$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.payments-pays {
  display: grid;
  grid-template-columns: repeat(auto-fill, 148px);
  grid-gap: 8px;
  justify-content: center;
  @include breakpoint($bp1) {
    grid-gap: 12px;
  }
  @include breakpoint($bp2) {
    grid-template-columns: repeat(auto-fill, 168px);
  }

  &__item {
    position: relative;
    min-height: 182px;
    display: grid;
    row-gap: 4px;
    align-items: center;
    padding: 10px;
    background-color: #eff5fd;
    color: #2a394e;
    overflow: hidden;
    transition: 0.1s background-color;

    &:hover {
      background-color: #ffffff;
    }

    &:hover &-backbox {
      transform: translateY(-100%);
      opacity: 1;
    }

    &:hover &-button {
      pointer-events: all;
    }
  }

  &__item-logo {
    width: 100%;
    height: 30px;
  }

  &__item-text {
    word-break: break-word;
    text-align: center;
    @include text(14px, 16px, 700, #a5afc0);
  }

  &__item-description {
    word-break: break-word;
    margin-top: 1px;
    @include text(16px, 19px, 700, #2a394e);
    text-align: center;
  }

  &__item-sum {
    body.rtl-lang & {
      width: 100%;
      display: inline-block;
      text-align: center;
      &::before {
        content: 'ف';
        display: inline;
        font-size: 0;
        line-height: 0;
        pointer-events: none;
      }
    }
  }

  &__item-backbox {
    width: 100%;
    height: calc(100% - 48px);
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    opacity: 0;
    transition: 0.2s transform;
  }

  &__item-button {
    width: 124px;
    min-width: auto;
    height: 48px;
    margin: 41px auto 0;
    padding: 10px 5px;
    pointer-events: none;
    border: none;
    @include text(20px, 24px, 400, #080f21);
    text-transform: uppercase;
    @include ellipsis;
    text-align: center;
    background-color: #ecfd00;
    border-radius: 1px;

    @include breakpoint($bp2) {
      width: 144px;
    }
  }

  &__stub {
    grid-column: 1 / -1;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
}
