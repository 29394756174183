$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-guide {
  @include text(14px, 140%, 400, $darkGrey);
  text-align: left;
  padding-top: 20px;

  &--crypto {
    padding-top: 0;
  }

  ol {
    li {
      counter-increment: li;
      display: flex;
      margin-bottom: 18px;

      &::before {
        width: 16px;
        height: 16px;
        border: 1px solid #2a394e;
        content: counter(li);
        @include text(12px, 14px, 400, $darkGrey);
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        margin-right: 6px;
        margin-top: 1px;
      }
    }
  }
}