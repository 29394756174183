$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
html {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 12px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;

    &.has-open-left-sidebar, &.has-open-right-sidebar {
        overflow: hidden !important;
        body {
            overflow: hidden !important;
        }
    }
}


body {
    font-family: $ff;
    width: 100%;
    height: auto;
    min-height: 100%;
    background-color: #0C1221;
    color: #fff;
    &.rtl-lang .ng-binding,
    &.rtl-lang [translate] {
        direction: rtl;
    }
    &:has(.game-page) {
        height: 100%;
    }
}

a {
    color: #EFFB37;
    text-decoration: none;
    @include hover() {
        text-decoration: underline;
    }

    &.is-white {
        color: white;
        text-decoration: underline;
        @include hover() {
            text-decoration: none;
        }
    }
}

:root {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

//.asb-application * {
//  all: revert;
//}
//
//[name="asb-banners"] img {
//    all: inherit !important;
//}