$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.modal {

  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(12, 18, 33, 0.8);
  color: white;

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  z-index: -102;
  opacity: 0;

  .linda-has-modal & {    
    z-index: 9999;
    display: block;
    opacity: 1;
  }

}