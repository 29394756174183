$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-btn {
  margin: 0 auto 32px;
  max-width: 392px;

  &.is-m-top {
    margin-top: 20px;
  }
}
