$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.autobonus-popup {
    &__title {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.15em;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 20px;

        @include breakpoint($bp1) {
            font-size: 24px;
            margin-bottom: 22px;
        }
    }

    &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        margin-top: 26px;
    }

    &__info-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5em;
        color: #A5AFC0;
        margin-bottom: 16px;
    }

    &__info-link {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.15em;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-decoration-line: underline;
        text-underline-offset: 4px;
    }
}