$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.progress-time {
    width: 100%;
    height: 2px;
    position: relative;
    background: linear-gradient(to right, $yellow 17px, rgba(255, 255, 255, 0.1) 17px 100%);
    padding: 0 17px;
    &__active {
        position: relative;
        width: 0;
        height: 100%;
        background: $yellow;
    }
    &__value {
        @include text(11px, 13px, 400, $btnBlack);
        position: absolute;
        box-sizing: border-box;
        right: -17px;
        top: -7px;
        width: 34px;
        height: 14px;
        background: $yellow;
        padding: 1px 2px 0;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 1px;
    } 
}