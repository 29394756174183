$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.live-chat {
  position: fixed;
  width: 48px;
  height: 48px;
  bottom: 46px;
  right: 16px;
  border: 1px solid #2A394E;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #273242 50% / 28px no-repeat;
  z-index: 1111;

  &:hover {
    border-color: #394557;
    background-color: #2A394E;
  }

  &:focus {
    border-color: #273242;
    background-color: #162334;
  }

  @include breakpoint($bp440) {
    bottom: 64px;
  }

  body:has(linda-game-page) &,
  body:has(linda-game-page-mobile) & {
    display: none;
  }
}