.oktoberfest-welcome-popup {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.holiday-welcome-popup {
  position: relative;
  &__btn {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
  }
  &__daily {
    margin-bottom: 7px;
    color: #f1e6d3;
    text-align: center;
    font-family: $f-josefin;
    font-size: 18px;
    font-weight: 700;
  }
  &__img {
    background: url($cdn + '/oktoberfest/2023/popup/ectangle.svg');
    width: 90px;
    margin-bottom: 10px;
    height: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__time {
    margin-bottom: 25px;
    color: #f1e6d3;
    text-align: center;
    font-family: $f-josefin;
    font-size: 16px;
    font-weight: 700;
  }
  &__close {
    position: absolute;
    top: -10px;
    right: 82px;
    width: 36px;
    backdrop-filter: blur(3px);
    height: 36px;
    z-index: 2;
    border: none;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.1) inset, 0px 2px 0px 0px rgba(206, 206, 206, 0.2) inset;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.18));
    display: flex;
    background: url($cdn + '/oktoberfest/2023/elements/close.svg'),
      radial-gradient(49.26% 57.16% at 50% 93%, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(0deg, #cfb082 0%, #cfb082 100%), #88532d;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    @include breakpoint($bp2) {
      right: -10px;
    }
  }
  &__inner {
    background-image: url($cdn + '/oktoberfest/2023/popup/back-mob.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 500px;
    @include breakpoint($bp2) {
      background-image: url($cdn + '/oktoberfest/2023/popup/back-desk.png');
    }
  }
}
