$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-body-cashbox {
  width: 100%;
  min-height: 100%;
  background: #192333;
  padding: 0;
  display: block;
  position: relative;

  body.rtl-lang & {
    direction: rtl;
  }

  .popup-close-btn {
    top: 0;
    inset-inline-end: 4px;
  }
  
  &__content {
    position: relative;
  }

  &__head {
    background: #293241;
    height: 90px;
    display: flex;
    flex-direction: column;
  }

  &__body {
    background: white;
    min-height: calc(100vh - 202px);
    padding: 1px 20px 20px;
    text-align: center;
  }

  @include breakpoint($bp1) {
    margin: 40px auto;
    max-width: 472px;
    min-height: 300px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 12px rgba(29, 37, 52, 0.4);
    &__body {
      padding-left: 40px;
      padding-right: 40px;
      min-height: 392px;
    }
  }
}
