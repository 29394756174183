$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-item-full {
  position: relative;
  padding: 168px 20px 24px;
  background-image: linear-gradient(180deg, rgba(12, 18, 33, 0.00) 0%, rgba(12, 18, 33, 0.91) 41.35%, #0C1221 99.5%);
  background-repeat: no-repeat;
  background-size: 100% 138px;
  background-position: center top 140px;
  margin-left: -20px;
  margin-right: -20px;
  overflow: hidden;

  &__steps-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 34px;
    color: $white;
    text-align: center !important;
    margin-bottom: 4px;
  }

  &__img {
    position: absolute;
    top: 32px;
    width: 546px;
    height: 200px;
    background-size: auto 100%;
    background-position: center;
    z-index: -1;
    left: 50%;
    transform: translate(-50%) rotate(-10deg);
    background-repeat: no-repeat;
  }

  &__content {
    max-width: 292px;
    margin: 0 auto;
  }

  &__title {
    @include text(24px, 28px, bold);
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;

    span:first-child {
      @include text(16px, normal, 700);
      color: #ECFD00;
      text-transform: uppercase;
      display: block;
    }

    &.is-knockout-bonus {
      span:last-child {
        display: none;
      }
    }

    body.rtl-lang & {
      direction: rtl;

      *:not(span:first-child) {
        display: inline-block;
      }
    }
  }

  &__btn {
    max-width: 280px;
    margin: 0 auto;
  }

  &__chat-link {
    position: relative;
    @include text(16px, 48px);
    margin: 0 auto;
    margin-top: 8px;
    cursor: pointer;
    display: table;

    &::before {
      content: "";
      width: 20px;
      height: 100%;
      position: absolute;
      inset-inline-start: 0;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__copy-promocode {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 8px;
    padding: 7px 20px;
    background-color: rgb(255 255 255 / 0.10);
    font-weight: 400;
    font-size: 15px;
    line-height: 1.17;
    color: #A5AFC0;
    column-gap: 8px;
    border: 1px solid #2A394E;
  }

  &__copy-promocode-text {
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .promo-tooltip-wrapper {
    position: relative;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: -4px;

    .copy-tooltip {
      position: absolute;
      background-color: #EFF5FD;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.333;
      color: #0B1222;
      padding: 4px 10px;
      bottom: calc(100% + 6px);
      left: 50%;
      transform: translateX(-50%);
      transition: opacity .3s;
      opacity: 0;
      &.is-visible{
        opacity: 1;
      }

      &::before {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #EFF5FD transparent transparent transparent;
      }
    }
  }

  &__socials{
    max-width: 280px;
    margin: 0 auto;
    padding-top: 8px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;

    &-btn{
      height: 42px;
      background-color: #273242;
      border-radius: 2px;
      border: 1px solid #2A394E;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 28px;

      &.is-instagram {
        background-image: var(--icon-instagram);
      }

      &.is-youtube {
        background-image: var(--icon-youtube);
      }

      &.is-telegram {
        background-image: var(--icon-telegram-2);
      }
    }

    @include breakpoint($bp2 ){
      padding-top: 12px;
    }
  }

  @include breakpoint($bp1 ) {
    &__content {
      max-width: 100%;
    }

    &__text-list,
    &__btn {
      margin-right: auto;
      margin-left: auto;
    }
  }

  @include breakpoint($bp2 ) {
    padding-top: 154px;
    background-size: auto 152px;
    background-position: center top 138px;

    &__title {
      @include text(32px, 37px);

      span {
        font-size: 20px;
      }
    }

    &__img {
      width: 655px;
      height: 240px;
    }
  }

  @include breakpoint($bp3 ) {
    padding-top: 190px;

    &__title {
      margin-bottom: 52px;
    }
  }
}

.promo-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;

  &__step {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 280px;
    padding: 12px;
    font-size: 12px;
    line-height: 14px;
    background-color: $darkBlue;
    position: relative;
    counter-increment: item;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__number {
    width: 37px;
    height: 37px;
    flex-shrink: 0;
    background-color: $yellow;
    color: $black;
    border-radius: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 37px;
    text-align: center;

    &::after {
      content: counter(item);
    }
  }

  &__step {
    &-info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-grow: 1;
    }

    &-name {
      text-transform: uppercase;
      font-weight: 700;
      color: $white;
    }

    &-text {
      font-weight: 400;
      color: $blueGray;
    }
  }

  &__arrow {
    width: 13px;
    height: 13px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
