$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge {
  --outer-color: #162334;
  --inner-color: transparent;

  position: relative;

  display: grid;

  &_status-3 {
    --outer-color: #{$yellow};
    --inner-color: #{$yellow};
  }

  &__img-box {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    max-height: 110px;
    overflow: hidden;
    box-shadow: inset 0 -80px 40px -40px #162334;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background-image: linear-gradient(180deg, rgba(22, 35, 52, 0) 0, #162334 100%);
    }
  }
  &__img {
    & img {
      max-width: 100%;
      margin-right: 0;
      height: 178px;
    }
  }
  &__img-empty {
    width: 120px;
    height: 178px;
    background-color: #273242;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 78px;
      height: 62px;
      background-image: url($cdn + '/rabona/profile/dotes-bg.png');
      right: 0;
      top: 0;
      background-size: 100%;
    }
    &:after {
      content: '';
      position: absolute;
      width: 78px;
      height: 62px;
      background-image: url($cdn + '/rabona/profile/dotes-bg.png');
      left: 0;
      bottom: 0;
      background-size: 100%;
      transform: rotate(180deg);
    }
  }

  &__challange-info {
    grid-column: 1/-1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 3px solid $red;
    color: $white;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    white-space: break-spaces;
  }

  &__amount {
    margin-left: 12px;
    color: $yellow;
    font-size: 32px;
    text-transform: uppercase;
  }

  &__title {
    padding-left: 39px;

    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: $white;
    position: relative;
    white-space: break-spaces;

    &::before {
      content: '';

      position: absolute;
      left: -20px;
      bottom: -27px;
      width: 39px;
      height: 39px;

      transform: rotate(45deg);

      background-image: linear-gradient(
          to right,
          var(--outer-color) 0,
          var(--outer-color) 3px,
          transparent 3px,
          transparent calc(100% - 3px),
          var(--outer-color) 3px
        ),
        linear-gradient(
          to bottom,
          var(--outer-color) 0,
          var(--outer-color) 3px,
          transparent 3px,
          transparent calc(100% - 3px),
          var(--outer-color) 3px
        ),
        linear-gradient(0deg, var(--inner-color), var(--inner-color));

      background-repeat: no-repeat;
      background-position: 0 0, 0 0, center;
      background-size: 100%, 100%, 20px 20px;
    }
    &::after {
      content: '';

      position: absolute;
      right: 0;
      bottom: -9px;

      display: block;
      height: 3px;
      width: calc(100% - 24px);

      background-color: var(--outer-color);
    }
  }
  &_status-3 &__title {
    padding-left: 68px;
    background-image: var(--icon-choose);
    background-repeat: no-repeat;
    background-position: 38px 7px;
    color: #A5AFC0;
  }
  &__btn {
    flex: 0 0 auto;

    font-size: 14px;
    line-height: 28px;
    text-transform: uppercase;
    text-align: center;
    color: $yellow;

    min-width: 120px;
    padding-left: 10px;
    padding-right: 10px;

    border: 0;
    border-radius: 1px;
    background-color: $lightBlue;

    cursor: pointer;
  }
  &__prize {
    flex: 0 0 auto;

    font-size: 24px;
    line-height: 28px;
    text-transform: lowercase;
    color: $yellow;
    margin-top: 20px;
  }

  &__btns {
    margin-top: 20px;
    display: flex;
    align-items: center;

    &.is-center {
      justify-content: center;

      .challenge__deposit-btn {
        margin: 0;
      }
    }
  }
  &__deposit-btn {
    display: inline-flex;
    background-color: $red;
    color: $yellow;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    width: 120px;
    height: 40px;
    border-radius: 1px;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    margin-inline-end: 20px;
    cursor: pointer;
  }
  &__details-btn {
    font-size: 14px;
    text-transform: uppercase;
    color: $yellow;
    cursor: pointer;
  }

  &__progress {
    color: $yellow;
    font-size: 20px;
    margin-bottom: 12px;

    &.is-finished {
      color: #576477;
    }
  }

  &__info {
    --bg: #162334;
    --color: #{$white};
    --triangleBg: #162334;
    --max-h: 520px;

    position: relative;
    white-space: break-spaces;

    flex: 1 0 100%;
    padding: 38px 30px 20px;
    margin-top: 15px;
    font-size: 16px;
    line-height: 24px;
    color: var(--color);

    background-color: var(--bg);
    clip-path: polygon(28px 0, 100% 0, 100% 28px, 100% var(--max-h), 0 var(--max-h), 0 28px);
    transition: var(--animation-duration) clip-path linear, var(--animation-duration) max-height linear;
    transition-delay: 0.05s;

    &.has-img {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    &::before {
      content: '';

      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 28px;
      height: 28px;

      background: var(--triangleBg);
    }
  }

  &__show-more {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
  }

  &__flex-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
