$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-info-line {

  background-color: $red;
  margin: 20px auto;
  padding: 9px  25px 9px 27px;
  position: relative;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.05);
  background: linear-gradient(-66deg, rgba(204,0,52, 0) 18px, rgb(204,0,52) 0);
  min-width: 75px;
  max-width: fit-content;

  &__text {
    @include text(19px, 22px);
    @include ellipsis;
  }

  &.icon-points-yellow__before::before {
    content: "";
    position: absolute;
    width: 52px;
    height: 52px;
    left: -30px;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 52px;
    top: -5px;
  }

}