$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-cashier-text {
  @include text(14px, 140%, 400, $darkGrey);
  text-align: left;

  &_title {
    @include text(14px, 120%, 500, $blueDark);
    margin: 20px 0 12px;
    text-align: center;
  }

  &_bottom {
    margin-bottom: 20px;

    @media (min-width: 540px) {
      margin-bottom: 15px;
    }

    @media (min-width: 768px) {
      margin-bottom: 10px;
    }
  }

  &.is-enter-amount {
    @include text(14px, 120%, 400, $blueDark);
    text-align: center;
    margin-bottom: 4px;
  }

  &.is-range {
    @include text(14px, 120%, 400, #a5afc0);
    text-align: center;
    margin-bottom: 8px;
  }

  &.is-center {
    text-align: center;
  }
}