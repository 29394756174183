$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.trustly-page {

  &__title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &__title {
    @include text(24px, 28px);

    margin-right: 8px;
    @include ellipsis;
  }
  &__text {
    @include text(12px, 14px, normal, $grey);
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  & .icon-trustly-logo, & .icon-zimpler {
    width: 55px;
    height: 24px;
    background-size: 55px 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__form {
    margin-top: 18px;
  }
  &__links {
    @include text(14px, 17px, normal, $grey);
    letter-spacing: 0.01em;
    margin-top: 16px;
    text-align: center;

  }

  &__pay-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
  }
  &__pay-item {
    height: 38px;
    width: 100%;
    @include ellipsis;
    @include text(16px, 36px, normal, $white);
    border-radius: 2px;
    background: #303542;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 8px;
    text-align: center;

    &.is-active {
      color: $black;
      background: $yellow;
      font-weight: bold;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__dep {
    @include text(16px, 23px, bold, $black);
    background-color: $yellow;
    border-radius: 2px;
    padding: 0 8px;
    text-align: center;
    flex-shrink: 0;
  }

  &__frame {
    height: 419px;
    margin-top: 12px;
    width: 320px;
    margin-left: -20px;
  }

  @include breakpoint($bp2){
    &__frame {
      height: 350px;
      width: 460px;
      margin-left: -70px;
    }
  }
  @include breakpoint($bp3){
    &__frame {
      height: 350px;
      width: 560px;
      margin-left: -60px;
    }
  }

}