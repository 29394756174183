$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.widget-last-deposit{
    &__text{
        @include text(16px, 24px, 400, $grey);
        margin-bottom: 8px;
    }

    &__payment{
        width: 100%; 
        padding-block: 7px;
        padding-inline: 10px 20px;
        min-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        background: #FFFFFF;
        border: 1px solid #2D394C;
        border-radius: 2px;
        @include text(16px, 19px, 700, $black);

        body.rtl-lang & {
            direction: rtl;
        }

        img{
            width: 88px;
            height: 24px;
        }
    }

    &__btn{
        width: 280px;
        margin-top: 8px;
    }

}