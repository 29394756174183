$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.home-wins {
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  background-color: #111928;
  border-radius: 2px;
  padding: 20px 0;

  body.rtl-lang & {
    direction: rtl;
  }

  &__list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding: 0 28px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    width: 250px;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-inline-start: 30px;
    }
  }

  &__img {
    display: block;
    width: 88px;
    height: 72px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    margin-inline-end: 15px;
    border-radius: 2px;
    background-color: rgba(0,0,0,0.2);
  }

  &__content {
    width: 167px;
    flex-shrink: 0;
  }

  &__sum {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    color: #ECFD00;

    body.rtl-lang & {
      direction: ltr;
      text-align: right;
    }
  }

  &__text {
    margin-top: 2px;
    font-size: 14px;
    line-height: 16px;
    color: #909BAD;

    span {
      body.rtl-lang & {
        direction: ltr;
        display: inline-block;
        margin-left: 4px;
      }
    }
  }

  &__link {
    margin-top: 4px;
    display: block;
    color: #909BAD;
    text-decoration: underline;
    font-size: 14px;
    line-height: 16px;
  }
}