$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-text {

  font-size: 16px;
  line-height: 24px;

  color: #A7AFBE;

  margin-bottom: 20px;

  batman-modal-wrapper1 & {
    white-space: break-spaces;
  }

  &.has-line {

    padding-bottom: 20px;
    margin-bottom: 0;
    border-bottom: 2px solid #CC0034;

  }
  &.is-migration{
    margin-top: 20px;
    margin-right: -10px;
    padding-right: 20px;
    padding-bottom: 10px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    flex-grow: 1;
    height: calc(100% - 148px);
    overflow: auto;
    position: relative;
    color: #A5AFC0;

    &::-webkit-scrollbar {
      width: 8px;
      height: 0;
      background-color: #505A67;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 8px;
    }

    p{
      margin-top: 15px;
    }
  }

  @include breakpoint($bp2) {
    &.is-migration{
      font-size: 18px;
    }
  }
}
