$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.registration-with-deposit,
.login-form {
  padding: 0 20px;

  &__form-item {
    position: relative;
    margin-bottom: 12px;
    display: block;
  }
}
