$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-horizontal-box{
    position: relative;
    width: 100%;
    margin-top: 8px;

    @include shadow-before-after(8px, calc(100% - 24px));

    &__scroll{
        position: relative;
        display: flex;
        max-width: 100%;
        overflow: auto;
        padding: 0 20px 24px;
        white-space: nowrap;

        @include without-scrollbar();

        > * {
            display: block;
            flex-shrink: 0;
        }
    }

    body.rtl-lang & {
        direction: rtl;
    }
}