$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.footer {
  padding: 32px 24px 88px;
  background-color: #162334;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  body.rtl-lang > & > * {
    text-align: left;
    direction: ltr;
  }

  &__block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    justify-content: center;
    align-items: stretch;
    max-width: 350px;
    margin: 0 auto;
    width: 100%;

    body.rtl-lang & {
      direction: rtl;
    }

    &.is-chat-lang {
      margin: 0 auto;
    }

    &.social-block {
      margin-top: 12px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__socials {
    display: flex;
    gap: 0px 12px;
    justify-content: center;
    align-items: center;
  }

  &__licence-btn {
    display: flex;
    align-items: center;
    margin: 12px auto 0;
    @include text(16px, 19px, 400, $white);
    border: none;
    background-color: transparent;
    &::after {
      content: '';
      display: block;
      margin-left: 2px;
      width: 16px;
      height: 16px;
      background-image: var(--icon-arrow-down);
      background-repeat: no-repeat;
      background-size: contain;
      filter: brightness(2);
      transition: 0.2s all;
    }
  }

  .footer__text-block {
    &.is-open {
      .is-hidden {
        display: block;
      }
      .footer__licence-btn {
        text-decoration: underline;
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .footer__text {
    @include text(14px, 16px, 400, #a5afc0);
    opacity: 0.5;
    text-align: center;
    margin-top: 12px;

    &.is-shown {
      display: block;
    }

    &.is-hidden {
      display: none;
    }

    &_capitalize {
      &::first-letter {
        text-transform: capitalize;
      }
    }

    body.rtl-lang & {
      text-align: center;
      direction: ltr;
    }
  }

  .social-block {
    &__link {
      height: 42px;
      background-color: #273242;
      border-radius: 2px;
      border: 1px solid #2A394E;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 28px;

      &.is-instagram {
        background-image: var(--icon-instagram);
      }

      &.is-youtube {
        background-image: var(--icon-youtube);
      }

      &.is-telegram {
        background-image: var(--icon-telegram-2);
      }
    }
  }
}

.footerLicense {
  margin-top: 20px;

  &-hiddenText {
    font-size: 16px;

    &.is-open {
      button span {
          text-decoration: underline;
      }
    } 

    p {
      color: #a5afc080;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2em;
    }
  }

  .footerLicense-icons {
    margin-bottom: 12px;

    body.rtl-lang & {
      direction: rtl;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      display: flex;
    }

    img {
      min-width: 23px;
      max-width: 100%;
      max-height: 34px;
    }

    &__license {
      max-width: 34px;
    }
  }

  .footerLicense-text {
    margin-top: 12px;
    color: #a5afc0;
    opacity: .5;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}
