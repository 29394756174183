$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-text {

  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #A7AFBE;

  margin: 32px 0;

}