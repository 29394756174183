$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.collection-popup {
    width: 100%;
    min-height: 100%;
    display: block;
    position: relative;
    background: #192333;

    @include breakpoint($bp1) {
        max-width: 472px;
        margin: 40px auto;
        min-height: auto;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 20px 36px;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;


    }

    &__title {
        padding: 0;
        font-size: 38px;
        line-height: 44px;
        color: $yellow;
    }

    &__box {
        padding: 16px 18px;
        border: 1px solid $wh;
        @include text(24px, 28px, 400, $wh);
        text-align: center;
        margin-top: 24px;
        margin-bottom: 20px;
        width: 100%;

        span {
            color: $yellow;

            &.is-uppercase {
                text-transform: uppercase;
            }

            &.is-block {
                display: block;
            }
        }
    }

    .collection-list__item {
        height: 364px;
    }

    .collection-list__label {

        @include breakpoint($bp1) {
            width: 255px;
        }
    }

    .collection-list__price {
        font-size: 50px;
        line-height: 58px;
        margin-top: 10px;
    }

    .collection-list__item-container {
        padding-bottom: 32px;
        height: 220px;
    }

    &__bg {
        height: 35%;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle cx='10' cy='10' r='1' fill='%23DA6436' /%3E%3C/svg%3E");
        background-repeat: repeat;
        background-position: center top;
        background-color: $red;

        @include breakpoint($bp1) {
            height: 27%;
        }

        &::before {
            content: '';
            position: absolute;
            top: -99.5px;
            left: 0;
            width: 100%;
            height: 100px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle cx='10' cy='10' r='1' fill='%23DA6436' /%3E%3C/svg%3E");
            background-repeat: repeat;
            background-position: center top;
            background-color: $red;
            clip-path: polygon(100% 100%, 0 100%, 100% 0);
        }
    }

    &__btn {
        position: relative;
        z-index: 1;
        margin-bottom: 0!important;
    }
}