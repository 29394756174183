$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.wrapper {
  display: block;
  transition: 0.3s all;

  &:has(linda-game-page) {
    height: calc(100% - 48px);
  }

  linda-header:has(.is-verification-changed) ~ &:has(linda-game-page) {
    height: calc(100% - 104px);
  }


  .has-open-left-sidebar & {
    transform: translateX(270px);
  }

  .has-open-left-sidebar body.rtl-lang & {
    transform: translateX(-270px);
  }

  .has-open-right-sidebar & {
    transform: translateX(-270px);
  }

  .has-open-right-sidebar body.rtl-lang & {
    transform: translateX(270px);
  }
}
