$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.link-icon {

  @include text(14px, 16px, normal, $grey);
  padding-inline-start: 20px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  body.rtl-lang & {
    background-position: right center;
  }

  &.is-p24 {
    padding-inline-start: 24px;
  }

  &.is-chat{
    margin-top: 20px;
    padding-inline-start: 30px;
  }

  span {
    font-size: 8px;
    line-height: 9px;
    text-transform: uppercase;
  }

  @include hover(){
    opacity: .8;
  }

}