$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-timer {
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
    &__title {
        text-transform: uppercase;
    }
}