.holiday-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 64px;
  background-color: #c27a07;
  filter: drop-shadow(0px 4.68543004989624px 7.496689319610596px rgba(50, 50, 50, 0.3));
  border-radius: 60px;
  position: relative;
  text-decoration: none;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 61px;
    padding: 0 81px;
    border-radius: 61.477px;
    background: radial-gradient(96.56% 88.89% at 50.25% 94.5%, #ffd239 0%, #f8b424 100%);
    box-shadow: 0.9370861649513245px 0.9370861649513245px 0px 0px rgba(246, 243, 187, 0.4) inset;
    position: relative;
    // &:active {
    //   &::after {
    //     background: #f5a014;
    //     box-shadow: -0.9370861649513245px -0.9370861649513245px 3.0683348178863525px 0px #f28f22 inset,
    //       0.9370861649513245px 0.9370861649513245px 0px 0px rgba(252, 175, 90, 0.4) inset;
    //   }
    // }

    // @include breakpoint($bp3) {
    //   &:hover {
    //     &::after {
    //       background: linear-gradient(149.95deg, #ffc530 18.32%, #ecb320 84.78%),
    //         linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
    //       box-shadow: inset 0.914572px 0.914572px 0 rgba(246, 243, 187, 0.4), inset -1px -1px 2.99462px #ffc635;
    //     }
    //   }
    // }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      right: 6px;
      left: 4px;
      bottom: 4px;
      border-radius: 61.477px;
      background: #f5a014;
      box-shadow: -0.9370861649513245px -0.9370861649513245px 3.0683348178863525px 0px #f28f22 inset,
        0.9370861649513245px 0.9370861649513245px 0px 0px rgba(252, 175, 90, 0.4) inset;
      transition-property: background-image, box-shadow;
      transition-duration: 0.2s;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      right: 17px;
      left: 17px;
      height: 32px;
      background-image: linear-gradient(180deg, rgba(255, 245, 193, 0.8), rgba(255, 216, 22, 0.1) 100%);
      opacity: 0.7;
      border-radius: 50px;
      z-index: 2;
    }
  }

  &__text {
    display: block;
    width: 100%;
    position: relative;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 32px;
    z-index: 5;
    font-family: $f-jost;
    color: $c-white;
    color: #6b4018;
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    white-space: nowrap;
  }
}
