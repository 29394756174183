$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-gradient-text {
  pointer-events: none;
  transform: translateY(-1px);
  margin-top: -100px;
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, rgba(22, 35, 52, 0) 0%, #162334 93.23%);

  @include breakpoint($bp3) {
    background-color: rgba(255, 255, 255, 0);
  }
}
