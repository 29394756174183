$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-withdrawal-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid $lightGrey;
    padding: 16px;
    color: $lightBlue;

    &__date{
        @include text(14px, 28px, 400);
    }

    &__logo{
        @include text(18px, 28px);
        width: 96px;
    }

    &__amount{
        @include text(18px, 21px, 700, $black);
    }

    > *:not(:last-child) {
        margin-bottom: 8px;
    }

    & + & {
        margin-top: 16px;
    }
}