$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-pays{
    display: grid;  
    justify-content: center;
    grid-gap: 8px;
    grid-template-columns: repeat( auto-fit, 88px );
    overflow: hidden;
    
    transition: .15s ease margin;
   
    &.is-sm{
        height: 104px;
        &.is-open{
            height: auto;
            margin-bottom: 20px;
        } 
    }
    @include breakpoint($bp1){
        grid-template-columns: repeat( auto-fit, 92px );
        &.is-sm{
            height: 108px;
        }
    }
}   
