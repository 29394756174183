$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-trustly-page {
  max-width: 280px;
  margin: 24px auto 40px;

  &__btn {
    margin: 16px auto 0;
    max-width: 100%;
  }

  @include breakpoint($bp2){
    max-width: 320px;
  }

  @include breakpoint($bp2){
    max-width: 440px;
  }

}