$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.big-celebrity-page {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
    width: 100%;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    min-height: 630px;
    @include s {
        padding-top: 24px;
        min-height: 400px;
    }

    @include m {
        max-width: 755px;
        padding-bottom: 30px;
    }

    @include l {
        max-width: 895px;
        padding-bottom: 50px;
        padding-top: 40px;
        min-height: 630px;
    }

    &-wrap {
        position: relative;
        background-repeat: no-repeat;
        background-size: auto 561px;
        background-position: top center;
        align-items: center;
        overflow: hidden;

        @include s{
            background-position: top left;
            background-size: auto 432px;
        }

        @include m{
            background-size: cover;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 997px;
            height: 561px;
            background: linear-gradient(360deg, #0B1120 0%, rgba(11, 17, 32, 0.93) 9.08%, rgba(11, 17, 32, 0) 29.95%), linear-gradient(90deg, rgba(11, 17, 32, 0.6) 0.98%, rgba(11, 17, 32, 0.48) 58.05%, rgba(11, 17, 32, 0) 72.37%);
            
            @include s{
                width: 768px;
                height: 432px;
                background: linear-gradient(360deg, #0B1120 0%, rgba(11, 17, 32, 0.93) 9.08%, rgba(11, 17, 32, 0) 29.95%),linear-gradient(90deg, rgba(11, 17, 32, 0.6) 0.98%, rgba(11, 17, 32, 0.48) 58.05%, rgba(11, 17, 32, 0) 72.37%);
            }

            @include m {
                width: 1024px;
            }

            @include l{
                height: 630px;
            }
        }

        &.is-knockout-bonus::before {
            background: radial-gradient(67.89% 99.36% at 39.4% 6.32%, #0B1120 0%, rgba(11, 17, 32, 0.43) 35.5%, rgba(11, 17, 32, 0) 100%), linear-gradient(90deg, rgba(11, 17, 32, 0.6) 0.98%, rgba(11, 17, 32, 0.48) 83.93%, rgba(11, 17, 32, 0.21) 100%), linear-gradient(360deg, #0B1120 0%, rgba(11, 17, 32, 0.93) 9.08%, rgba(11, 17, 32, 0) 29.95%);
        }
    }

    &-pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        @include l{
            margin-bottom: 56px;
        }

        &__arrow{
            position: relative;
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: 0;
            background-position: center;
            background-repeat: no-repeat;

            &.is-prev{
                background-image: var(--icon-arrow-left);
                order: 0;
            }
            &.is-next{
                background-image: var(--icon-arrow-left);
                transform: scale(-1,1);
                order: 2;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__top {
        @include s {
            margin-bottom: 24px;
        }

        @include m {
            margin-bottom: 32px;
        }
        .is-knockout-bonus &{
            z-index: 1;
        }
    }

    &__title {
        color: #FFF;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.15em;
        text-transform: uppercase;
        margin-bottom: 4px;

        @include m {
            font-size: 32px;
        }
    }

    &__subtitle {
        color: #ECFD00;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.15em;
        text-transform: uppercase;

        @include m {
            font-size: 20px;
            line-height: 1.2em;
        }
    }

    &__offer {
        color: #A5AFC0;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @include l {
            font-size: 16px;
        }
    }

    &__quote {
        color: #FFF;
        font-size: 14px;
        font-style: italic;
        font-weight: 300;
        line-height: normal;
        width: 100%;
        text-align: right;
        margin-top: 26px;
        display: none;

        @media (min-width: 414px) {
            margin-top: 16px;
        }

        @include s {
            max-width: 275px;
            margin-top: 25px;
        }

        @include m {
            max-width: 358px;
            font-size: 16px;
            margin-top: 32px;
        }

        @include l {
            max-width: 416px;
            display: block;
        }

        span {
            display: block;
            color: #FFF;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            margin-top: 8px;
            font-style: initial;

            @include m {
                font-size: 16px;
            }
        }
    }

    &__description {
        color: #A5AFC0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5em;
        margin-top: 30px;
        display: none;

        @include s {
            margin-top: 6px;
        }

        @include m {
            width: 100%;
            max-width: 475px;
            font-size: 16px;
            margin-top: 20px;
        }

        @include l {
            display: block;
            margin-top: 30px;
            max-width: 465px;
        }
    }

    &__details {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: uppercase;
        display: block;
        text-align: left;
        width: fit-content;
        margin-top: 18px;
    }

    &__ambassador {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-transform: uppercase;
        padding-left: 7px;
        border-left: 1px solid #ffffff;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.5em;

        @include s {
            font-size: 14px;
            width: 100%;
            margin-left: 11px;
        }

        @include m {
            width: auto;
            font-size: 12px;
            bottom: 26px;
        }

        @include l {
            font-size: 20px;
        }
    }

    &__hero {
        width: 100%;
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 317px;

        @media (min-width: 414px) {
            height: 345px;
            max-width: 414px;
        }

        @include s {
            position: absolute;
            align-items: flex-end;
            right: 0px;
            max-width: 247px;
            top: 14px;
            margin-top: 0;
        }

        @include m {
            max-width: 394px;
            top: 24px;
            right: 5px;
        }

        @include l {
            max-width: 568px;
            top: 34px;
            right: -64px;
            height: 464px;
        }

        .is-knockout-bonus &{
            @media (min-width: 414px) {
                max-width: 377px;
            }

            @include s{
                max-width: 247px;
            }

            @include m{
                max-width: 394px;
            }

            @include l {
                max-width: 568px;
            }
        }

        &::before {
            content: '';
            display: block;
            background: linear-gradient(0deg, #0c1221 0% 10.81%, #0c122100 100.17%);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;
            height: 80px;
            z-index: 1;
            pointer-events: none;

            @media (min-width: 414px) {
                bottom: -10px;
            }

            @include s {
                display: none;
            }
        }

        &-image {
            margin-right: -14px;
            display: flex;
            align-items: center;
            justify-content: center;
            mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, transparent 100%);
            position: absolute;
            bottom: 0;
            right: 0;

            @media (min-width: 414px) {
                margin-right: -15px;
                margin-left: -15px;
            }

            @include s {
                margin-right: 6px;
                margin-left: 0;
                width: 350px;
                bottom: 45px;
            }

            @include m {
                width: auto;
                margin-right: 0;
                bottom: 10px;
            }

            .is-knockout-bonus &{
                @media (min-width: 414px) {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                @include s{
                    bottom: 20px;
                    margin-left: 0;
                    margin-right: 0;
                    width: 290px;
                }

                @include m{
                    width: 336px;
                    bottom: -10px;
                }

                @include l{
                    width: 498px;
                    bottom: -85px;
                }
            }

            img {
                height: 286px;
                object-fit: contain;
                object-position: bottom;

                @media (min-width: 414px) {
                    height: 335px;
                }

                @include s {
                    height: 300px;
                }

                @include m {
                    height: 322px;
                }

                @include l {
                    height: 464px;
                }
                .is-knockout-bonus &{
                    height: 377px;

                    @media (min-width: 414px) {
                        height: 425px;
                    }
    
                    @include s {
                        height: 350px;
                    }
    
                    @include m {
                        height: 379px;
                    }
    
                    @include l {
                        height: 562px;
                    }
                }
            }
        }

        &-info {
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
            position: absolute;
            bottom: 26px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;

            @media (min-width: 414px) {
                bottom: 10px;
            }

            @include s {
                flex-direction: column;
                justify-content: flex-start;
                gap: 0;
                bottom: 3px;
            }

            @include m {
                flex-direction: row;
                justify-content: center;
                bottom: 10px;
                padding-left: 15px;
            }

            @include l {
                padding-left: 37px;
                bottom: -100px;
            }
        }

    }

    &__sign {
        display: flex;

        @include s {
            margin-left: 30px;
            margin-bottom: -10px;
        }

        img {
            height: 50px;

            @include s {
                height: 70px;
            }

            @include m {
                height: 53px;
            }

            @include l {
                height: 71px;
            }
        }
    }

    &-promo {
        background: #192333;
        box-shadow: 0px 2px 8px 0px #0B1222;
        padding: 21px 10px 21px 16px;
        background-position: -8px 2px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjEiIGZpbGw9IiNFRkZCMzciIGZpbGwtb3BhY2l0eT0iLjM1IiBzdHlsZT0iIi8+PC9zdmc+');
        border: 1px solid #273242;
        border-left: 2px solid #CC0034;
        margin-top: -14px;
        width: 100%;
        z-index: 2;

        @media (min-width: 414px) {
            margin-top: 5px;
        }

        @include s {
            margin-top: 0;
            padding-right: 14px;
            max-width: 275px;
        }

        @include m {
            max-width: 358px;
        }

        @include l {
            max-width: 416px;
        }

        &__actions{
            @include m {
                display: flex;
            }
        }

        &__offer {
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            margin-top: 4px;
            margin-bottom: 20px;
        }

        &__btn {
            display: inline-flex;
            width: 100%;
            justify-content: center;
            text-align: center;
            height: 56px;
            line-height: 56px;
            padding: 0 32px;
            font-size: 20px;
            font-weight: 700;

            @include m {
                width: auto;
                min-width: 210px;
            }

            @include l {
                min-width: auto;
            }
        }

        &__details {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            text-transform: uppercase;
            display: block;
            text-align: left;
            width: fit-content;
            margin: 0 auto;
            margin-top: 18px;
            @include l {
                margin: 18px 27px 0;
            }
        }
    }

    &__common{
        padding: 20px 16px 36px;
        @include l {
            display: none;
        }
        .big-celebrity-page__quote{
            display: block;
            max-width: none;
            margin-top: 0;
            @include s{
                width: fit-content;
                span{
                    margin-left: auto;
                }
            }
        }
        .big-celebrity-page__description{
            display: block;
            max-width: none;
        }
    }
}