$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cabinet-deposit{
    position: relative;
    border: 2px solid $yellow;
    @include text(14px, 16px, 400, #fff);
    text-align: center;
    margin-bottom: 4px;

    &.is-hidden{
        border: none;
        .cabinet-deposit__more, &::before{
            display: none;
        }
        .cabinet-deposit-btn{
            margin: 0;
        }
    }

    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color:#0B1222;
        border: 2px solid $yellow;
        transform: rotate(45deg) translate(-40%);
    }
   
    .cabinet-deposit-btn{
        @include text(null, null, 700, #080F21);
        letter-spacing: 0.01em;
        text-transform: uppercase; 
        background-color: $yellow; 
        border: none; 
        margin: 0 -1px -1px;
        &::before{
            content: ".";
            color: transparent;
            display: inline-block;
            width: 16px;
            line-height: 19px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            margin-inline-end: 7px;
        }
    }

    &__more{
        display: block;
        overflow: hidden;
        padding: 10px 16px;
    }

    @include hover(){
        .cabinet-deposit-btn{
            opacity: 0.9; 
        }
    }
    

}