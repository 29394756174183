$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  body.rtl-lang & {
    direction: rtl;
  }

  &__item {
    padding: 0 12px;
    height: 34px;
    background-position: center;
    background-repeat: no-repeat;

    &.icon-18 {
      width: 58px;
    }

    &.icon-iso {
      width: 52px;
    }

    &.icon-7sp {
      width: 109px;
    }

    &.is-icon-cuw {
      width: 66px;

      a {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: auto;
          height: 28px;
        }
      }

      &>div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.icon-igate {
      width: 96px;
    }

    &.icon-pagcor {
      background:  url( $cdn + '/footer-icons/pagcor-seal.png') center / contain no-repeat;
      width: 52px;
    }

    &.icon-gcb {
      height: 31px;
      width: 54px;
      margin: 0 12px;
      background:  url( $cdn + '/gcb/icon.png') center / contain no-repeat;
    }
  }
}