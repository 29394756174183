$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-cashier-preloader {
  background: #eff5fd;
  border-radius: 2px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: absolute;
  width: 100%;
  height: calc(100% - 20px);
  top: 20px;
  left: 0;
  &__icon {
    margin-bottom: 12px;
  }
  &__text {
    text-align: center;
    @include text(14px, 140%, 400, #a5afc0);
  }
}
