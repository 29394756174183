$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-body-login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 40px 20px;

  .popup-close-btn {
    right: 4px;
    body.rtl-lang & {
      right: inherit;
      left: 4px;
    }
  }
}