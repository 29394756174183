$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-tab {
  position: relative;
  height: 45px;

  @include shadow-before-after(20px, 100%, 41, 50, 65);

  div {
    padding: 5px 34px;

    white-space: nowrap;
    overflow: auto;
    text-align: center;

    @include without-scrollbar();
  }

  a {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none !important;
    color: #a7afbe;
    padding: 0 20px;
    display: inline-block;
    position: relative;

    @include hover() {
      color: #ffffff;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.045);
      transform: skew(-39deg, 0deg);
      display: none;

      body.rtl-lang & {
        transform: skew(39deg, 0deg);
      }
    }

    &.is-active {
      color: #ffffff;
      pointer-events: none;

      &:after {
        display: block;
      }
    }

    & + a {
      margin-inline-start: 12px;
    }
  }

  &__new {
    height: 11px;
    line-height: 11px;
    color: #fff;
    position: absolute;
    font-weight: bold;
    font-size: 10px;
    display: block;
    width: 30px;
    top: -4px;
    inset-inline-end: -17px;
    padding: 0 !important;
    overflow: inherit !important;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
      background: #bc1f39;
      transform: skew(-39deg, 0deg);

      body.rtl-lang & {
        transform: skew(39deg, 0deg);
      }
    }

    span {
      position: relative;
    }
  }
}
