$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-cashier {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__section {
    &:has(.crypto-cashier-preloader) {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
    }
    &.is-selected {
      text-align: left;
    }
  }
  &__back {
    display: inline-flex;
    @include text(16px, 120%, 400, #273242);
    margin-top: 20px;
    position: relative;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background-image: var(--icon-arrow);
      transform: scale(-1, 1);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 4px;
    }
  }
}
