$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-title {

  font-weight: normal;
  font-size: 20px;
  line-height: normal;
  color: white;
  @include breakpoint($bp1) {
    font-size: 24px;
  }
}
