$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-bankid {

  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 44px;
  text-transform: uppercase;
  min-width: 160px;
  padding: 0 20px;
  text-align: center;

  background: transparent;
  border: 2px solid #FFFFFF;
  color: white;
  @include ellipsis;

  // &:before {
  //   content: '';
  //   width: 20px;
  //   height: 20px;
  //   display: block;
  //   margin-right: 8px;
  // }

  @include hover(){
    background: rgba(165, 175, 192, 0.3);
    text-decoration: none;
  }

}