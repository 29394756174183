$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.toolbar {
    --height: 48px;

    padding: 0 8px;
    position: fixed;
    z-index: 1000   ;
    bottom: 0;
    width: 100%;
    height: var(--height);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1E2737;
    transition: transform .3s;

    > *:first-child{
        margin-inline-end: 8px;
        width: 172px;
    }

    & .btn-stroke{
        min-width: 120px
    }

    body.linda-pwa &{
        padding-bottom: var(--safe-area-inset-bottom);
        height: calc(var(--safe-area-inset-bottom) + var(--height));
    }

    .has-open-left-sidebar & {
        transform: translateX(270px);
    }
    .has-open-right-sidebar & {
        transform: translateX(-270px);
    }

    body.rtl-lang & {
        direction: rtl;

        .has-open-left-sidebar & {
            transform: translateX(-270px);
        }
        .has-open-right-sidebar & {
            transform: translateX(270px);
        }
    }
}
