$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.form-error {

  width: 100%;
  color: $colorError;
  background: #0C1221;
  border-radius: 0 0 1px 1px;
  margin: 0;
  padding: 6px 20px;
  text-transform: initial;
  @include text(10px, 15px, 400);


  @include breakpoint($bp440) {
    padding: 6px 20px;
  }

  &.is-wide {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .form-label.is-light & {
    background-color: transparent;
    text-align: left;
    text-transform: initial;
    padding-top: 8px;
    padding-bottom: 0;
  }

  .form-label.is-bank-id & {
    align-items: center;
    text-transform: none;

    @include breakpoint($bp1) {
      height: 44px;
    }
  }

  &,
  .ng-untouched~&,
  .ng-valid~& {
    display: none;
  }

  .ng-invalid:focus~& {
    display: none !important;
  }

  .ng-invalid.ng-touched~&:not(.is-only-required),
  .ng-invalid-required.ng-touched~&.is-only-required {
    display: block;
  }

  &>div {
    margin-right: 5px;
  }

  &__minlength {
    display: none;

    .ng-invalid-minlength~.form-error & {
      display: inline;
    }
  }

  &__maxlength {
    display: none;

    .ng-invalid-maxlength~.form-error & {
      display: inline;
    }
  }

  &__pattern {
    display: none;

    .ng-invalid-pattern~.form-error &,
    .ng-invalid-email~.form-error &,
    .ng-invalid-number~.form-error &,
    .ng-invalid-phone~.form-error &,
    .ng-invalid-cpf~.form-error & {
      display: inline;
    }
  }

  &__pattern-letter {
    display: none;

    .ng-invalid-pattern~.form-error &,
    .ng-invalid-letter~.form-error &,
    .ng-invalid-number~.form-error & {
      display: inline;
    }
  }

  &__required {
    display: none;

    .ng-invalid-required~.form-error &,
    .ng-invalid-date~.form-error & {
      display: inline;
    }
  }
  &__same {
    display: none;

    .ng-invalid-login~.form-error & {
      display: inline;
    }
  }
  &__less {
    display: none;

    .ng-valid-parse-remove~.form-error & {
      display: inline-block;
    }
  }

  &__min {
    display: none;

    .ng-invalid-min~.form-error & {
      display: inline;
    }
  }

  &__max {
    display: none;

    .ng-invalid-max~.form-error & {
      display: inline;
    }
  }

  &__amount {
    display: none;

    .ng-invalid-amount~.form-error & {
      display: inline;
    }
  }

  &__password-verify {
    display: none;

    .ng-invalid-password-verify~.form-error & {
      display: inline;
    }
  }

  &__low {
    display: none;

    .ng-invalid-low~.form-error & {
      display: inline;
    }
  }

  &__up {
    display: none;

    .ng-invalid-up~.form-error & {
      display: inline;
    }
  }

  &__number {
    display: none;

    .ng-invalid-number~.form-error & {
      display: inline;
    }
  }

}
.ng-invalid-required.ng-valid-parse-remove~.form-error .form-error__required {
  display: none;
}
body.rtl-lang .form-error {
  text-align: right;
  direction: rtl;
}
