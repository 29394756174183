$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.custom-input {
  padding: 0 0 16px;

  .form-item__label {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #6E3923;
    opacity: 0.7;
    display: block;
    padding-bottom: 4px;
  }

  select,
  input[type='number'],
  input[type='email'],
  input[type='password'],
  input[type='date'],
  input[type='tel'],
  input[type='text'] {
    background: #FFFFFF;
    border: 1px solid #D2AF75;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    text-indent: 20px;
    padding: 14px 20px 14px 0;
    line-height: 1;
    width: 100%;

    &::placeholder {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #B9B9B9;
    }
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;

    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAM1BMVEUAAACahn2ahn2bhn2XhYCVhYCahX2ahn6fgICah3yahnyXg3uZhX2ahX2ahXyZhn2ahn3N8dC9AAAAEHRSTlMA799/IDBgXxCgz0CAv5Bw5GWISAAAAIVJREFUOMvt0MESRTAMheGTVLW4l7z/04oOk5kOqa3hX9F8q4OvV5WI060ri8ilDHqk/ZtEW66d8P7Ty1Y4c0M59WjIPIpGE+DLjkXjDnBk5ayJahnLy5hRFSuZZGtQ58tQ3A+aJ8350ubzZf4f81mnyxXOEWhKm8+XNp8vj/nakmZ8PacVHtgJpsxgYbEAAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 94% center;
  }

  label.show-placeholder {
    &:before {
      content: attr(placeholder);
      position: absolute;
      top: 32px;
      background: white;
      z-index: 9;
      left: 30px;
      width: 80%;
      text-align: left;
      overflow: hidden;
      letter-spacing: 1.3px;

      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #B9B9B9;
    }
  }

  input[type='date'] {
    -webkit-appearance: none;
    width: 100%;
    height: 48px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #797979;
    letter-spacing: .7px;
    text-indent: 20px;
    padding: 14px 20px 14px 0;
    background: #FFFFFF;
    border: 1px solid #D2AF75;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    display: grid;
    justify-content: left;

    &::placeholder {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #B9B9B9;
    }
  }

  input.input-style.ng-valid.ng-dirty {
    box-shadow: none;
    //border-color: $colorLightGreen !important;
    //background-color: $colorWhite !important;

    & ~ .icon-label {
      width: 20px;
      height: 20px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8BAMAAADI0sRBAAAAHlBMVEUAAAAzuUAyukAzukAzukAzukAyukAyuEAyu0AzuUAV5MIAAAAACXRSTlMAn5/vn4+ggH+/ey1UAAAAhklEQVQ4y+3OIQqAQBCFYRUUo9Uj2AQx2MQTWD2CbavZEwiWd1sHhmk7r2iS/evwMS9LpV53juxaAIGcO6CnGD3FWCm+OG6+w/WwMrzhZp8nYPawno3vkdkHjFex2TmEe1hqldtnh9tshxt2uIeVL4o9DsUeV8y4YMIFE06wcMGkcghZ6tc9uxxQtWJL8/gAAAAASUVORK5CYII=');
      position: absolute;
      top: 30px;
      right: 20px;
      background-size: contain;
    }

    & ~ .show-password {
      right: 48px;
    }
  }

  .show-password {
    width: 20px;
    height: 20px;
    top: 28px;
    right: 20px;
    margin-top: unset;

    &.f-icon {
      &:before {
        left: unset;
        top: 6px;
      }
    }

    &:before {
      width: 20px;
      height: 20px;
      background-size: contain;
    }
  }

  input.input-style.ng-invalid.ng-touched {
    border-color: #E02121 !important;
    //background-color: $colorWhite !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.23);
  }

  .g-form-error {
    margin-bottom: 0;
  }

  input[type='checkbox'] {
    display: none;
    & ~ label {
      display: flex;
    }
    & ~ label .check-block {
      width: 26px;
      height: 26px;
      display: inline-block;
      vertical-align: text-top;
      position: relative;

      background: #FFFFFF;
      border: 1px solid #D2AF75;
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.23);
      border-radius: 4px;

    }
    &:checked ~ label .check-block {
      background-color: #42B601;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAYAAAC9QufkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBlZFhDYAgEIWpYANtoA1sohFsgA2IZANtAA2wgefdhA3wEPy294fje2OHEJUAAGXGnBiNmf6IA8TYWrHDmETW/kJLycgNI16YhYYyOJSMvMGb1Q9TZCAqZq7C5oMrgPhFnj19Vg/P+kvQ97TcUkoFFjILLRXQZkdRIlOwiFpcgXEln+INa2x38DXSAgIAAAAASUVORK5CYII=')
    }
    & ~ label .check-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #905E3A;
      align-items: center;
      margin-left: 10px;
      flex-basis: calc(100% - 26px);
      width: calc(100% - 26px);
      word-break: break-word;

      &.is-light {
        color: #664133;
        text-align: left;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .g-form-tooltip {
    background: #FFF3B5;
    box-shadow: 0 4px 5px rgba(110, 57, 35, 0.45);
    border-radius: 8px;
    margin: 6px 0 0;
    padding: 12px 16px;

    p {
      padding-left: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      letter-spacing: 0.05em;
      color: #DE0000;

      &:before,
      &:after {
        content: none;
      }
    }
  }

  .g-input.ng-valid-min + .g-form-error .g-tooltip-min,
  .g-input.ng-valid-number + .g-form-error .g-tooltip-number,
  .g-input.ng-valid-low + .g-form-error .g-tooltip-low,
  .g-input.ng-valid-up + .g-form-error .g-tooltip-up {
    color: #33B940;
  }

  input[type='radio'] {
    display: none;
    & ~ label {
      display: flex;
      align-items: center;
    }
    & ~ label .radio-block {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: text-top;
      position: relative;

      background: #F5F4EE;
      border: 1px solid rgba(212, 207, 186, 0.46);
      box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.28);
    }
    &:checked ~ label .radio-block {
      background-color: #F5F4EE;
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 6px solid #42B601;
        position: absolute;
        box-sizing: content-box;
        top: 0;
        left: 0;
      }
    }
    & ~ label .radio-text {
      display: inline-block;
      margin-left: 8px;
      max-width: 100px;
      overflow: hidden;
      letter-spacing: 0.7px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #6E3923;
    }
  }

  .gender-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
  }

  .gender-item {
    background: linear-gradient(180deg, #FFF7ED 0%, #EFD696 100%);
    border: 1px solid rgba(184, 144, 89, 0.75);
    box-shadow: 0px 1px 1px rgba(48, 23, 5, 0.26);
    border-radius: 8px;
    padding: 14px 20px;
    width: 100%;
  }

  .input-wrap {
    position: relative;
    margin-bottom: 12px;
    display: block;
  }
}
