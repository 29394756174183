$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-app {
  min-height: 44px;
  padding: 7px 8px;
  display: grid;
  grid-template-columns: 28px auto;
  grid-template-rows: auto auto;
  align-items: center;
  border: 1px solid #2A394E;
  border-radius: 2px;
  background-color: #273242;
  column-gap: 8px;

  &.has-arrow {
    background-image: url($cdn + '/rabona/icons/arrow-rounded.svg');
    background-repeat: no-repeat;
    padding: 7px 16px 7px 7px;
    gap: 0px 8px;
    background-size: 5px 8px;
    background-position: center right 7px;
  }

  &::before {
    content: '';
    grid-row: span 2;
    width: 28px;
    height: 28px;
    display: flex;
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: center;
  }

  &.is-ios::before {
    background-image: var(--icon-ios-square);
  }
  &.is-android::before {
    background-image: var(--icon-android-square);
  }
  &.is-telegram::before {
    background-image: url($cdn + '/rabona/apps/telegram.svg');
  }
  &.is-twitter::before  {
    background-image: url($cdn + '/rabona/apps/twitter.svg');
  }
  &.is-facebook::before  {
    background-image: url($cdn + '/rabona/apps/facebook.svg');
  }
  &.is-youtube::before  {
    background-image: url($cdn + '/rabona/apps/youtube.svg');
  }

  &__title {
    @include text(12px, 14px, 400, #a5afc0);
    opacity: 0.5;
  }

  &__description {
    @include text(14px, 16px, 400, #ffffff);
  }
}
