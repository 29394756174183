$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.game-top-block {
  width: 100%;
  min-height: 72px;
  padding: 0;
  position: relative;

  body.rtl-lang & {
    direction: rtl;
  }

  &__header {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 72px;
    padding: 16px 20px;
  }

  &__item{
    height: 40px;
    padding-inline-start: 40px;
    font-size: 14px;
    line-height: 16px;
    color: $grey;
    border: 1px solid $lightBlue;
    border-radius: 2px;
    background-color: $blue;
    background-position: 10px center;
    background-repeat: no-repeat;

    transition: .3s all;

    width: calc(50% - 24px);
    position: absolute;
    top: 16px;
    inset-inline-end: 20px;

    display: flex;
    align-items: center;

    @include hover(){
      background-color: #2D394C;
    }

    body.rtl-lang & {
      background-position: calc(100% - 10px);
    }
  }

  .icon-provider,
  .icon-category {
    transform: translateX(0);
  }

  &.has-categories-btn {
    .icon-category {
      display: flex;
    }
    .icon-provider {
      display: none;
    }
  }

  &:not(.has-categories-btn) {
    .icon-category {
      display: none;
    }
    .icon-provider {
      display: flex;
    }
  }

  .btn-icon {
    position: absolute;
    top: 12px;
    inset-inline-start: -48px;
    transform: translateX(0);
    transition: .3s all;

    body.rtl-lang & {
      scale: -1 1;
    }
  }

  &.has-search-mode {
    position: absolute;
    background: #0c1221;
    Z-INDEX: 9;
    width: 100%;
    height: calc(100% - 48px);

    .btn-icon {
      transform: translateX(52px);
    }
    .is-right {
      transform: translateX(calc(100% + 20px));

      body.rtl-lang & {
        transform: translateX(calc((100% + 20px) * -1));
      }
    }
  }
}
