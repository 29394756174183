$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-history-item {
  background: #FFFFFF;
  @include text(14px, 17px);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center right 16px;

  &__wrap {
    border-bottom: 1px solid $grey;
    max-height: 78px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px 40px 15px 20px;
    position: relative;
  }

  & .icon-arrow-down__after:after {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: calc(50% - 10px);
    transform: rotate(-90deg);
    inset-inline-end: 16px;
    transition: 0.2s;

    body.rtl-lang & {
      transform: rotate(90deg);
    }
  }
  &__block {
    &.is-order {
      order: 1;
    }
    &.is-strong {
      font-weight: bold;
    }
    &.is-opacity {
      opacity: 0.8;
    }
    &.is-padding {
      padding-bottom: 4px;
    }

    &:first-child {
      body.rtl-lang & .balance-history-item__value::before {
        content: 'a';
        display: inline;
        font-size: 0;
        line-height: 0;
        pointer-events: none;
      }
    }
  }
  &__title {
    color: $grey;
    text-transform: uppercase;
    display: none;
  }
  &__value {
    color: $lightBlue;
    &.is-strong {
      font-weight: bold;
    }
    &.is-green {
      color: #26C000;
      font-weight: bold;
    }
    &.is-red {
      color: $red;
      font-weight: bold;
    }
  }

  body.rtl-lang & {
    direction: rtl;
  }

  @include breakpoint( $bp1 ){

    &__wrap {
      flex-direction: row;
      min-height: 76px;
      max-height: 100%;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;
    }

    &__block {
      width: calc(25% - 8px);
      text-align: center;
      margin-right: 8px;

      &.is-full {
        width: 100%;
        margin-right: 0;
      }
      &.is-order {
        order: 0;
      }
    }
    &__title {
      display: block;
      padding-bottom: 4px;
    }
    &__value {
      font-weight: bold;
    }
  }
}

.balance-history-hidden {
  display: none;
  background-color: $grey;
  padding: 16px 20px 12px 20px;
  border-bottom: 1px solid $grey;
  @include text(14px, 17px);
}

/*HELPERS*/

.is-opened {
  & .balance-history-hidden {
    display: block;
  }
  & .balance-history-item__wrap::after {
    transform: rotate(0deg);
    transition: 0.2s;
  }
}