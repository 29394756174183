$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-guide {
    &__inner {
        width: 100%;
        padding: 48px 20px 38px;
        position: relative;
        background-color: #162334;
    }

    &__close {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 12px;
        right: 16px;
        background: var(--icon-close) center/24px no-repeat;
        @include breakpoint($bp2) {
            right: 36px;
        }
    }

    &__content {
        section {
            padding: 20px 0;

            &:not(:last-child) {
                border-bottom: 2px solid #CC0034;
            }

            &:first-of-type {
                border-top: 2px solid #CC0034;
            }
        }

        h2 {
            font-weight: 400;
            font-size: 20px;
            line-height: 1.15em;
            color: #FFFFFF;
            margin-bottom: 20px;

            @include breakpoint($bp1) {
                padding-left: 20px;
            }
        }

        h3,
        h4 {
            text-transform: uppercase;
            margin-bottom: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.25em;
            color: #A5AFC0;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5em;
            color: #A5AFC0;

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            &+h3,
            &+h4 {
                margin-top: 24px;
            }
        }

        ul {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5em;
            color: #A5AFC0;
            list-style-type: disc;
            padding-left: 24px;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        .video-crypto {
            display: flex;
            justify-content: center;
        }

        .screenshots-crypto {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            width: auto;
            min-width: 160px;
        }
    }
}