$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.entrance-bnr-wrapper {
  height: 470px;
  position: relative;

  @include s {
    height: 305px;
  }

  @include m {
    height: 330px;
  }

  .game-slider__dots {
    display: none !important;
  }

  &--authorized {
    height: 395px;

    @include s {
      height: 290px;
    }

    @include m {
      height: 330px;
    }

    .game-slider__dots {
      display: flex !important;
    }
  }
}

.entrance-bnr {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  height: 460px;
  padding: 20px;
  background-position: center;

  @include s {
    height: 300px;
  }

  @include m {
    height: 325px;
    padding: 38px 20px;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 20;
    background-image: linear-gradient(#0c122100 0%, #0c1221 100%);
    background-position: left bottom -1px;
    background-repeat: no-repeat;
    background-size: 100% 174px;
    position: absolute;
    top: 0;
    left: 0;
  }

  body.rtl-lang & {
    direction: rtl;
  }

  &--authorized {
    padding: 8px 20px 35px;
    align-items: flex-end;
    height: 390px;

    @include s {
      height: 290px;
    }

    @include m {
      height: 325px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 280px;
      background: linear-gradient(90deg, #0d1322 0%, transparent 100%);
      pointer-events: none;
    }
  }

  &.is-pnp {
    background-image: url($cdn + '/rabona/pay-and-play/bg_banner_payandplay.png');
    width: 100vw;
    left: 50%;
    transform: translate(-50%);
    justify-content: center;
    padding-top: 75px;
    overflow: hidden;
    margin-top: 0;
  }

  &__video {
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(12, 18, 41, 0.7) 0%, rgba(12, 18, 33, 0.7) 100%),
      linear-gradient(180deg, rgba(12, 18, 33, 0) 0%, #0c1221 100%);
  }

  &__img {
    width: 390px;
    height: 390px;
    z-index: 10;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -27px;
    left: 50%;
    transform: translateX(-47%);

    body.rtl-lang & {
      transform: translateX(-53%);
    }

    @include s {
      left: 80%;
      top: 10px;
      transform: translateX(-50%);

      body.rtl-lang & {
        left: 20%;
      }
    }

    @include m {
      top: 14px;
      left: 69%;

      body.rtl-lang & {
        left: 31%;
      }
    }

    @include l {
      top: 30px;
      left: 71%;

      body.rtl-lang & {
        left: 29%;
      }
    }

    .entrance-bnr--authorized & {
      z-index: 0;

      @include s {
        top: 10px;
        left: 67%;

        body.rtl-lang & {
          left: 33%;
        }
      }

      @include m {
        top: 14px;
        left: 69%;

        body.rtl-lang & {
          left: 31%;
        }
      }

      @include l {
        top: 30px;
        left: 53%;

        body.rtl-lang & {
          left: 47%;
        }
      }
    }

    .entrance-bnr--authorized.is-sport-bonus & {
      top: -10px;
      height: 316px;

      @include s {
        top: 10px;
        left: 71%;
        height: 290px;

        body.rtl-lang & {
          left: 29%;
        }
      }

      @include m {
        top: -10px;
        height: 333px;
      }

      @include l {
        left: 55%;
        height: 353px;

        body.rtl-lang & {
          left: 45%;
        }
      }
    }
  }

    .entrance-bnr--authorized & {

    .entrance-bnr--unauthorized & {
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    // max-width: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 21;

    @include s {
      max-width: 330px;
    }

    @include m {
      max-width: 400px;
    }
  }

  &__background{
    z-index: -2;
    width: 100%;
    height: 100%;
    background-size: auto 463px;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    @include s {
      background-size: cover;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 505px;
    width: 738px;
    padding: 30px 55px;
    text-align: center;
    margin: 0 auto;

    border: 8px solid #fff;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      top: -35px;
    }

    &:before {
      width: 665px;
      height: 665px;
      right: 100%;
      margin-right: -48px;
      background-image: url($cdn + '/rabona/pay-and-play/cup-gold.png'),
        url($cdn + '/rabona/pay-and-play/cup-silver.png');
      background-position: right center, left center;
      background-size: 372px 665px, 294px 520px;
    }

    &:after {
      width: 926px;
      height: 658px;
      left: 100%;
      margin-left: -150px;
      background-image: url($cdn + '/rabona/pay-and-play/desktop-players.png');
      background-size: 100%;

      background-position: center;
    }

    .pay-n-play & {
      padding: 30px 55px 22px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: $yellow;
    text-shadow: 0 0 6px #0C1221;

    @include m {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__offer {
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0px 0px 6px #0C1221;

    @include m {
      font-size: 40px;
      line-height: normal;
    }
  }

  &__btn {
    margin-top: 12px;
    min-width: 280px;
    max-width: 392px;
    pointer-events: auto;
    display: inline-block;
    width: 100%;
    @include s {
      min-width: min-content;
      width: max-content;
    }
  }

  &__form {
    position: absolute;
    bottom: 33px;
    // transform: translateY(-50%);
    left: 20px;
    right: 20px;
    z-index: 4;

    @include s {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }

    @include m {
      left: 20px;
    }

    @include l {
      left: 20px;
    }
  }

  &__pagination {
    position: relative;
    width: fit-content;
    margin: 0 auto 0;

    & .slider__dots {
      left: 0;
      transform: none;
      position: relative;
      bottom: 0;
    }

    & .slider__main-arr {
      position: absolute;
      filter: invert(77%) sepia(3%) saturate(1570%) hue-rotate(179deg) brightness(90%) contrast(91%);
      border: none;
      height: 17px;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      width: 17px;
      bottom: -2px;
      appearance: none;

      &.is-prev {
        left: -35px;
        background-image: var(--icon-arr-l);

        &:hover {
          filter: invert(80%) sepia(99%) saturate(3683%) hue-rotate(9deg) brightness(107%) contrast(103%);
        }
      }

      &.is-next {
        right: -35px;
        background-image: var(--icon-arr-r);

        &:hover {
          filter: invert(80%) sepia(99%) saturate(3683%) hue-rotate(9deg) brightness(107%) contrast(103%);
        }
      }
    }
  }
}
