$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.swiper-slide {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    padding: 46px 0 0 0;
  }

@media screen and (min-width: 540px) {
  .swiper-slide {
    padding: 0;
  }
}