$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-promo {
  display: block;
  border: none;
  @include text(20px, 56px, 700, $btnBlack);

  text-transform: uppercase;
  min-width: 160px;
  padding: 0 20px;
  text-align: center;
  letter-spacing: 0.01em;

  background: $yellow;
  @include ellipsis;
  @include hover() {
    background: #7cf74c;
    text-decoration: none;
  }

  &.has-w100 {
    width: 100%;
  }
  &.has-w280 {
    width: 280px;
    margin: 0 auto;
  }
  &.disabled,
  &:disabled {
    pointer-events: none;
    background-color: $grey;
  }
}

body.rtl-lang .btn-promo {
  text-align: center;
}
