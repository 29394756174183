$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-popup-win {
    width: 345px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
    &__image {
        width: 100%;
        margin-bottom: -8px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(.5) translateY(200px);
        opacity: 0;
        animation: startWinnerClawImgBox .8s forwards;
        img {
            width: 282px;
            height: 282px;
            object-fit: contain;
            position: relative;
            animation: startWinnerClawImg .5s forwards;
        }
        &::before {
            content: '';
            width: 500px;
            height: 500px;
            background: url($cdn + '/rabona/crab/explosion-min.png') no-repeat center / contain;
            position: absolute;
            transform: translate(-50%, -50%);
            animation: spin 160s linear infinite;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        transform: translateY(300px);
        opacity: 0;
        animation: startWinnerClawBody .4s forwards;
        animation-delay: .4s;
        background: #162334;
        box-sizing: border-box;
        padding: 40px 20px;
        width: 345px;
        min-height: 290px;
        position: relative;
        &.no-delay {
            animation-delay: 0s;
        }
        &::before {
            background: none;
            content: "";
            display: block;
            position: absolute;
            pointer-events: none;
            // height: 140px;
            // background-size: contain;
            // top: -85px;
            // left: -50px;
            // width: calc(100% + 100px);
            // z-index: 1100;
        }
        &.hide-before::before {
            display: none !important;
        }
    }
    &__name {
        text-align: center;
    }
    &__title {
        font-weight: normal;
        font-size: 28px;
        text-align: center;
        color: #fff;
        padding: 0 0 10px 0;
        margin: 0;
    }
    &__description {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 15px;
        text-align: center;
        color: #A5AFC0;
        padding: 0;
    }
    &__amount {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 55px;
        text-align: center;
        span {
            &.prize {
                font-size: 74px;
                line-height: 0;
                font-weight: 900;
                color: #ECFD00;
            }
            &.coins {
                font-size: 20px;
                font-weight: normal;
                padding: 15px 0 35px 0;
                color: #fff;
                margin-top: 40px;
                line-height: 0;
                text-align: center;
            }
        }
    }
    &__btns {
        position: relative;
        margin: 0 auto;
        & button {
            width: 257px;
            height: 48px;
            line-height: 48px;
        }
    }
}

@keyframes spin {
    from {
        transform: translate(-50% -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes startWinnerClawImgBox {
    0% {
        transform: scale(.5) translateY(200px);
        opacity: 0;
    }
    50% {
        transform: scale(1) translateY(200px);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }
}

@keyframes startWinnerClawImg {
    0% {
        transform: scale(.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes startWinnerClawBody {
    0% {
        transform: translateY(300px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 320px) {
    .cash-crab-popup-win {
        width: 320px;
        &__body {
            width: 320px;
            padding: 20px;
        }
    }
}