$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.link-btn {
  background-color: transparent;
  border: none; 
  padding: 0;
  
  display: inline-block;

  font-weight: normal;
  font-size: 16px;
  line-height: 48px;

  color: white;

  &.is-yellow {
    color: #EFFB37;
  }

  @include hover(){
    color: #CBD0D8;
  }

}