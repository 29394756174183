$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.bonus{
  position: relative;
  width: 100%;
  background-color: #192333;
  border-radius: 1px;
  text-align: center;

  &__header{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__amount{
    text-transform: uppercase;
    @include text(null, null, 700, #fff );

    overflow: hidden;
  }

  &__btn-stroke{
    width: 160px;
  }

  &__body{
    padding: 0 20px 20px;
    text-align: left;
  }
  
  &__row{
    max-width: 340px;
    margin: 0 auto;
    width: 100%;  
    display: flex;
    align-items: center;
    min-height: 55px;
    padding: 8px 0;
    border-bottom: 1px solid $blue;
    
    &-label{
      margin-right: 8px;
      width: 85px;
      flex-shrink: 0;
  
      @include ellipsis;
      @include text(12px, 14px, null, $grey);
      
      text-transform: uppercase;
    }
  
    &-text{
      flex-grow: 1;
      text-align: right;
      @include text(16px, 1.2, null, #fff);
      overflow: hidden;

      &--percent {
        body.rtl-lang & {
          &::before {
            content: 'مكافأة نشطة';
            font-size: 0;
            line-height: 0;
            pointer-events: none;
          }
        }
      }

      &.currency-output {
        body.rtl-lang & {
          direction: ltr;
        }
      }
    }
  }

  &__name{
    display: block;
    max-width: 100%;
    overflow: hidden;
    
    @include text(16px, 19px);
    text-align: center;

    body.rtl-lang & {
      &::before {
        content: 'مكافأة نشطة';
        font-size: 0;
        line-height: 0;
        pointer-events: none;
      }
    }
  }

  &__link-name{
    color: $yellow;
    text-decoration: underline;
  }
}