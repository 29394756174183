$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-list {
  display: flex;
  flex-direction: column;
  margin: 8px 0 20px;

  @include breakpoint($bp2) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    padding: 0 10px;
  }

  @include breakpoint($bp3) {
    max-width: 985px;
    margin: 0 auto;
  }
}
