$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.autobonus{
    color:#fff;

    &__header{
        position: relative; // for the tape
        min-height: 166px;
        justify-content: space-between;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMSIgZmlsbD0iI0VGRkIzNyIgZmlsbC1vcGFjaXR5PSIuMzUiLz48L3N2Zz4=') center repeat,
            linear-gradient(#CC0034 0%, #CC0034 100%) center repeat;
        background-size:  auto, contain;
        padding: 20px;
        margin: 0 -20px;
    }

    &__label{
        min-width: 80px;
        max-width: 100%;
        position: relative;
        z-index: 1;
        margin-bottom: 8px;
        padding: 6px 19px;
        
        color: $black;

        &-text {
            width: 100%;
            @include ellipsis;
            padding: 0 2px;
            text-transform: uppercase;
            @include text(14px, 16px, 700);
            font-style: italic;
            text-align: center;
        }
        &::before{
            content: '';
            position: absolute;
            z-index: -1;

            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            box-shadow: 2px 3px 0px rgba(42, 51, 65, 0.25);
            transform: skew(-21deg);
        
            .is-new & {
                background-color: #fff;
            }

            .is-active & {
                background-color: $yellow;

            }
        }
    }

    &__text {
        text-align: center;
        margin-bottom: 20px;
    }

    &__amount {
        width: 100%;
        @include text(28px, 34px, 700, #fff);
        text-transform: uppercase;
        @include ellipsis;
        margin: auto 0;

        .cashbox-tint__sum {
            body.rtl-lang & {
                display: inline-block;
                direction: ltr;
            }
        }
    }

    &__name{
        max-width: 100%;
        overflow: hidden;
        @include text(16px, 24px, null, #fff);

        body.rtl-lang & {
            &::before {
                content: 'ب';
                display: inline;
                font-size: 0;
                pointer-events: none;
            }
        }
    }
    &__link-name{
        color: $yellow;
    }

    &__btn{
        margin: 20px auto;
        width: 100%;
        max-width: 352px;
    }

    &__link{
        overflow: hidden;
        max-width: 100%;
        margin-top: 12px;
    }

    @include breakpoint(540px){
        &__header{
            margin: 0;
        }
    }
}
