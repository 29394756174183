$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.season-popup {
  .popup {
    &__wrap {
      padding: 48px 20px;
      background: #162334;
      position: relative;
      min-height: 100%;

      @include s {
        padding: 30px 40px 40px;
        max-width: 472px;
        margin-inline: auto;
        margin-block-start: 40px;
        min-height: auto;
      }

      body.rtl-lang & {
        direction: rtl;
      }

    }

    &__close {
      width: 48px;
      height: 48px;
      top: 0;
      inset-inline-end: 0;
      background-size: 18px;
      background-position: center;
      background-repeat: no-repeat;

      @include s {
        top: 16px;
        inset-inline-end: 12px;
      }
    }

    &__content {
      @include s {
        max-width: 392px;
        margin: 0 auto;
      }
    }
  }

  &__title {
    font-size: 20px;
    color: #fff;
    line-height: 1;
    margin-bottom: 12px;

    @include s {
      font-size: 24px;
      margin-bottom: 22px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: #A5AFC0;
    margin-bottom: 22px;

    > span {
      display: inline-block;
    }
  }

  &__image-box {
    margin: 0 -20px 20px;

    @include s {
      margin: 0 0 20px;
      text-align: center;
    }
  }

  &__image {
    width: 100%;

    @include s {
      width: 324px;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }

  &__cta {
    font-size: 20px;
    line-height: 56px;
  }

  &__details {
    text-decoration: underline;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding: 16px 0;
    letter-spacing: 0.14px;
    font-size: 14px;
  }
}