$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-filter {
  margin: 30px 0;
  position: relative;

  &__wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    column-gap: 8px;
    padding: 0 32px;
    @include without-scrollbar();

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 32px;
  }

  &::before {
    inset-inline-start: 0;
    background: linear-gradient(90deg, rgba(42, 57, 78, 1) 50%, rgba(42, 57, 78, 0) 100%);
  }

  &::after {
    inset-inline-end: 0;
    width: 40px;
    background: linear-gradient(270deg, rgba(42, 57, 78, 1) 0%, rgba(42, 57, 78, 0) 100%);
  }

  &__item {
    min-height: 43px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    font-size: 16px;
    color: #A5AFC0;
    background-color: #162334;
    margin-bottom: 8px;
    cursor: pointer;

    &.is-active {
      background-color: #EFF5FD;
      color: #162334;
    }
  }
}