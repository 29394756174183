$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-lang {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 16px;
  max-width: 288px;
  margin: 0 auto;
  padding-bottom: 40px;

  body.rtl-lang & {
    direction: rtl;
  }

  &-title {
    width: 100%;
    max-width: 340px;
    margin-inline: auto;
    text-align: center;
  }

  &__item {
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: unset;
    $item: &;

    &-img {
      max-width: 56px;
      width: 100%;
      padding: 8px;
      background: #1c2738;
      border: 1px solid #273242;
      border-radius: 2px;
    }

    &-info {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      color: #a7afbe;
      font-weight: 400;
      padding-block: 8px;
      padding-inline: 8px 0;
      width: 100%;

      span {
        font-size: 10px;
        line-height: 12px;
      }

      p {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &:hover,
    &.is-active {
      #{$item}-img {
        background: #2a394e;
      }

      #{$item}-info {
        color: #fff;
      }
    }
  }
}