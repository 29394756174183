$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.trustly-optional-data {
    margin-top: 20px;
    padding: 16px 6px;
    background-color: $blueDark;
    text-align: center;
    &__title {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        color: $white;
    }
    &__logo {
        display: inline-flex;
        flex: 1 0 18px;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        background-image: var(--icon-notification-info-white);
        background-size: contain;
        background-repeat: no-repeat;
    }
    &__btn {
        margin-top: 16px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $grey;
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__form {
        text-align: left;
    }
    @include breakpoint( $bp1 ) {
        padding: 16px;
    }
 }