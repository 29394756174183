$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.game-box3{
    @include game-item-size-var;
    @each $i in (sm, m){
        &.has-item-#{$i}{
            height: calc(var(--game-item-#{$i}-height) + 27px);
            &::before, &::after{
                @include game-item-size(#{$i});
            }
        }
    }

    &.has-item-sm, &.has-item-m {
        position: relative;
        &::before, &::after{
            position: absolute;
            z-index: -1;
            top: 0;
            inset-inline-start: 0;
            content: ''; 
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 2px;
        }
        &::before{
            transform: translateX(20px);

            body.rtl-lang & {
                transform: translateX(-20px);
            }
        }
        &::after{
            transform: translateX(calc(100% + 28px));

            body.rtl-lang & {
                transform: translateX(calc((100% + 28px) * -1));
            }
        }
    }
}   
