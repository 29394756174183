$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-stroke {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  text-transform: uppercase;
  min-width: 160px;
  padding: 0 20px;
  text-align: center;

  background: transparent;
  border: 2px solid #FFFFFF;
  color: white;
  @include ellipsis;
  @include hover(){
    background: #FFFFFF;
    color: #080F21;
    text-decoration: none;
  }

  &.is-inversion {
    border: 2px solid $lightBlue;
    color: $lightBlue;

    @include hover(){
      background: $lightBlue;
      color: $white;
    }
  }

  &.is-on-gamehall {
    margin: 0 auto 20px;
    display: block;
    width: 196px;
  }

  body.rtl-lang & {
    text-align: center;
  }

}