$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-title {
  @include text(24px, 40px, bold, $white);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 28px;

  body.rtl-lang & {
    text-align: center;
  }

  @include breakpoint($bp1) {
    margin-bottom: 60px;
  }
  @include breakpoint($bp2) {
    margin-bottom: 70px;
  }

}
