$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
  min-height: 100%;
  padding: 48px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  body.rtl-lang & {
    direction: rtl;
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 280px;
    min-height: 46px;
  }
  &__category-item {
    padding: 8px;
    margin: 6px 4px;
    border: 1px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    @include text(14px, 16px, 400, #fff);
    text-transform: uppercase;
    transition: 0.2s ease opacity;

    @include hover() {
      text-decoration: none;
      opacity: 0.8;
    }
  }

  &-fav {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    inset-inline-end: 2px;
    width: 40px;
    height: 40px;
    z-index: 101;
    @include breakpoint($bp2) {
      height: 46px;
    }
    &-wrap {
      position: relative;
      .has-quick-dep + & {
        display: none;
      }

      body.rtl-lang & {
        direction: rtl;
      }
    }

    &.is-guest {
      top: 0;
    }

    &::after,
    &::before {
      position: absolute;
      background-position: center;
      width: 20px;
      height: 20px;
      transition: 0.2s ease transform;
    }
    &::before {
      transform: scale(0);
      opacity: 0;
    }
    &::after {
      transform: scale(1);
      opacity: 1;
    }

    &.is-favourite {
      &::before {
        transform: scale(1);
        opacity: 1;
      }
      &::after {
        transform: scale(0);
        opacity: 0;
      }
    }
  }
}
