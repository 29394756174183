$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.accordion {
    & > div {
        & > p {
            @include text(16px, 24px, 400, $grey);
            margin: 0 auto;
            max-width: 820px;
            padding-bottom: 8px;
            padding-inline-start: 16px;

            &:first-child {
                padding-bottom: 0;
            }            
        }
    }

    section {
        counter-increment: section; 
        margin-bottom: 4px;
        overflow: hidden;

        h1 {
            display: block;
        }

        h2,
        h1 {
            background-image: linear-gradient(0deg, $red, $red);
            background-size: 100% 2px;
            background-repeat: no-repeat;
            background-position: 0 0;
            @include text(16px, 19px,700, $white);
            text-transform: uppercase;
            list-style: my-counter;
            text-align: start;
            padding: 24px 48px 24px 36px;
            padding-block: 24px;
            padding-inline: 36px 48px;
            margin-bottom: 0;
            position: relative;
            z-index: 1;
            transition: .2s margin;

            @include text(16px, 19px,700, $white);

            &:after{
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                inset-inline-end: 20px;
                margin: auto ;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00LjggNi41bDUuMSA1IDUuMi01IDEuMSAxLTYuMyA2LTYuMS02IDEtMXoiIGZpbGw9IiNBN0FGQkUiIHN0eWxlPSIiLz48L3N2Zz4=');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                transition: .3s ease;
            }

            &:before{
                content: counter(section);
                position: absolute;
                top: 2px;
                inset-inline-start: 0;
                bottom: 0;
                margin: auto;
                width: 26px;
                height: 26px;
                padding-block: 3px 7px;
                padding-inline: 0 10px;
                clip-path: polygon(0 0, 100% 0, 62.5% 100%, 0% 100%);
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjIyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDBoMjRsLTkuNjg1IDIySDBWMHoiIGZpbGw9IiNDQzAwMzQiIHN0eWxlPSIiLz48L3N2Zz4='), url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjIyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDBoMjRsLTkuNjg1IDIySDBWMHoiIGZpbGw9IiM5OTAwMjciIHN0eWxlPSIiLz48L3N2Zz4=');
                background-size: 24px 22px, 0;
                background-position: 0 0,  0px 6px;
                background-repeat: no-repeat; 
    
                @include text(14px, 16px, 700, #fff); 
                text-transform: uppercase;
                text-align: center;
                transition: .2s ease background-size;

                body.rtl-lang & {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI0IDIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjIiPgoJPHRpdGxlPmltYWdlLXN2ZzwvdGl0bGU+Cgk8c3R5bGU+CgkJLnMwIHsgZmlsbDogI2NjMDAzNCB9IAoJPC9zdHlsZT4KCTxwYXRoIGlkPSJMYXllciIgY2xhc3M9InMwIiBkPSJtMjQgMGgtMjRsOS43IDIyaDE0LjN6Ii8+Cjwvc3ZnPg=='), url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI0IDIyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjIiPgoJPHRpdGxlPmltYWdlICgxKS1zdmc8L3RpdGxlPgoJPHN0eWxlPgoJCS5zMCB7IGZpbGw6ICM5OTAwMjcgfSAKCTwvc3R5bGU+Cgk8cGF0aCBpZD0iTGF5ZXIiIGNsYXNzPSJzMCIgZD0ibTI0IDBoLTI0bDkuNyAyMmgxNC4zeiIvPgo8L3N2Zz4=');
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 37.5% 100%);
                    background-position: right, 0 6px;
                }
            }

            & ~ * {
                transform: translateY(-30px);
                opacity: 0;
                max-height: 0;
                transition: .2s ease transform, .2s height, .2s ease margin-bottom;
            }

            & ~ table {
                display: none;
            }

            &.is-open {
                background-size: 100% 100%;
                margin-bottom: 16px;

                &:after {
                    transform: rotate(180deg);
                }

                &:before{
                    background-size: 24px 22px, 24px 22px;
                }

                & ~ * {
                    transform: translateY(0px);
                    max-height: unset;
                    opacity: 1;
                    margin: 0 20px 16px;
                }

                & ~ table  {
                    display: block;
                }

                & ~ *:last-child{
                    margin-bottom: 24px;
                }

                & ~ p{
                    & > span{
                        font-weight: 700;
                        margin-inline-end: 12px;

                        body.rtl-lang & {
                            float: right;
                        }
                    }
                }
            }
        }
    }

    &.info_article {
        section {
            h2,
            h1 {
                padding-block: 24px;
                padding-inline: 20px 45px;

                &:before {
                    display: none;
                }
            }
        }
    }

    body.rtl-lang & {
        direction: rtl;
    }
}
