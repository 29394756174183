$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.home {
  &__banner {
    margin-bottom: 24px;

    &:empty {
      display: none;
    }
  }

  &__promos {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-image: url($cdn + '/rabona/entrance-page/background.webp');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 0;
    width: 100%;
    min-height: 576px;
    gap: 8px;
    margin: 0 auto;
    padding: 24px 20px 0;

    body.rtl-lang & {
      direction: rtl;
    }

    @include breakpoint($bp2) {
      min-height: 420px;
      flex-wrap: wrap;

      background-size: cover;
      flex-direction: row;
      justify-content: center;
      padding: 44px 20px 0;

      &:nth-child(2) {
        .home-promo__content {
          background: #192333;
        }
      }
    }
  }

  &__events {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    .home__categories + & {
      margin-bottom: 32px;
    }

    .game-box-title .title {
      margin-inline-start: 0;
    }

    &-link {
      .game-box-title & {
        display: none;

        @include breakpoint($bp1) {
          display: inline-block;
        }
      }
    }
  }

  &__categories {
    width: 100%;
    margin: 0 auto;

    .title {
      margin-left: 20px;
    }
  }
}
