$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.bonus-active {
    width: 100%;
    max-width: 420px;
    padding: 20px 0 28px;
    margin-bottom: 20px;

    &__amount{
        @include text(24px, 28px);
        text-align: center;
        margin:0 20px 8px;
    }

    &__name{
        color: $grey;
        margin: 0 20px 20px;
    }
    &__img{
        width: 100%;
        height: auto;
    }

    &__btn{
        width: 280px;
        margin-bottom: 20px;
        display: inline-block;
    }

    @include breakpoint($bp1){
        & .bonus__row-label{
            width: 123px;
        }
    }

}