$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.link-btn-up {
  background-color: transparent;
  border: none; 
  padding: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;

  height: 48px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  text-transform: uppercase;

  color: #FFFFFF;

  @include hover(){
    color: #CBD0D8;
  }

}