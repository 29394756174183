$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.game-search-result {
  background: #0c1221;
  padding: 8px 20px;
  display: none;
  .has-search-mode & {
    display: block;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 50px;
      background-image: linear-gradient(to bottom, #0c1221, transparent);
    }
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #A7AFBE;
    margin-bottom: 16px;
  }

  &__empty {
    font-size: 14px;
    line-height: 16px;
    color: #A7AFBE;
    padding-top: 16px;
    border-top: 1px solid #293241;
  }

}
