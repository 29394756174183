$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
// BP
@mixin s {
    @media screen and (min-width: #{$s}) {
        @content;
    }
}

@mixin m {
    @media screen and (min-width: #{$m}) {
        @content;
    }
}

@mixin l {
    @media screen and (min-width: #{$l}) {
        @content;
    }
}

@mixin rate-star{
    background-image: var(--icon-star-sharp);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    filter: grayscale(1);
    &.is-active {
        filter: none;
    }
}
// ellipsis
@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin hover() {
    &.ng-tap--start:not(.ng-tap--move) {
        @content;
    }
}

@mixin breakpoint($width) {
    @media ( min-width: #{$width} ) {
        @content;
    }
}

@mixin text-dots() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin color-gradient($value) {
    background: #{$value};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin without-scrollbar(){
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar  {
        display: none;
        height: 0;
        width: 0;
    }
}

@mixin text( $size: null, $lineHeight: null, $weight: null, $color: null ) {
    @if $size != null {
        font-size: $size;
    }

    @if $lineHeight != null {
        line-height: $lineHeight;
    }

    @if $weight != null {
        font-weight: $weight;
    }

    @if $color != null {
        color: $color;
    }
}


@mixin shadow-before-after( $width: 20px, $height: 48px, $color1: 12, $color2: 18, $color3: 33 ){

    &::before, &::after {
        z-index: 1;
        content: '';
        width: $width;
        height: $height;
        right: 0;
        top: 0;
        position: absolute;
        background: linear-gradient(to left , rgb($color1, $color2, $color3), rgba($color1, $color2, $color3, 0));
        background: -webkit-linear-gradient(to left, rgb($color1, $color2, $color3), rgba($color1, $color2, $color3, 0));
        opacity: 1;
      }
      &::before {
        right: auto;
        left: 0;
        background: linear-gradient(to right, rgb($color1, $color2, $color3), rgba($color1, $color2, $color3, 0));
        background: -webkit-linear-gradient(to right, rgb($color1, $color2, $color3), rgba($color1, $color2, $color3, 0));
      }

}

@mixin game-item-size-var {
    --game-item-sm-height: 133px;
    --game-item-sm-width: 88px;
    --game-item-m-width:  112px;
    --game-item-m-height:  168px;

    @include breakpoint($bp1){
        --game-item-sm-height: 180px;
        --game-item-sm-width: 119px;
        --game-item-m-width:  140px;
        --game-item-m-height:  210px;
    }
    @include breakpoint($bp2){
        --game-item-sm-height: 220px;
        --game-item-sm-width: 139px;
        --game-item-m-width:  160px;
        --game-item-m-height:  243px;
    }
    @include breakpoint($bp3){
        --game-item-sm-height: 236px;
        --game-item-sm-width: 157px;
        --game-item-m-width:  175px;
        --game-item-m-height:  264px;
    }
}

@mixin game-item-size($size) {
    width: var(--game-item-#{$size}-width);
    height: var(--game-item-#{$size}-height);
}

@mixin cabinet-bg{
    padding: 16px;
    margin-bottom: 4px;
    border-radius: 2px;
    background-image: url($cdn + '/rabona/elements/pattern-cabinet.png');
    background-size: cover;
    background-position: center top;
    background-blend-mode: screen, normal;
    mix-blend-mode: normal;
}