$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-collection {
    box-sizing: border-box;
    padding: 0 16px;
    margin-top: 8px;
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include breakpoint( $bp1 ) {
        margin-top: 20px;
    }
    @include breakpoint( $bp3 ) {
        padding: 0 52px;
    }

    body.rtl-lang & {
        direction: rtl;
    }
} 