$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.crypto-cashier-pay-item {
  background: #eff5fd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-height: 102px;
  margin: 8px 0;
  padding: 24px 12px;

  &.is-single {
    justify-content: center;
    margin-top: 20px;
  }

  &__img {
    width: 88px;
    margin-inline-end: 16px;

    .is-single & {
      width: 126px;
      margin-inline-end: inherit;
    }
    img {
      width: 100%;
    }
  }
  &__info {
    text-align: start;
  }
  &__title {
    margin-bottom: 4px;
    @include text(14px, 120%, 500, $blueDark);
  }
  &__text {
    @include text(12px, 140%, 400, $darkGrey);
  }
  &__info-link {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include text(14px, 140%, 400, #909bad);

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      margin-inline-start: 4px;
      flex-shrink: 0;
      background-image: var(--icon-arrow);
      background-position: center;
      background-size: contain;
      filter: $filterGrey;
    }
  }
}
