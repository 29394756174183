$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.info-wrap {
    color: white;
    font-size: 16px;
  
    h1 {
        @extend .title;
        margin: 24px 20px; 
    }

    main {
        padding: 20px 20px 0;
        border-top: 2px solid #273242; 
    }

    section {
        margin-bottom: 30px;
    }

    section:last-child {
        margin-bottom: 40px;
    }
  
    h2 {
      margin-bottom: 12px;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
      text-transform: uppercase;
    }
  
    p {
      line-height: 1.5;
      margin-bottom: 12px;
    }

    li {
        line-height: 1.5;
        margin-bottom: 8px;
    }

    li:last-child, p:last-child {
        margin-bottom: 0;
    }
  
    ul,
    ol {
      list-style-position: outside;
      margin-left: 18px;
    }
  
    ul {
      list-style-type: disc;
    }
  
    ol {
      list-style-type: decimal;
    }

    a {
        color: $yellow; 
    }

    &_padding {
        padding: 0 20px; 
    }
}
  
