$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.a2hs-footer {
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;      
    &__info {
        flex-grow: 1;
        min-height: 40px;
        padding-left: 52px;
        padding-right: 12px;
        background-image: url(/img/icon-192x192.png);
        background-size: 40px 40px;
        background-position: left top;
        background-repeat: no-repeat;
    }
    &__title {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: $white;
        margin: 3px 0 2px;
    }
    &__description {
        color: #A5AFC0;
    }
    &__btn {
        display: inline-block;
        font-weight: bold;
        font-size: 14px;
        line-height: 36px;
        text-transform: uppercase;
        text-align: center;
        padding: 0 8px;
        min-width: 61px;
        color: $black;
        background: $white;
        @include ellipsis;
        @include hover(){
            background: transparent;
            color: $white;
            text-decoration: none;
        }
    }      
}