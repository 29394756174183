$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-modal-form {

  .form-item:not(.form-checkbox) {
    margin-top: 22px;
  }
  & .form-checkbox:first-of-type {
    margin-top: 20px;
  }

  & .btn-promo {
    max-width: 392px;
    width: 100%;
    margin: 20px auto 12px;
  }
  & form {
    max-width: 392px;
    margin-left: auto;
    margin-right: auto;
  }


}