$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;

  body.rtl-lang & {
    direction: rtl;
  }

  &__box {
    width: 100%;
    max-width: 420px;
  }

  @include breakpoint(768px){
    padding: 0 16px;
    max-width: 912px;

    &__box{
      margin: 0 4px 8px;
      width: calc(50% - 8px);
    }
  }

  @include breakpoint(1024px){
    &__box{
      width: calc(50% - 20px);
      margin: 0 10px 20px;
    }
  }
}