$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.game-search-input {

  inset-inline-end: auto;
  inset-inline-start: 20px;
  padding-inline-start: 0 !important;

  .has-search-mode & {
    position: absolute;
    top: 16px;
    inset-inline-start: 52px;
    width: calc(100% - 72px);
  }

  input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    font-size: 14px;
    color: #A7AFBE;
    padding-inline-start: 40px;
    &::-webkit-input-placeholder {
      color: #A7AFBE;
    }
    &:focus {
      border: 1px solid #EFFB37;
    }
  }

  span {
    display: flex;
    height: 100%;
    align-items: center;
    white-space: nowrap;
    padding: 0 20px;
  }

}