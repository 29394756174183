$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cabinet-deposit-btn {
    color: $grey;
    text-align: center;
    overflow: hidden;
    background-color: $blue;
    border: 1px solid $lightBlue;
    padding: 14px 16px;
    border-radius: 2px;
    @include text(16px, 19px, 400,#A5AFC0 );

    @include hover() {
        background-color: $lightBlue; 
    }

    &.has-mb-4 {
        margin-bottom: 4px;
    }

    body.rtl-lang & {
        justify-content: flex-end;
    }
}