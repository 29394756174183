$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cashbox-contacts {

  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 4px;

  & > * {
    margin-block: 0 20px;
    margin-inline: 0 12px;
  }
  @include breakpoint( $bp1 ) {
    justify-content: center;
  }

}