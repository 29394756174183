$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-tooltip {
    &__ach-tooltip {
        position: absolute;
        width: 264px;
        top: 100%;
        right: 4px;
        padding-top: 14px;
        overflow: hidden;
    }
    &__ach-tooltip-wrap {
        display: flex;
        background-color: $red;
        position: relative;
        padding: 10px;
        &:before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: -8px;
            right: 14px;
            transform: rotate(45deg);
            background-color: $red;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background-image: url($cdn + "/rabona/elements/pattern-collections.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: calc(100% - 100px) calc(100% - -30px);
            width: 100%;
            height: 100%;
            z-index: 0;
            transform: scaleX(-1);
        }
    }
    &__ach-info {
        flex: 1 1 auto;
        padding: 10px 10px 10px 20px;
        z-index: 10;
    }
    &__ach-title {
        color: $yellow;
        font-size: 18px;
        text-align: center;
        .small-font & {
          font-size: 16px;
        }
    }
    &__ach-description {
        color: $white;
        font-size: 12px;
        margin-bottom: 20px;
        text-align: center;
        white-space: break-spaces;
        .small-font & {
          font-size: 11px;
        }
    }
    &__ach-btn {
        text-align: center;
        button {
            max-width: 120px;
            min-width: 120px;
            border: none;
            outline: none;
        }
    }
    &__ach-img {
        display: none;
        flex: 0 0 145px;
        z-index: 10;
        background-color: #1C2738;
        position: relative;
        img {
            position: absolute;
            top: -54px;
            transform: rotate(12.5deg) scale(0.9);
            left: -8px;
        }
    }

    @include m {
        &__ach-tooltip {
            width: 524px;
        }
        &__ach-tooltip-wrap {
            &:after {
                background-size: calc(100% - 100px);
                background-position: -142px calc(100% + 80px);
            }
        }
        &__ach-title {
            color: $yellow;
            font-size: 20px;
            text-align: left;
        }
        &__ach-description {
            color: $white;
            font-size: 14px;
            margin-bottom: 20px;
            text-align: left;
        }
        &__ach-btn {
            text-align: right;
        }
        &__ach-img {
            display: block;
        }
    }
}
