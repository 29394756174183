$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.home-live {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;

    .title {
        @include breakpoint($bp2) {
            margin: 0;
            width: fit-content;
        }
    }

    body.rtl-lang & {
        direction: rtl;
    }

    @include breakpoint($bp2) {
        flex-direction: row;
        gap: 12px;
        margin: 24px 20px 20px;
    }

    &__left {
        .title {
            display: none;
            @include breakpoint($bp2) {
                display: inline-block;
            }
        }

        @include breakpoint($bp2) {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
    &__right {
        @include breakpoint($bp2) {
            flex: 0 0 320px;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        @include breakpoint($bp3) {
            flex: 0 0 424px;
        }
    }
    &__streaming {
        margin: 0 20px;

        body.rtl-lang & {
            direction: ltr;
        }

        @include breakpoint($bp2) {
            margin: 0;
            background-color: #273242;
            border-radius: 2px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            &::after {
                content: '';
                display: block;
                flex-grow: 1;
                background-position: 2px bottom;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjEiIGZpbGw9IiNFRkZCMzciIGZpbGwtb3BhY2l0eT0iLjM1IiBzdHlsZT0iIi8+PC9zdmc+');
            }
        }
    }
    &__banner {
        @include breakpoint($bp2) {
            flex-grow: 1;
        }
    }


    body.rtl-lang & {
        direction: rtl;
    }
}