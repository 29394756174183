$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-chat-lang {
  min-height: 44px;
  min-width: 150px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  background: #273242;
  border: 1px solid #2a394e;
  border-radius: 2px;

  body.rtl-lang & {
    padding: 12px;
  }

  &__img {
    width: 23px;
    height: 16px;
    object-fit: contain;
    margin-inline-end: 8px;
  }

  &__text {
    @include text(16px, 19px, 400, $white);

    &.is-yellow {
      color: $yellow;
    }
  }

  &__chat-icon {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url($cdn + '/rabona/icons/live-chat.svg');
    background-position: center;
    background-repeat: no-repeat;
    margin-inline-end: 8px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(27%) saturate(5396%) hue-rotate(9deg) brightness(107%)
      contrast(105%);
  }
}
