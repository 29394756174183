$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.label {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: -3px;
        width: 7px;
        height: 15px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 12px);
        background-color: var(--label-bg);
        filter: brightness(.5);
    }

    &__band {

        @include text(14px, 16px, bold);
        text-transform: uppercase;
        font-style: italic;
        color: var(--label-text);
        
        display: flex;
        align-items: center;
        height: 30px;
        padding: 1px 14px 0 8px;

        background-color: var(--label-bg);

        transform-origin: left;
        transform: skewY(-23.3deg);
        box-shadow: 3px 3px 0px 0px rgba(12, 18, 33, 0.2);
    }

    &__text {
         transform: skewY(23.3deg) rotate(-23.3deg);
    }
}