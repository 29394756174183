$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.registration {
  &__progress {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &::before,
    &::after {
      content: '';
      display: block;
      width: calc( 50% - 2px);
      height: 4px;
      background: #2D394C;
      transform: skew(-20deg);
    }

    &::before{ background: $red;}
    &.is-step-2::after { background: $red; }
  }

  &__step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__item {
    width: 100% ;
    align-self: flex-start;

    .form-box {
      margin: 0;
    }
  }

  &__btn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__link {
    margin-top: 60px;
    width: calc(100% - 250px);
    margin-right: 8px;
  }

  &__btn2 {
    margin-top: 60px;
    width: 208px;
  }

  &__date{
    display: flex;
    flex-wrap: wrap;
    margin:0 -7px;
    min-height: 56px;
  }
  &__date-item{
    margin:0 7px;
    width: calc( 33.3334% - 14px);
  }
  &__date-error {
    width: 100%;
    color: $colorError;
    background: #0C1221;
    border-radius: 0 0 1px 1px;
    padding: 6px 20px;
    text-transform: initial;
    @include text(10px, 15px, 400);
    margin: 0 7px;
  }

  &__gender {
    display: flex;
    align-items: center;

    body.rtl-lang & {
      direction: rtl;
    }

    & .form-name {
      flex-grow: 1;
      flex-shrink: 0;
      margin-bottom:0;
    }

    &_pnp {
      flex-wrap: wrap;
      margin-top:0;

      & .form-name {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }

  &__gender-i {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 46px;

    &_pnp {
      height: 56px;
      padding-top:16px;
    }
  }

  @include breakpoint($bp440) {
    &__date-error {
      padding: 6px 20px;
    }
  }

  @include s{
    &__progress{ margin-top:13px; }
    &__btn{ margin-top: 72px; }
    &__btn{ width: 288px}
    &__link,
    &__btn2{ width: calc(50% - 24px) ; margin:60px 12px 0 ; text-align: center; }
    &__item.is-50{ width: calc(50% - 24px) ; }

    &__gender{
      flex-wrap: wrap;
      margin-top:0;
      & .form-name{
       width: 100%;
        margin-bottom:8px;
      }

    }
    &__gender-i{
      height: 56px;
      padding-top:16px;
    }

  }
  @include m{
    &__btn{ width: 304px}
  }
}

.registration__step.ng-invalid .btn {
  --mainColor: #A5AFC0;
  pointer-events: none;
}

.registration__step.ng-hide { opacity: 0; }


.registration__step.ng-hide-add{
  animation: registrationStepHide 0.6s linear ;
}
.registration__step.ng-hide-remove {
  animation: registrationStepShow 0.6s linear ;
}


@keyframes registrationStepShow {
  0%  {  opacity:0; max-height: auto;}
  100%{  opacity:1; max-height: auto;}

}
@keyframes registrationStepHide {
  0%{  opacity:0; max-height: 0;}
  100%{  opacity:0; max-height: 0;}
}

