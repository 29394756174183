$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-deposit-popup {
  .quick-registration-dropdown {
    &.open {
      left: 2px;

      .quick-registration-promotions {
        border-radius: 14px;

        &:after {
          content: '';
        }
      }
    }

    &.open + .auth-with-deposit-info {
      border-radius: 14px;
    }
  }

  .quick-registration-promotions {
    border-radius: 14px 14px 0 0;
    margin: 2px auto 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);

    &:after {
      content: none;
    }
  }

  .auth-with-deposit-info {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.35);
    background-color: #20425b;
    margin: 2px;
    padding: 6px 0 6px 12px;

    min-height: 46px;
    border-radius: 14px 14px 0px 0px;

    &__payment-icon {
      background: rgba(255, 255, 255, 0.75);
      border-radius: 8px;
      padding: 4px;
      margin-right: 12px;
      height: 40px;
    }

    &.is-login {
      border-radius: 0;
      margin: 0;
      min-height: 46px;
      background: #BE956A;
      padding: 9px 0 9px 12px;
    }

    &.is-dep {
      min-height: 46px;
      border-radius: 14px 14px 0 0;
      margin-top: 2px;
    }
  }

  &__wrap {
    .quick-registration-promo__img-wrap {
      display: none;
    }

    .chose-code__img {
      display: none;
    }
  }
}

.auth-deposit-popup__wrap + .auth-with-deposit-info {
  min-height: auto;
  border-radius: 0;
  margin: 0 2px;
}

.auth-with-deposit {
  position: relative;
  padding: 18px 20px;

  &__title {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    background: linear-gradient(180deg, #8F5738 0%, #6E3923 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #6E3923;
    opacity: 0.7;
  }

  &__close {
    background-image: linear-gradient(180deg, #D09A8D 0%, #A56B56 95.03%);
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 0;
    outline: 0;
    width: fit-content;

    position: absolute;
    right: 8px;
    top: 16px;

    &:after {
      content: '';
      white-space: nowrap;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 8px;
      border-radius: 40px;
      width: 36px;
      overflow: hidden;
      background-repeat: no-repeat;
      background: #8C5F51;
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.25);
    }
  }

  auth-with-deposit-paysystems {
    display: block;
    margin-top: 16px;
  }
}
