$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.quick-registration {
  position: relative;
  z-index: 1;
  // width: 290px;
  border-radius: 4px;
  border: 1px solid #273242;
  background-color: #162334;
  box-shadow: 0px 2px 8px 0px #0B1222;
  padding: 12px;

  @include s {
    width: 320px;
  }

  &__plug {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    grid-column: 1 / 11;
    min-height: 62px;
    padding: 6px 6px 14px;

    &__plug-img {
      width: 42px;
      height: 42px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 6px;
      body.rtl-lang & {
        align-items: flex-end;
      }
    }

    &-title {
      color: #A5AFC0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.28px;
    }

    &-text {
      color: #A5AFC0;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.12;
    }

    body.rtl-lang & {
      flex-direction: row-reverse;
    }
  }

  &__currency-wrap {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    column-gap: 6px;
    grid-column: 1 / 11;

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__input {
    grid-column: 1 / 7;
    text-align: center;
    font-size: 22px;
    line-height: 1;
    font-weight: 700;
    padding: 10px;
    height: 46px;
    background-image: none;

    &.ng-valid.ng-dirty:not(.not-status),
    &:not(.not-status).ng-invalid.ng-touched:not(:focus) {
      background-image: none;
    }

    &::placeholder {
      font-size: 18px;
    }
    body.rtl-lang & {
      text-align: center;
      padding: 10px;
    }
  }

  &__select {
    height: 46px;
    padding-right: 35px !important;
    grid-column: 7 / 11;
    color: #A5AFC0;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    border-radius: 2px;
    border: 1px solid #394557;
    background-color: #2F3A4A;
    background-position: right 15px center;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuODAxNzcgNi41TDkuOTI5MDcgMTEuNTU1NkwxNS4xMzAyIDYuNUwxNi4yMjIzIDcuNDUwMkw5LjkyOTA2IDEzLjVMMy43Nzc4MyA3LjQ1MDJMNC44MDE3NyA2LjVaIiBmaWxsPSIjQTVBRkMwIi8+Cjwvc3ZnPgo=);
    background-size: 22px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:disabled {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC+SURBVHgB7Y89CsJAEIVnsv6VewADCmIT7NMZQcHSMmWO4lHsTGk6CyHapbNRsFBQyAFSysoyuoWyxFXERpB8sMXsvPfBABT8Ds8bcfgCpg/uwA8kY9N6uxOl+01mKrhDv2E3nbXdck7pYbu7/1t6qHY5zxAoQ0mxKpgkaqcyVSGW+g7zYXWaKFdiAuTEsJfMw6MuIcJVsgiDfO9JZJJJafESSTVHJslL0eOUvj9BpC4Q8HeSj1Cy2xtDwR9yBTa8Sc1tethcAAAAAElFTkSuQmCC');
      border-color: #273242;
      color: #394557;
      background-color: #273242;
      opacity: 1;
    }

    body.rtl-lang & {
      padding-left: 35px !important;
      background-position: 16px center;
    }
  }

  &__crypto {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 20px;
  }

  &__bonus {
    grid-column: 1/11;
  }

  &__submit {
    grid-column: 1 / 11;
    padding: 18px 32px;
    line-height: 1;
    font-size: 20px;
    border: none;
    display: flex;
    justify-content: center;

    .is-text {
      margin-right: 8px;

      body.rtl-lang &,
      [lang="in"] &,
      [lang="hi-IN"] & {
        order: 1;
        margin-left: 8px;
        margin-right: 0;
      }
    }

    &.is-kk {
      .is-text {
        order: 1;
        margin-right: 0;
      }
    }

    &.is-pln {
      .is-text {
        order: 1;
      }

      .is-amount {
        order: 2;
      }

      .is-currency {
        order: 3;
      }
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 6px;

    &.ng-invalid {
      .quick-registration__submit {
        background-color: #a5afc0;
        pointer-events: none;
      }
    }
  }
}