$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.timer {
  text-align: center;

  &__title-row {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    @include text(12px, 12px, normal, $grey);
    text-transform: uppercase;
    padding-bottom: 6px;
    width: 68px;

    &.is-fs-8 {
      font-size: 8px;
      line-height: 8px;
    }
    &.is-white {
      color: $white;
    }
    &.is-width-33 {
      width: 33px;
    }
    &.is-padding {
      padding-top: 2px;
      padding-bottom: 0;
    }
  }
  &__counter {
    @include text(42px, 49px, bold);
    text-transform: uppercase;
    display: flex;
    align-items: center;

    &.is-fs-20 {
      font-size: 20px;
      line-height: 23px;
    }

  }
  &__colon {
    width: 4px;
    height: 16px;
    margin: 0 10px;
    background-size: 100%;
    background-repeat: no-repeat;

    &.is-small {
      width: 2px;
      height: 8px;
      margin: 0 5px;
    }
  }
  &__number {
    width: 68px;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    position: relative;


    &:after {
      content: "";
      position: absolute;
      height: 1px;
      background: $black;
      width: 68px;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    &.is-yellow {
      color: $yellow;
    }

    &.is-width-33 {
      width: 33px;
      height: 39px;

      &:after {
        width: 33px;
      }
    }
  }

}