$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.sidemenu {
    --category-gradient: linear-gradient(114deg, #{$sidemenuDarkTransparent}, #{$sidemenuDarkTransparent} 20px, #{$sidemenuDark} 20px, #{$sidemenuDark} 24px, #{$sidemenuDarkTransparent} 24px, #{$sidemenuDarkTransparent} 28px, #{$sidemenuDark} 28px, #{$sidemenuDark} 32px, #{$sidemenuDarkTransparent} 32px),
        linear-gradient(90deg, #2D394C 0%, #1E2737 100%);
    --category-gradient-crab: linear-gradient(114deg, #{$sidemenuBlueRgbTransparent}, #{$sidemenuBlueRgbTransparent} 20px, #{$sidemenuBlueRgb} 20px, #{$sidemenuBlueRgb} 24px, #{$sidemenuBlueRgbTransparent} 24px, #{$sidemenuBlueRgbTransparent} 28px, #{$sidemenuBlueRgb} 28px, #{$sidemenuBlueRgb} 32px, #{$sidemenuBlueRgbTransparent} 32px),
        linear-gradient(189.59deg, #048FF8 7.2%, #005697 92.75%),
        linear-gradient(90.05deg, #BC1F39 0.04%, #AC1D34 99.95%);
    --category-gradient-tap: linear-gradient(114deg, #{$sidemenuDarkTransparent}, #{$sidemenuDarkTransparent} 20px, #{$sidemenuDark} 20px, #{$sidemenuDark} 24px, #{$sidemenuDarkTransparent} 24px, #{$sidemenuDarkTransparent} 28px, #{$sidemenuDark} 28px, #{$sidemenuDark} 32px, #{$sidemenuDarkTransparent} 32px),
        linear-gradient(90deg, #28354A 0%, #161E2D 100%);
    --arrow-filter: invert(77%) sepia(9%) saturate(376%) hue-rotate(181deg) brightness(90%) contrast(89%);

    --category-easter-active: linear-gradient(114deg, #{$sidemenuRedRgbTransparent},  #{$sidemenuRedRgbTransparent} 20px, #{$sidemenuRedRgb} 20px, #{$sidemenuRedRgb} 24px,  #{$sidemenuRedRgbTransparent} 24px,  #{$sidemenuRedRgbTransparent} 28px, #{$sidemenuRedRgb} 28px,  #{$sidemenuRedRgb} 32px,  #{$sidemenuRedRgbTransparent} 32px),
    linear-gradient(90deg, #CC0034 0%, #AC1D34 100%);

    --height-sidemenu-toolbar: 48px;
    --border-height-sidemenu-toolbar: 20px;

    --category-periodicals-gradient: linear-gradient(114deg, #{$sidemenuDarkTransparent}, #{$sidemenuDarkTransparent} 20px, #{$sidemenuDark} 20px, #{$sidemenuDark} 24px, #{$sidemenuDarkTransparent} 24px, #{$sidemenuDarkTransparent} 28px, #{$sidemenuDark} 28px, #{$sidemenuDark} 32px, #{$sidemenuDarkTransparent} 32px),
     linear-gradient(270deg, #FF4D00 0%, rgba(45, 57, 76, 0.00) 100%), linear-gradient(90deg, #2D394C 0%, #1E2737 100%);

    width: 270px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform .3s;
    transform: translateX(-270px);
    display: flex;
    flex-direction: column;
    z-index: 9801;
    overflow-y: auto;
    background-color: #192333;

    body.rtl-lang & {
        left: auto;
        right: 0;
        direction: rtl;
        transform: translateX(270px);
    }

    .has-open-left-sidebar &:not(.is-right) {
        transform: translateX(0);
    }
    .has-open-right-sidebar &.is-right {
        transform: translateX(0);
    }

    &.is-sport-tab {
        --category-gradient-active: linear-gradient(114deg, #{$yellowRgbTransparent}, #{$yellowRgbTransparent} 20px, #{$yellowRgb} 20px, #{$yellowRgb} 24px, #{$yellowRgbTransparent} 24px, #{$yellowRgbTransparent} 28px, #{$yellowRgb} 28px, #{$yellowRgb} 32px, #{$yellowRgbTransparent} 32px),
        linear-gradient(90deg, #F4FF4D 0%, #E9F716 100%);
        --arrow-filter-active: invert(17%) sepia(35%) saturate(653%) hue-rotate(176deg) brightness(91%) contrast(83%);
        --filter-category: invert(18%) sepia(5%) saturate(4180%) hue-rotate(179deg) brightness(97%) contrast(87%);
    }

    &.is-casino-tab {
        --category-gradient-active: linear-gradient(114deg, #{$sidemenuRedRgbTransparent},  #{$sidemenuRedRgbTransparent} 20px, #{$sidemenuRedRgb} 20px, #{$sidemenuRedRgb} 24px,  #{$sidemenuRedRgbTransparent} 24px,  #{$sidemenuRedRgbTransparent} 28px, #{$sidemenuRedRgb} 28px,  #{$sidemenuRedRgb} 32px,  #{$sidemenuRedRgbTransparent} 32px),
        linear-gradient(90deg, #CC0034 0%, #AC1D34 100%);
        --arrow-filter-active: invert(100%);
        --filter-category: invert(100%);
    }

    &.is-right {
        left: auto;
        right: 0;
        transform: translateX(270px);

        body.rtl-lang & {
            right: auto;
            left: 0;
            transform: translateX(-270px);
        }

        .has-open-right-sidebar body.rtl-lang & {
            transform: translateX(0);
        }
    }

    &__fade {
        width: 100vw;
        height: 100%;
        background-color: rgba(12, 18, 33, 0.8);
        position: fixed;
        top: 0;
        opacity: 0;
        display: block;
        z-index: -100;
        overscroll-behavior-x: none;
        transition: opacity .3s ease, z-index .3s step-end;

        .has-open-left-sidebar &.is-left, .has-open-right-sidebar &.is-right {
            opacity: 1;
            z-index: 9800;
            transition: opacity .3s ease, z-index .3s step-start;
        }
    }

    &__close {
        position: fixed;
        display: none;
        width: 48px;
        height: 48px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #131A2A;
        z-index: 9999;

        body.rtl-lang & {
            direction: rtl;
        }

        .has-open-left-sidebar &.is-left {
            display: block;
            top: 0;
            inset-inline-start: 270px;
        }

        .has-open-right-sidebar &.is-right {
            display: block;
            top: 0;
            inset-inline-end: 270px;
        }
    }

    &__link {
        display: block;
        padding-block: 5px;
        padding-inline: 20px 45px;
    }

    &__title {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
    }

    &__btm {
        width: 100%;
        margin-top: auto;
        padding-bottom: var(--safe-area-inset-bottom);
    }
}
