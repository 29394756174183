$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-progress {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  border: 3px solid $red;
  margin-bottom: 30px;
  text-align: center;

  &__text {
    @include text(18px, 140%, 700, $white);
    text-transform: uppercase;

    span.is-big {
      font-size: 28px;
      line-height: 40px;
      color: $yellow;
      margin: 0 6px;
    }

    .is-small {
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      text-transform: initial;
    }

    span.is-points-icon {
      position: relative;
      top: 1px;
      padding-left: 32px;
      white-space: nowrap;
      &::before {
        content: '';
        display: block;
        background-image: var(--icon-points-yellow);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 28px;
        height: 28px;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }
}