$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-item {
  width: 100%;
  margin-bottom: 24px;

  &__img-block {
    display: flex;
    overflow: hidden;
  }
  &__title {
    @include text(16px, 19px, bold);
    text-transform: uppercase;

    body.rtl-lang & {
      direction: rtl;
      * {
        display: inline-block;
      }
    }


    &.small-font {
      font-size: 14px;
      @include breakpoint($bp2) {
        font-size: 16px;
      }
    }
  }

  &__btn {
    max-width: 100%;
    &.is-underlined{
      text-align: center;
      min-width: auto;
      max-width: 100%;
      letter-spacing: .01em;
      border-color: transparent;
      margin-bottom: auto;
      padding: 0;
      line-height: 36px;
      text-decoration-line: underline;
      margin-left: 24px;
    }
  }
  &.is-summer-promotion {
    background-color: $red;
  }
  &.is-summer-promotion {
    background-color: $red;
  }
  &:not(:nth-child(4n + 3)),
  &:not(:nth-child(4n + 4)) {
    .promo-item__inner-wrapper {
      background-color: #192333;
    }

  }
  &:nth-child(4n + 3),
  &:nth-child(4n + 4) {
    .promo-item__inner-wrapper {
      background-color: $red;
    }
  }

  //*** все элементы, кроме первого ***//
  &:not(.is-first-promo) {
    margin-bottom: 16px;
    .promo-item__inner-wrapper {
      height: 100%;
      min-height: 122px;
      display: flex;
      justify-content: space-between;
    }

    & .promo-item__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      flex-shrink: 0;
      padding: 12px 16px 16px 12px;
      align-items: flex-start;

      body.rtl-lang & {
        direction: rtl;
      }
    }

    & .promo-item__title {
      overflow: hidden;
      text-overflow: ellipsis;

      max-width: calc(100% - 28px);
    }

    & .promo-item__btn {
      min-width: 120px;
      max-width: 164px;
      margin-top: 16px;
      &.is-underlined{
        min-width: auto;
        max-width: 100%;
      }
    }

    & .promo-item__img-block {
      width: 50%;
    }

    & .promo-item__img {
      flex-shrink: 0;
      height: 100%;
      width: 100%;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left center;
      }
    }

    &:nth-of-type(2n) {
      & .promo-item__img {
        & img {
          object-position: right center;
        }
      }
    }
    &:nth-of-type(2n + 1) {
      .promo-item__inner-wrapper {
        flex-direction: row-reverse;
      }

    }

    @include breakpoint($bp1) {
      margin-right: 40px;
      margin-left: 40px;
      max-width: calc(100% - 80px);

      .promo-item__inner-wrapper {
        min-height: 187px;
      }
      & .promo-item__title {
        @include text(24px, 28px, bold);
      }
    }

    @include breakpoint($bp2) {
      margin: 10px;
      width: calc(50% - 20px);

      .promo-item__inner-wrapper {
        min-height: 122px;
      }
      & .promo-item__title {
        @include text(16px, 19px, bold);
      }
    }

    @include breakpoint($bp3) {
      .promo-item__inner-wrapper {
        min-height: 160px;
      }
      & .promo-item__title {
        @include text(20px, 24px, bold);
      }
    }
  }

  //***** 1-й элемент *****//

  &.is-first-promo {
    .promo-item__inner-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    & .promo-item__img-block {
      padding-top: 36.875%;
      width: 100%;
      position: relative;
    }
    & .promo-item__img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      object-fit: cover;
      & img {
        width: 100%;
      }
    }
    & .promo-item__content {
      background-repeat: repeat;
      background-position: left top;
      display: block;
      width: 100%;
      padding: 20px;

      body.rtl-lang & {
        direction: rtl;
      }
    }
    & .promo-item__title {
      @include text(24px, 28px, bold, $white);
      margin-bottom: 18px;
    }
    & .promo-item__btn {
      color: $btnBlack;
      background-color: $yellow;
      border: none;
      line-height: 40px;

      @include hover() {
        background: #7cf74c;
        text-decoration: none;
      }
    }

    @include breakpoint($bp2) {
      margin: 0 -10px 14px;
      width: 100vw;

      & .promo-item__title {
        @include text(32px, 37px);
        &.small-font {
          font-size: 23px;
        }
      }
    }
  }

  //*** FOR SPORT ***//

  &_sport {
    &:first-child {
      & .promo-item__content {
        background-color: $red;
      }
    }
  }

  //*** FOR CASINO ***//

  &_casino {
    &:first-child {
      & .promo-item__content {
        background-color: #192333;
      }
    }
  }

  &.is-big-celebrity {
    & .promo-item__content {
      background-color: #192333;
    }

    &_casino {
      & .promo-item__content {
        background-color: $red;
      }
    }
  }

  &__new{
    position: absolute;
    min-width: 70px;
    max-width: fit-content;
    padding: 0 10px;
    height: 30px;
    background-color: #BC1F39;
    top: 0;
    right: 0;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;

    body.rtl-lang & {
      text-align: center;
    }

    .is-big-celebrity_casino & {
      right: auto;
      left: 0;
    }
  }
  &__subtitle{
    color: #ECFD00;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  &.is-easter{
    .promo-item__inner-wrapper {
      background-color: $red;
      flex-direction: row !important;
    }
  }
}
