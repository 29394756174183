$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.threeds {
  margin: 20px;
  border-radius: 5px;
  overflow: hidden;

  @include breakpoint($bp2) {
    margin: 40px auto;
    max-width: 600px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background-color: #2a394e;
  }

  &__title {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #ecfd00;

    &::before {
      content: '';
      margin-right: 12px;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.28 5v2.55h-1.1C1.39 7.55.74 8.2.74 9v7.27c0 .8.65 1.45 1.46 1.45H13.8c.8 0 1.46-.65 1.46-1.45V9c0-.8-.65-1.45-1.46-1.45h-1.09V5a4.72 4.72 0 00-9.44 0zm1.81 2.55V5a2.9 2.9 0 115.82 0v2.55H5.09zm1.46 4a1.45 1.45 0 112.24 1.21l.3 1.87c0 .3-.24.55-.54.55h-1.1a.54.54 0 01-.54-.55l.3-1.87c-.4-.25-.66-.7-.66-1.22z' fill='%23ECFD00'/%3E%3C/svg%3E");
      background-size: 15px auto;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__close {
    flex-shrink: 0;
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px auto;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M9.63 8l6.08-6.07a1.12 1.12 0 00-.06-1.57A1.12 1.12 0 0014.07.3L8 6.32 1.93.3A1.13 1.13 0 00.35.36a1.12 1.12 0 00-.06 1.57L6.33 8 .29 14.07c-.41.46-.38 1.16.07 1.58.41.45 1.12.47 1.57.05L8 9.64l6.07 6.06a1.17 1.17 0 001.64-1.63L9.63 8z' fill='%23FAFBFC'/%3E%3C/svg%3E");
    outline: none;
    cursor: pointer;
  }

  &__body {
    background-color: white;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &__iframe {
    display: block;
    width: 800px;
    height: 600px;
    border: none;
    max-width: 100%;
  }
}
