$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
// used variables from _sidemenu.scss
.sidemenu-categories {
    display: flex;
    flex-direction: column;

    .sidemenu:not(.is-casino-tab) &.is-casino-categories {
        display: none;
    }

    .is-casino-tab &.is-sport-categories {
        display: none;
    }

    &_info {
        &>*:first-child {
            margin-top: 9px;
        }
    }

    &__category {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        padding-inline: 20px 34px;
        @include text(16px, 48px, 700);
        max-width: 100%;
        @include ellipsis;
        text-transform: uppercase;
        border-bottom: 1px solid #344155;
        margin-bottom: 4px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            inset: 0;
            background-image: var(--category-gradient);
            z-index: -1;

            body.rtl-lang & {
                scale: -1 1;
            }
        }

        @include hover() {
            &::before {
                background-image: var(--category-gradient-tap);
            }
        }

        &.cricket {
            position: relative;

            &::before {
                content: '';
                width: 22px;
                height: 22px;
                background-image: url('#{$cdn}/rabona/mobile/cricket-menu.svg');
                background-size: cover;
                background-position: center;
                transform: translateY(5px);
                display: inline-flex;
                margin: 0 8px 0 7px;
            }

            &.active::before {
                filter: invert(100%) sepia(4%) saturate(5707%) hue-rotate(179deg) brightness(104%) contrast(83%);
            }
        }

        &_collapse {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                right: 20px;
                background-size: 100%;
                transition: .3s transform;
                filter: var(--arrow-filter);
            }

            &.active:after {
                transform: translateY(-50%);
            }

            body.rtl-lang & {
                &:after {
                    right: auto;
                    left: 20px;
                }
            }
        }

        &_promo {
            &::before {
                content: "";
                position: absolute;
                width: 97px;
                height: 100%;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                background-size: 100%;
                background-position: center;
            }

            @at-root .is-sport-categories &.active::before {
                filter: $filterGrey;
            }
        }

        &.has-pin::after {
            margin-bottom: 2px;
        }

        &.active,
        &.is-periodicals.active {
            background-image: var(--category-gradient-active);

            @include hover() {
                background-image: var(--category-gradient-active);
            }

            &:after {
                filter: var(--arrow-filter-active);
            }
        }

        &_easter {
            background-image: var(--category-gradient-crab);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 27px 0 23px;
            color: #ffffff;
            body.rtl-lang & {
                flex-direction: row-reverse;
            }
            &::before{
                content: "";
                position: absolute;
                width: 97px;
                height: 100%;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                background-size: 100%;
                background-position: center;
                background-image: var(--icon-promo-fone);
            }
            &.active {
                background-image: var(--category-easter-active);
                --arrow-filter-active: invert(100%);
                --filter-category: invert(100%);
                color: #fff !important;
                @include hover() {
                    background-image: var(--category-easter-active);
                    --arrow-filter-active: invert(100%);
                    --filter-category: invert(100%);
                }
            }
        }

        &.tyson-promo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: linear-gradient(114deg, #242e4000, #242e4000 20px, #242e40 20px, #242e40 24px, #242e4000 24px, #242e4000 28px, #242e40 28px, #242e40 32px, #242e4000 32px), linear-gradient(270deg, #D20023 0%, rgba(210, 0, 35, 0.00) 100%), linear-gradient(90deg, #2D394C 0%, #1E2737 100%);
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
            padding: 0 27px 0 23px;
            padding-bottom: 1px;
            border-bottom: none;

            body.rtl-lang & {
                flex-direction: row-reverse;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(90deg, transparent 0%, rgba(255, 77, 0, 1) 100%);
                filter: none;
            }

            .sidemenu-categories__link-label{

            }

            // div {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     min-width: 45px;
            //     max-width: fit-content;
            //     height: 20px;
            //     padding: 3px 10px;
            //     text-align: center;
            //     font-size: 12px;
            //     font-weight: 700;
            //     text-transform: uppercase;
            //     position: relative;
            //     margin-left: 10px;
            //     background-color: transparent;
            //     z-index: 1;

            //     &::before {
            //         content: '';
            //         position: absolute;
            //         inset: 0;
            //         background-color: #BC1F39;
            //         transform: skew(-27deg);
            //         box-shadow: 2px 3px 0 rgba(42, 51, 65, 0.25);
            //         z-index: -1;
            //     }
            // }

            &.active {
                color: #ffffff;

                @include hover() {
                    background: linear-gradient(114deg, #242e4000, #242e4000 20px, #242e40 20px, #242e40 24px, #242e4000 24px, #242e4000 28px, #242e40 28px, #242e40 32px, #242e4000 32px), linear-gradient(270deg, #D20023 0%, rgba(210, 0, 35, 0.00) 100%), linear-gradient(90deg, #2D394C 0%, #1E2737 100%);
                }
            }

            &:not(.active) {
                @include hover() {
                    background: linear-gradient(114deg, #242e4000, #242e4000 20px, #242e40 20px, #242e40 24px, #242e4000 24px, #242e4000 28px, #242e40 28px, #242e40 32px, #242e4000 32px);
                }
            }
        }

        &.australian-tournament {
            display: flex;
            align-items: center;
            background: linear-gradient(114deg, #242e4000 20px, #242e40 20px, #242e40 24px, #242e4000 24px, #242e4000 28px, #242e40 28px, #242e40 32px, #242e4000 32px), linear-gradient(270deg, #0091D2 0%, rgba(0, 145, 210, 0.00) 100%), linear-gradient(90deg, #2D394C 0%, #1E2737 100%);

            &::after {
                content: '';
                display: block;
                position: absolute;
                height: 1px;
                width: 100%;
                background: linear-gradient(90deg, #28354A 0%, #0091D2 100%);
                bottom: 0;
                left: 0;
                filter: none;
            }

            .sidemenu-nav__item-icon {
                width: 26px;
                height: 26px;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 8px;
            }

            &.active {
                @include hover() {
                    background: linear-gradient(114deg, #242e4000 20px, #242e40 20px, #242e40 24px, #242e4000 24px, #242e4000 28px, #242e40 28px, #242e40 32px, #242e4000 32px), linear-gradient(270deg, #0091D2 0%, rgba(0, 145, 210, 0.00) 100%), linear-gradient(90deg, #2D394C 0%, #1E2737 100%);
                }
            }

            &:not(.active) {
                @include hover() {
                    background-image: var(--category-gradient);
                }
            }
        }

        &.is-santa {
            position: relative;
            padding-inline: 54px 32px;
            height: 48px;
            background-size: 100%;
            background-repeat: no-repeat;

            body.rtl-lang & {
                direction: rtl;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
                inset-inline-end: 14px;
                width: 16px;
                height: 16px;
                background-image: var(--icon-up);
                filter: invert(1);

                body.rtl-lang & {
                    scale: -1 1;
                }
            }

            span {
                white-space: normal;
            }

            .sidemenu-promo {
                &__img {
                    position: absolute;
                    top: 0;
                    inset-inline-start: 0;

                    body.rtl-lang & {
                        scale: -1 1;
                    }
                }

                &__text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    line-height: 1;
                    height: 100%;
                }

                &__title {
                    font-size: 14px;
                    color: #ECFD00;
                }
            }
        }

        .sidemenu:not(.is-casino-tab) &.active {
            color: $lightBlue;

            &.australian-tournament,
            &.tyson-promo {
                color: #fff;
            }
        }
    }

    body.rtl-lang &__category {
        text-align: right;
    }

    &__category,
    &__item {
        span {
            max-width: calc(100% - var(--pin-space-width));
            @include ellipsis;
        }

        &.has-pin {
            display: flex;
            align-items: center;
            --pin-space-width: 26px;

            &::after {
                content: '';
                display: inline-block;
                margin-inline-start: 10px;
                margin-inline-end: 6px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                box-shadow: 0px 3px 4px rgba(188, 31, 57, 0.8), 0px 2px 0px #111928;
                border: 2px solid $red;

                body.rtl-lang & {
                    margin-inline-start: 6px;
                    margin-inline-end: 10px;
                }
            }

            &.active::after {
                filter: none;
            }
        }

    }

    &__category,
    &__item {
        span {
            max-width: calc(100% - var(--pin-space-width));
            @include ellipsis;
        }

        &.has-pin {
            display: flex;
            align-items: center;
            --pin-space-width: 26px;

            &::after {
                content: '';
                display: inline-block;
                margin-inline-start: 10px;
                margin-inline-end: 6px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                box-shadow: 0px 3px 4px rgba(188, 31, 57, 0.8), 0px 2px 0px #111928;
                border: 2px solid $red;
            }

            &.active::after {
                filter: none;
            }
        }

    }

    &__list {
        display: none;
        flex-direction: column;
        overflow: hidden;
        max-height: 0;
        transition: max-height .3s;
        background-color: $darkBlue;

        .active+& {
            display: flex;
            max-height: max-content;
        }
    }

    &__item {
        display: block;
        @include text(16px, 20px, 400, $grey );
        @include ellipsis;
        max-width: 100%;
        padding: 14px 20px;
        background-size: 0;
        text-decoration: none;
        margin-bottom: 4px;

        @include hover() {
            color: $yellow;
            text-decoration: none;
            background: linear-gradient(90deg, #28354A 0%, #161E2D 100%);
        }

        &.active {
            color: $yellow;
            background: linear-gradient(90deg, #2D394C 0%, #1E2737 100%);
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        }

        &_icon {
            display: flex;
            align-items: center;

            i {
                width: 24px;
                height: 24px;
                flex-shrink: 0;
                margin-inline-end: 13px;
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 48px 24px;
            }

            &.active {
                i {
                    background-position: right top;
                }
            }

            @include hover() {
                i {
                    background-position: right top;
                }
            }

            .is-casino-categories & {
                i {
                    background-position: center;
                }
                
                &.active {
                    i {
                        background-position: center;
                        filter: brightness(0) saturate(100%) invert(88%) sepia(99%) saturate(741%) hue-rotate(9deg) brightness(105%) contrast(106%);
                    }
                }

                @include hover() {
                    i {
                        background-position: center;
                    }
                }
            }
        }

        &.is-exit {
            display: inline-flex;
            overflow: hidden;
            border-top: 1px solid $red;
            margin: 0 12px;
            min-height: 50px;

            &::before {
                content: "";
                flex-shrink: 0;
                width: 17px;
                height: 100%;
                margin-inline-end: 4px;
                filter: $filterWhite;
                background-size: 18px;
                background-repeat: no-repeat;
            }

            @include hover {
                &::before {
                    background-size: 18px;
                    background-repeat: no-repeat;
                    filter: $filterYellow
                }
            }
        }


        @include hover() {
            color: $yellow;

            &::before {
                background-position: right top;
            }

        }
    }

    &__item-text {
        flex-grow: 1;
        @include ellipsis;
        margin-inline-end: 18px;
    }

    &__item-amount {
        flex-shrink: 0;
    }

    &__link-label {
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        padding: 2px 10px;
        position: relative;
        min-width: 40px;
        margin-inline-start: auto;
        flex-shrink: 0;
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        text-shadow: none;
        background-color: #BC1F39;
        transform: skewX(-25deg);
        filter: drop-shadow(4px 4px 0px rgba(42, 51, 65, 0.25));

        body.rtl-lang & {
            text-align: center;
            margin-inline-end: auto;
            margin-inline-start: 0;
        }

        .sidemenu-categories__category.active:not(.tyson-promo) & {
            background-color: #EDFA2A;
            color: #000000;
        }

        span {
            transform: skewX(20deg);
        }
    }

    &__link-icon {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 22px;
        height: 22px;
        margin-inline-end: 7px;
        position: relative;

        body.rtl-lang & {
            margin-inline-start: 7px;
            margin-inline-end: 0;
        }
    }
}
