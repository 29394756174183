$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.home-promo {
    width: 100%;
    padding-top: 31px;
    padding-inline-end: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    min-height: 157px;

    @include breakpoint( $bp1 ) {
        min-height: 160px;

        &.small-font {
            min-height: 170px;
        }
    }

    @include breakpoint( $bp2 ) {
        width: 237px;
        min-height: 352px;
        align-items: flex-end;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-inline-end: 0;
    }

    &__background {
        position: absolute;
        inset: 0;
        background-size: 185px auto, cover;
        background-position: top left, top center;
        background-repeat: no-repeat;
        background-color: $blue;
        z-index: -1;

        @include s {
            background-size: 220px auto, cover;
        }

        @include m {
            background-position: center 25px, top center;
            z-index: auto;
        }

        body.rtl-lang & {
            scale: -1 1;
        }
    }

    &__category-wrap {
        filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.25));
        position: absolute;
        top: 0;
        inset-inline-end: 11px;

        @include breakpoint( $bp1 ) {
            inset-inline-end: 20px;
        }

        @include breakpoint( $bp2 ) {
            inset-inline-end: 0;
            inset-inline-start: 0;
        }  
    }

    &__category {
        display: block;
        padding: 5px 16px;
        width: 160px;
        height: 24px;
        @include text(12px, 14px, 700, $btnBlack);
        text-align: center;
        text-transform: uppercase;
        background: $yellow;  
        clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);  
        
        @include ellipsis;
        @include breakpoint( $bp2 ) {   
           margin: 0 auto;
        }  
               
    }

    &__content {    
        width: 200px;            
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;

        @include breakpoint( $bp1 ) {
            width: 260px;
        }

        @include breakpoint( $bp2 ) {
            width: 100%;  
            height: 200px;  
            padding: 46px 20px 0; 
            justify-content: space-between;
            align-items: center;
            background: $red;  
            clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
        }           
    }

    &__text {
        margin: auto 0 4px;
        @include text(20px, 23px, 700);
        text-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
        text-align: end;
        text-transform: uppercase;
        white-space: break-spaces;

        > span {
            display: inline-block;
        }

        .small-font & {
          font-size: 17px;
        }

        @include breakpoint($bp1){
            margin: auto 0;
        }

        @include breakpoint( $bp2 ) {
             text-shadow: unset;
            max-height: 63px;
            @include text(18px, 21px);
            text-align: center;

            .small-font & {
              font-size: 15px;
            }
        }
    }

    &__btn {
        text-align: center;
        margin: 0;
        min-width: 160px;
        max-width: 100%;
        line-height: 36px;
        letter-spacing: 0.01em;
        text-decoration: none;
        border: 2px solid $white;
        padding: 0 20px;

        &--underlined {
            text-align: center;
            margin-bottom: auto;
            min-width: auto;
            max-width: 100%;
            height: 36px;
            line-height: 36px;
            letter-spacing: 0.01em;
            text-decoration-line: underline;  
            border: none;
            padding: 0;
        }

        &.btn-stroke {
             @include hover() {
                    color: #CBD0D8;
                    background: none;
                    text-decoration: underline;
               }
        }
        
        @include breakpoint( $bp1 ) {
            &.btn-stroke {
                @include hover() {
                    background: #FFFFFF;
                    color: #080F21;
                    text-decoration: none;
                }
            }
        }

        @include breakpoint( $bp2 ) {
            margin: 4px 0 0;
        }
    }

    body.rtl-lang &__btn {
        text-align: center;
    }

    body.rtl-lang &__category {
        text-align: center;
    }
}