$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}