$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-paralax {
  width: 100%;
  position: relative;
  min-height: 387px;
  height: 387px;
  overflow: hidden;

  &__wrap {
    position: absolute;
    top: 0;
    width: 1024px;
    left: calc(50% - 512px);
    height: 387px;
    text-align: center;
    z-index: 10;
  }
  &__light {
    background-image: url($cdn + '/rabona/cup-page/m_bg-light.svg');
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center top -24px;
    background-size: 692px 127px;
  }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url($cdn + '/rabona/cup-page/m_bg-img.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__ark {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url($cdn + '/rabona/cup-page/m_bg-arka_l.png'), url($cdn + '/rabona/cup-page/m_bg-arka_r.png');
    background-repeat: no-repeat;
    background-position: left top, right top;
    background-size: 348px 379px;
  }

}