$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item {
  padding-top: 151.136%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  transition: .2s all;
  position: relative;

  body.rtl-lang & {
    direction: rtl;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
    width: 100%;
    @include text(14px, 140px, 500, #fff );
    @include ellipsis;
    word-break: break-all;
  }

  @include hover(){
    opacity: .9;
  }

  &__label-wrap {
    position: absolute;
    display: block;
    top: 3px;
    right: 5px;
  }

  &__label {
    width: 48px;
    height: 14px;
    padding: 0 20px;
    display: none; 
    justify-content: center;
    align-items: center;
    clip-path: polygon(5px 0, 100% 0, calc(100% - 5px) 100%, 0 100%);
    @include text(8px, 12px, 700, #FFFFFF);
    text-transform: uppercase;

    &_new {
      display: flex;
      background-color: #00C400;
    }
    &_exclusive {
      display: flex;
      background-color: #BC1F39;
    }
    &-dropAndWins {
      display: block;
      width: 52px;
      height: 48px;
      background-image: url($cdn + '/rabona/ribbons/drops-wins.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 0;
    }
}

  &.has-width-sm, &.has-width-m{
    display: block;
    padding-top: 0;
    margin: 0 4px;

    &:first-child{ margin-inline-start: 0; }
    &:last-child{ margin-inline-end: 0;  }
  }
  
  &.has-width-m{ @include game-item-size(m);}
  &.has-width-sm{ @include game-item-size(sm); }
}