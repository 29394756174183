$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.missed-data {
  background-color: #192333;
  width: 100%;
  min-height: 100vh;
  padding: 32px 0 12px;
  box-sizing: border-box;
  position: relative;
  color: #A5AFC0;

  --side-padding: 20px;

  &.has-preloader::after {
    position: fixed;
  }

  &__container {
    padding: 0 20px;
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
  }

  &__header {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 2px solid $red;
  }

  &__logo {
    display: none;
    text-align: center;
    margin: 0 auto 36px;
    max-width: 150px;

    @include breakpoint( $bp3) {
      display: block;
    }
  }

  &__title {
    @include text(24px, 28px, normal, #ffffff);
    text-align: center;
    margin: 0 var(--side-padding) 20px;
  }

  &__description {
    font-size: 16px;
    line-height: 1.5;
  }

  &__form {
    max-width: 280px;
    margin: 20px auto 0;
  }

  &__submit {
    margin-top: 16px;
  }

  &__decline {
    margin: 0 auto;
  }

  body.rtl-lang & {
    direction: rtl;
  }
}

.missed-data-info {
  position: relative;
  max-height: 600px;
  padding-inline-end: 16px;
  padding-bottom: 1px;
  overflow: hidden;
  overflow-y: auto;

  body.rtl-lang & {
    padding-inline-start: 16px;
  }

  &__content {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.is-open {
      .missed-data-info__inner {
        max-height: fit-content;
      }
      .missed-data-dropdown {
        &::after {
          transform: rotate(180deg);
        }

        &__text {
          &_less {
            display: block;
          }

          &_more {
            display: none;
          }
        }
      }
    }
  }

  &__inner {
    display: block;
    max-height: 238px;
    overflow: hidden;
    font-size: 16px;
    line-height: 1.5;

    @include breakpoint( $bp2 ) {
      max-height: 200px;
    }
  }

  &__dropdown {
    position: relative;
    margin: 5px auto 0;
  }

  h1, h2, p, li, table {
    margin-bottom: 12px;
  }

  h1, h2 {
    @include text(18px, 1.2, normal, #ffffff);
  }

  h2 {
    font-size: 16px;
  }

  table {
    display: block;
    max-width: 100%;
    border-width: 0;
    overflow-x: auto;
  }

  td {
    min-width: 120px;
    padding: 5px;
  }
  
  th,
  td {
    text-align: start;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }

}

.missed-data-dropdown {
  $self: &;

  @include text(16px, 19px, normal, #ffffff);

  position: relative;
  display: flex;
  align-items: center;
  padding-inline-end: 22px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;

  &::after {
    content: '';
    position: absolute;
    inset-inline-end: 0;
    width: 13px;
    height: 11px;
    background-image: var(--icon-arrow-down);
    background-size: cover;
    transform: rotate(0deg);
  }

  &__text {
    &_less {
      display: none;
    }

    &_more {
      display: block;
    }
  }
}

.missed-data-checkbox {
  max-width: 204px;
  margin: 0 auto;

  .form-checkbox__text {
    color: #A5AFC0;
    margin-inline: 14px 0;
    text-align: start;
  }
}

.missed-data-cancellation {
  height: 100%;
  text-align: center;
  background-color: #192333;

  &__content {
    max-width: 274px;
    margin: 0 auto;
  }

  &__title {
    @include text(22px, 26px, 700, #ffffff);

    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__text {
    @include text(16px, 1.5, normal, #A5AFC0);

    margin-bottom: 8px;
  }
}

.no-scroll {
  overflow: hidden;
}
