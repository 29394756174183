$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.pay-cb {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  margin: 0 auto;
  text-align: center;

  &__logo {
    width: 100px;
    height: 72px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &__title {
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__text {
    @include text(16px, 24px);
    margin-bottom: 20px;
    &_processing {
      color: #a5afc0;
    }
  }
  &__timer {
    margin-bottom: 20px;
  }
  &__chat {
    width: 100%;
    margin: 0 auto;
  }
  &__link {
    margin-top: 12px;
    display: inline-flex;
    &.is-email {
      @include text(14px, 16px, 400, #a5afc0);
      margin-top: 24px;
      vertical-align: middle;

      align-items: center;
      justify-content: center;
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-image: var(--icon-arrow-right);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 4px;
        display: inline-block;
      }
    }
  }
  &__payment {
    margin-bottom: 20px;
    width: 100%;
  }

  &__nav {
    width: 100%;
  }

  @include breakpoint($bp1) {
    max-width: 360px;

    &__title {
      margin-top: 40px;
      margin-bottom: 36px;
    }
    &__timer {
      margin-bottom: 37px;
    }
    &__text_processing,
    &__text_success {
      margin-top: -16px;
    }
    &__payment {
      margin-bottom: 39px;
    }
    &__chat {
      max-width: 280px;
    }
  }
}

.callback-payment {
  &__item {
    border-bottom: 1px solid $blue;
    min-height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__title {
    @include text(12px, 12px, normal, $grey);
    text-transform: uppercase;
    max-width: 100px;
    text-align: left;
  }
  &__value {
    @include text(16px, 19px);
    max-width: 172px;
    text-align: right;
  }

  @include breakpoint($bp1) {
    width: 360px;
    &__title {
      max-width: 130px;
    }
    &__value {
      max-width: 222px;
    }
  }
}
