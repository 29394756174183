$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.layout-bonus-page {

  padding: 48px 0 20px !important;

  @include breakpoint( $bp1 ){
    padding-bottom: 32px !important;
  }

  &__list {

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    max-width: 280px;
    .bonus-new {
      margin-bottom: 20px;
    }

    @include breakpoint( $bp1 ){
      margin-top: 8px;
      max-width: 508px;
      .bonus-new {
        max-width: 246px;
        margin: 0 4px 8px;
      }
    }

    @include breakpoint( $bp2 ){
      max-width: 748px;
      .bonus-new {
        max-width: 280px;
      }

      .layout-bonus-page__active-bonus{
        max-width: 420px;
        width: calc(50% - 6px);
          &:nth-child(2n){
          margin-left: 12px;
        }
      }

    }

    @include breakpoint( $bp3 ){
      max-width: 864px;
    }

  }

}