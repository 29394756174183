$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.easter-info {
    body.linda-has-user & {
        height: calc(100vh - 48px);
    }

    body:has(.is-verification-changed) & {
        height: calc(100vh - 98px);
    }
}