$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-banner {

  background-color: $red;
  background-image: url($cdn + '/rabona/collections/bg-get-collection.jpg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 160px auto;
  height: 48px;
  width: 100vw;
  padding: 10px 20px;
  margin: 0 -20px -24px;



  &__text {
    @include text(12px, 14px, bold);
    text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    max-width: 174px;
    max-height: 28px;
    overflow: hidden;
  }


  @include breakpoint( $bp1 ) {
    background-size: 236px 141px;
    width: 472px;
    margin: 0 -40px 0;
    height: 141px;
    padding: 42px 28px;

    &__text {
      @include text(24px, 28px);
      max-width: 261px;
      max-height: 56px;

    }
  }



}