$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.composite-popup {
  background: #162334;
  padding: 48px 20px 32px;
  display: flex;
  flex-direction: column;

  &__title {
    @include text(20px, 23px, 400, $white);
    margin-bottom: 12px;
  }
  &__description {
    @include text(16px, 22px, 400, #A5AFC0);
    margin-bottom: 34px;
  }
  &__section {
    height: 136px;
    background-color: $red;
    margin-bottom: 52px;
  }
  &__bg {
    position: absolute;
    min-height: 136px;
    background-color: $red;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;
    height: 100%;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><circle r="2" fill="%23DA6436" /></svg>') repeat;
  }

  &__icon {
    height: 72px;
    width: 72px;
    background-image: var(--icon-i-points-with-shadow);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: 3px;
    position: relative;
    top: -5px;
  }

  &__text {
    @include text(28px, 34px, 400, $white);
    text-transform: uppercase;
  }

  &__btn {
    min-width: auto;
    height: 56px;
    padding: 0 32px;
    flex-shrink: 0;
    width: max-content;
    margin: auto;
  }
}