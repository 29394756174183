$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.tournament-sports {
	padding: 0 17px;

	@include breakpoint($bp1) {
		padding: 0 20px;
	}
	@include breakpoint($bp2) {
		padding: 0 24px;
	}

	body.rtl-lang & {
		direction: rtl;
	}

	&__cat {
		margin-bottom: 36px;
	}

	&__title {
		margin-left: 0;
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 16px;

		@include breakpoint($bp1) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include breakpoint($bp2) {
			grid-template-columns: repeat(4, 1fr);
		}

		@include breakpoint($bp3) {
			grid-template-columns: repeat(5, 1fr);
		}
	}

	&__item {
		min-height: 135px; //fallback
		aspect-ratio: 1/1;
	}

	&__bg {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #162334;
		background-origin: content-box;
		padding: 15px;
	}
}