$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.home-cards {
    position: relative;
    min-height: 252px;
    padding-bottom: 20px;
    overflow: hidden;
    @include breakpoint($bp2){
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }


    &__img{
        position: relative;
        width: 100%;
        min-height: 144px;
        padding-top: 37.11%;
        background-position:  top center;
        background-repeat: no-repeat;
        background-size: cover;

    }
    &__container {
        background-color: #162334;
        display: flex;
        flex-direction: column;
        align-items: start;

        padding: 20px;
        position: relative;
        min-height: 117px;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: scale(-1, 1);
            background-image: url($cdn + "/rabona/elements/pattern-collections.svg");
            background-repeat: no-repeat;
            background-position: right 240px top -65px;
            background-position: right calc(100% + 270px) bottom -20px;
            opacity: .5;
            @include s {
                background-position: right calc(100% + 195px) bottom -20px;
            }

            body.rtl-lang & {
                transform: unset;
            }
        }
        @include breakpoint($bp2){
            flex-grow: 1;
        }
    }
    &__title {
        @include text(14px, 16px, 700);
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        white-space: break-spaces;
        color: #ECFD00;
    }
    &__descr {
        @include text(20px, 23px, 700, $white);
        position: relative;
        z-index: 1;
        white-space: break-spaces;
        margin-bottom: 8px;
    }
    &__btn {
        min-width: 160px;
        max-width: 100%;
        line-height: 40px;
        position: relative;
        z-index: 1;
        @include breakpoint($bp2){
            margin-top: auto;
        }
    }


    body.rtl-lang & {
        direction: rtl;

        &__btn {
            text-align: center;
            font-weight: bold;
            font-family: system-ui;
        }
    }

    @include breakpoint($bp1){
    }
    @include breakpoint($bp2){
       
    }

    @include breakpoint($bp3){
        &__img{
            right: 0;
        }
    }
}