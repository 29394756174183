$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.balance-filter-error {
  width: 100%;
  color: #FF2469;
  padding: 8px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  &,
  .balance-filter-date__wrap:has(.ng-valid)+& {
    display: none;
  }

  .balance-filter-date__wrap:has(.ng-invalid.ng-touched)+&:not(.is-only-required) {
    display: block;
  }

  &__min {
    display: none;

    .balance-filter-date__wrap:has(.ng-invalid-min)+.balance-filter-error & {
      display: block;
    }
  }

  &__max-start {
    display: none;

    .balance-filter-date__wrap:has(.is-start.ng-invalid-max)+.balance-filter-error & {
      display: block;
    }
  }
  &__max-end {
    display: none;

    .balance-filter-date__wrap:has(.is-end.ng-invalid-max)+.balance-filter-error & {
      display: block;
    }
  }
}