$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.sidemenu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  .is-right & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
