$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-payments {
  display: flex;
  justify-content: center;
  list-style: none;

  body.rtl-lang & {
    direction: rtl;
  }

  &__item {
    margin: 0 4px;
    padding: 2px;
    min-width: 72px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #273242;
    border: 1px solid #2a394e;
    border-radius: 1px;

    img {
      object-fit: contain;
    }

    &.is-link {
      position: relative;
      padding-block: 2px;
      padding-inline: 10px 22px;

      [lang="eu"] &,
      [lang="fr-BE"] & {
        padding-block: 2px;
        padding-inline: 4px 17px;

        @include breakpoint($bp1) {
          padding-block: 2px;
          padding-inline: 10px 22px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        inset-inline-end: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 8px;
        background-image: url($cdn + '/rabona/icons/arrow-rounded.svg');
        background-repeat: no-repeat;
        background-size: 5px 8px;

        body.rtl-lang & {
          scale: -1 1;
        }
      }
    }
  }

  &__link {
    @include text(16px, 19px, 400, #ffffff);
    width: 100%;
    text-align: center;

    [lang="eu"] &,
    [lang="fr-BE"] & {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;

      @include breakpoint($bp1) {
        font-size: 16px;
      }
    }
  }
}
