$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.btn-icon {

  width: 48px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;

  @include hover(){
    opacity: .8;
  }

}