$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.limit-popup {

  &__title {
    padding-top: 16px;
    font-size: 24px;
    line-height: 28px;
    color: white;
    padding-bottom: 20px;
    border-bottom: 2px solid $red;

    @include breakpoint($bp1) {
      padding-top: 0;
      padding-bottom: 24px;
      font-size: 28px;
      line-height: 33px;
    }
  }

  &__text {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    color: #A5AFC0;
  }

  &__message {
    padding: 3px 20px 6px;
    font-size: 10px;
    line-height: 15px;
    color: white;
    background-color: #0B1222;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;

    &.is-red { color: $red; }

    & { opacity: 1; }

    &.ng-hide {
      opacity: 0;
      position: absolute;
    }
    &.ng-hide-add,
    &.ng-hide-remove {
      position: absolute;
      bottom: 0;
    }
  }
}
