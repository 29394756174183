$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.form-box {
  position:relative;
  max-width: 100% ;
  margin:8px 0;

  .form-field & {
    margin: 20px 0 0 0;
  }

  &.is-cashbox { margin:20px 0 0; }

  &.is-50 {
    max-width: 50%;
  }

  &__phone-data {
    display: grid;
    grid-template-columns: 112px 1fr;
    gap: 4px;

    body.rtl-lang & {
      direction: rtl;
    }

    &-code {
      position: relative;

      & select {
        height: 100%;
        width: 100%;
        border: none;
        appearance: none;
        background: transparent;

        & + .form-select {
          body.rtl-lang & {
            direction: ltr;
            padding-inline: 30px 16px;
          }
        }

        body.rtl-lang & {
          option {
            direction: ltr;
          }
        }
      }
    }
    & .form-input.not-status{
      padding-inline-end: 20px;
      &.has-invalid-status.ng-touched:not(:focus){
        background-image: var(--icon-i-error);
      }
      &.ng-dirty:not(.has-invalid-status){
        background-image: var(--icon-i-success);
      }
    }
  }
}
