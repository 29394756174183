$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.funid-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  color: #9B9AB2;

  &__or {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;
    height: 24px;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 0 14px;
    align-items: center;
    &::after,
    &::before {
      content: "";
      flex-grow: 1;
      background-image: linear-gradient(0deg, rgba(155, 154, 179, .2), rgba(155, 154, 179, .2));
      background-size: 100% 1px;
      background-repeat: no-repeat;
      background-position: center;
      height: 1px;
    }
  }
  &__link {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #9B9AB3;
    margin-top: 10px;
  }
}