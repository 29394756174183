$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.is-big-celebrity{
    .entrance-bnr__img{
        width: 251px;
        height: 205px;
        transform: unset;
        top: 6px;
        left: calc(50% - 86px);
        mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, transparent 100%);
        @include s {
            width: 317px;
            height: 259px;
            top: 31px;
            left: calc(50% - 49px);
        }
        @include m {
            width: 357px;
            height: 292px;
            top: 33px;
            left: calc(50% + 10px);
        }

        @include l{
            width: 428px;
            height: 350px;
            top: 8px;
        }
    }
}

.is-big-celebrity-knockout{
    .entrance-bnr__img{
        width: 192px;
        height: 217px;
        transform: unset;
        top: 0px;
        left: calc(50% - 15px);
        mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, transparent 100%);
        @include s {
            width: 249px;
            height: 290px;
            top: 3px;
            left: calc(50% - -15px);
        }
        @include m {
            width: 280px;
            height: 325px;
            top: 4px;
            left: calc(50% + 75px);
        }

        @include l{
            width: 280px;
            height: 325px;
            top: 4px;
            left: calc(50% + 125px);
        }
    }
}

.is-big-celebrity,
.is-big-celebrity-knockout {
    .entrance-bnr__background{
        &::before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(12, 18, 33, 0) 0%, rgba(12, 18, 33, 0.85) 26.89%, #0C1221 100%);
            background-size: auto 285px;
            background-position: bottom;
            background-repeat: no-repeat;
            @include s{
                content: none;
            }
        }
    }

    .entrance-bnr__sign{
        width: 150px;
        height: 58px;
        z-index: 21;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 36px;
        left: calc(50% - 161px);
        @include s {
            top: 197px;
            left: calc(50% + 76px);
            width: 158px;
            height: 58px;
        }
        @include m {
            width: 144px;
            height: 59px;
            top: 246px;
            left: calc(50% + 20px);
        }
        @include l {
            width: 175px;
            height: 71px;
            left: calc(50% + 45px);
        }
    }
    
    .entrance-bnr__autograph{
        color: #fff;
        width: max-content;
        font-size: 11px;
        line-height: 18px;
        position: absolute;
        padding-left: 8px;
        border-left: 1px solid #fff;
        display: flex;
        flex-direction: column;
        font-weight: 700;
        text-transform: uppercase;
        top: calc(100% + 12px);
        left: 0;
        @include s {
            left: 0;
            top: 46px;
            font-size: 11px;
            line-height: 16px;
            padding-top: 4px;
        }
        @include m {
            font-size: 14px;
            line-height: 21px;
            top: 0;
            left: calc(100% + 8px);
            padding-top: 0;
        }
        @include l {
            font-size: 20px;
            line-height: 30px;
        }
    }
    &.entrance-bnr--authorized {
        .entrance-bnr__sign{
            @include s {
                top: 213px;
                left: calc(50% - 58px);
                width: 130px;
                height: 53px;
            }
            @include m {
                width: 144px;
                height: 59px;
                top: 246px;
                left: calc(50% + 20px);
            }
            @include l {
                width: 175px;
                height: 71px;
                left: calc(50% + 45px);
            }
        }
        .entrance-bnr__autograph{
            @include s {
                top: 0;
                left: calc(100% + 9px);
                font-size: 11px;
                line-height: 16px;
                padding-top: 4px;
            }
            @include m {
                font-size: 14px;
                line-height: 21px;
                top: 0;
                left: calc(100% + 8px);
                padding-top: 0;
            }
            @include l {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
}