$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cabinet-no-collection{
    background-color: #1E2737;
    border: 2px solid $yellow;
    padding: 14px;
    margin-bottom: 4px;
    @include text(14px, 16px, 400, #fff);
    @include hover(){
        .cabinet-card__title{
            opacity: 0.85;
        }
    }
    & > *:first-child{
        @include text(null, null, 700, $yellow);
        margin-bottom: 4px;
    }

    & > *:last-child{
        @include hover(){
            opacity: 0.8;
        }
    }
}