$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-deposit-payment {
  display: flex;
  gap: 4px;
  flex-direction: column;

  &__item {
    background: #FFFCF3;
    border: 1px solid rgba(145, 100, 30, 0.25);
    box-shadow: 0 0 0 rgba(141, 116, 70, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
  }

  &__logo {
    width: 120px;
    height: 44px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  &__card {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.05em;
    color: #6E3923;
    opacity: 0.9;
  }

  &__limit {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.05em;
    color: #6E3923;
  }

  &__arr {
    background-image: url('#{$cdn}/wazamba/regisrtation-page/auth-arr.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 18px;
    height: 18px;
    margin-left: auto;
  }
}
