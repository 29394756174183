$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-bets {
  min-height: 400px;
  margin: 8px 12px 0;
  
  &-top {
    body.rtl-lang & {
      direction: rtl;
    }
  }
}
