$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.sidemenu-app {
  margin: 4px auto;
  width: 100%;
  height: 72px;
  padding-inline-start: 84px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background: linear-gradient(90deg, #2d394c 0%, #1e2737 100%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  position: relative;

  body.rtl-lang & {
    direction: rtl;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url('/img/backgroun-app-phone.png');
    background-size: auto 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    pointer-events: none;

    body.rtl-lang & {
      background-position: 100% 100%;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    color: #a5afc0;
    margin-top: 4px;
  }
}
