$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-verification.has-margin-top-30 {
  margin-top: 0 !important;
}
.profile-verification {
  padding: 20px;
  @include breakpoint($bp2) {
    padding: 24px;
  }
}
