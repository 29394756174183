$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.sidemenu-top {
    z-index: 9;
    width: 100%;
    background: #182333;
    padding: 4px;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: 40px;
    grid-gap: 4px;

    &__home {
        width: 40px;
        height: 40px;
        background-position: center;
        background-repeat: no-repeat;

        @include hover() {
            background-color: rgba(255,255,255,0.1);
        } 
    }

    &__tabs {
        display: flex;
        width: 218px;
    }

    &__tab-item {
        --skew-val: 16px;
        text-align: center;
        width: calc(50% + var(--skew-val)/2);
        max-width: calc(50% + var(--skew-val)/2);
        background-color: $lightBlue;
        @include text(16px, 20px, 400, $grey);
        @include ellipsis;
        text-transform: uppercase;

        &:first-child {
            margin-inline-end: -16px;
        }

        &_sport {
            .sidemenu:not(.is-casino-tab) & {
                background-color: $red;
                @include text(null, null, 700, $white);                
            }
        }

        &_casino {
            .is-casino-tab & {
                background: linear-gradient(90deg, #F4FF4D 0%, #E9F716 100%);
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                @include text(null, null, 700, $lightBlue);
            }
        }
    }

    &__left-shape {
        clip-path: polygon(0 0, 100% 0, calc(100% - var(--skew-val)) 100%, 0 100%);
        padding-block: 10px;
        padding-inline: 10px 18px;

        body.rtl-lang & {
            clip-path: polygon(0 0, 100% 0, 100% 100%, var(--skew-val) 100%);
        }
    }

    &__right-shape {
        clip-path: polygon(var(--skew-val) 0, 100% 0, 100% 100%, 0 100%);
        padding-block: 10px;
        padding-inline: 18px 10px;
        justify-self: end;

        body.rtl-lang & {
            clip-path: polygon(0 0, calc(100% - var(--skew-val)) 0, 100% 100%, 0 100%);
        }
    }

    body.rtl-lang &__tab-item {
        text-align: center;
    }
    // &__tab-casino{
    //     width: 50%;
    //     background-color: $lightBlue;
    // }
}
