$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.collection-card {
    width: 136px;
    height: 203px;
    margin: 0 4px 8px;
    perspective: 1000px;
    position: relative;
    &.is-status-open,
    &.is-status-bonus_received {
        animation: cardzindex 1s;
        z-index: 9;
    }

    @include breakpoint( $bp1 ) {
        width: 161px;
        height: 240px;
    } 
    @include breakpoint( $bp2 ) {
        width: 176px;
        height: 263px;
    }

    &__wrap {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 1s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        .is-animate-open-true & {
            animation: cardRotate 1s;
            animation-fill-mode: forwards;
        }
        .is-status-open &,
        .is-status-bonus_received & {
            transform: scale(1) rotateY(180deg);
        }
    }
    &__front,
    &__back {
        position: absolute;
        backface-visibility: hidden;
        transform: none;
    }

    &__front {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: white;
        transform: rotateY(180deg);
    }
    &__back {
        background-color: #162334;
        padding: 4px;
        width: 100%;
        height: 100%;
        border-radius: 6px;

        &-inner {
            width: 100%;
            height: 100%;
            background-image: url($cdn + '/rabona/cards-page/logo-card-stroke.png'), url($cdn + '/rabona/cards-page/card-fon.jpg');
            background-size: 43px 78px, calc(100% - 4px) calc(100% - 4px);
            background-repeat: no-repeat;
            background-position: center 34px, center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            clip-path: polygon(0 3px,3px 0,100% 0,100% calc(100% - 3px),calc(100% - 3px)100%,0 100%);
            box-shadow: inset 2px 2px #545f70, inset -2px -2px #545f70;
            border-radius: 4px 0 4px 0;
            @include breakpoint( $bp1 ) {
                background-size: 51px 92px, calc(100% - 4px) calc(100% - 4px);
                background-position: center 40px, center;
                padding: 0 13px 32px;
            }
        }
    }
    &__container {
        display: none;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
    }
    &__content{
        position:absolute;
        bottom: 14%;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        body.rtl-lang & {
            direction: ltr;
        }
    }
    &__icon{
        font-weight: 500;
        font-size: 13px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1.5px solid $wh;
    }
    &__count{
        font-size: 17px;
        line-height: 20px;
        font-weight: 700;
        text-align: center;
        color: #FFFFFF;
    }
}

@keyframes cardRotate {
    0% {
        transform: scale(1) rotateY(0);
    }
    20% {
        transform: scale(1.1) rotateY(0);
    }
    80% {
        transform: scale(1.1) rotateY(180deg);
    }
    100% {
        transform: scale(1) rotateY(180deg);
    }
}

@keyframes cardzindex {
    0% {
        z-index: 10;
    }
    99% {
        z-index: 10;
    }
    100% {
        z-index: 9;
    }
}