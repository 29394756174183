$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.form-name {
  font-size: 12px;
  line-height: 1em;
  color: #FFFFFF;
  margin-bottom: 10px;
  text-transform: uppercase;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.is-dark {
    color: #0C1221;
  }

  &.is-center {
    text-align: center;
  }
}