$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-info-line {

  background-color: $lightBlue;
  margin: 20px 0 20px 29px;
  padding: 9px  25px 9px 27px;
  position: relative;
  box-shadow: inset 0px 0.673077px 0px rgba(255, 255, 255, 0.05);
  background: linear-gradient(-66deg, rgba(45,57,76, 0) 18px, rgb(45,57,76) 0);



  &__text {
    @include text(19px, 22px);
    @include ellipsis;


  }
  &__span {
    color: $yellow;
  }
  &.icon-points-yellow__before::before {
    content: "";
    position: absolute;
    width: 52px;
    height: 52px;
    left: -30px;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 52px;
    top: -5px;
  }




}