$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.cup-banner{
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 20px;
    height: 310px;
    display: flex;
    align-items: center;

   
    &__wrap {
        position: absolute;
        bottom: 20px;
        width: 978px;
        left: calc(50% - 489px);
        height: 387px;
        text-align: center;
        z-index: 10;
        &::after{
            content: '';
            position: absolute; 
            bottom: -7px;
            left: 0; 
            width: 100%; 
            z-index: 1;
            height: 85px;
            background: linear-gradient(180deg, rgba(11, 18, 34, 0) 0%, #0B1222 100%);
        }
    }
   
    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url($cdn + '/rabona/cup-page/m_bg-img.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__ark {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url($cdn + '/rabona/cup-page/m_bg-arka_l.png'), url($cdn + '/rabona/cup-page/m_bg-arka_r.png');
        background-repeat: no-repeat;
        background-position: left center, right center;
        background-size: 348px 379px;
    }

    &__cup{
        position: absolute;
        background-image: url($cdn + '/rabona/mobile/cup-main.png'), url($cdn + '/rabona/mobile/cup-coaster-main.png');
        background-size: contain;
        background-position: center bottom 10px, center bottom;
        background-repeat: no-repeat; 
        z-index: 12;
        bottom: -20px;
        width: 218px;
        height: 295px;
        right: 282px;
    }
    

    &__content{
        position: relative; 
        z-index:11;
        width: 157px;
    }
    &__title{
        @include text(28px, 33px, 700, #FFFFFF);
        max-height: 99px;
        text-transform: uppercase;
        text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        margin-bottom: 8px;
        overflow: hidden;
    }

    &__prize{
        @include text(14px, 16px, 400, $yellow); 
    }

    &__amount{
        @include text(32px, 37px, 700, $yellow);
        margin-bottom: 8px;
    }
    @include breakpoint($bp1){
        &__content{
            margin-left: 110px;
        }
    }
    @include breakpoint($bp2){
        height: 460px;
        padding: 40px;
        &__wrap{
            width: 1390px;
            height: 568px;
            left: calc(50% - 695px);
            bottom: 40px;
            &::after{
                bottom: 0;
                height: 120px;
            }
        }
        &__cup{
            width: 309px;
            height: 418px;
            right: 402px;
            bottom: -40px;
            background-position: center bottom 15px, center bottom;
        }
        
        &__ark {
            background-size: 495px 538px;
        }
        
        &__title{
            @include text(58px, 68px);
            max-height: 204px;
        }
        &__content{
            width: 368px;
            margin-left: 70px;
        }
    }
    @include breakpoint($bp3){
        &__content{
            margin-left: 196px;
        }
    }
}