$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-body-default {
  width: 100%;
  min-height: 100%;
  background: #192333;
  padding: 0 20px;
  display: block;
  position: relative;

  body.rtl-lang & {
    direction: rtl
  }

  &.autobonus-popup {
    padding: 48px 20px;

    @include breakpoint($bp1) {
      padding: 30px 40px;
    }
  }

  .popup-title {
    padding-top: 48px;
    padding-bottom: 12px;
  }

  @include breakpoint($bp1) {
    margin: 40px auto;
    max-width: 472px;
    min-height: auto;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 12px rgba(29, 37, 52, 0.4);
    padding: 0 40px 20px;

    .popup-title {
      padding-top: 27px;
      padding-bottom: 19px;
    }

    .popup-close-btn {
      top: 20px;
      right: 12px;
      body.rtl-lang & {
        right: inherit;
        left: 12px;
      }
    }
  }

  &.verification {
    background: $wh;
    color: $black;
    padding: 10px 20px;

    @include breakpoint($bp1) {
      padding: 10px 0;
      min-width: 560px;
      height: auto;
      min-height: 500px;
      position: relative;
      max-width: 560px;
    }
  }
}