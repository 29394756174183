$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-page {
    display: grid;
    grid-template-areas:
        'info1 info1 info1 info1 info1'
        'ach   ach   ach   ach   ach';
    grid-template-columns: 33px 1fr 26px 1fr 33px; 

    max-width: 728px;
    margin-block: 23px 42px;
    margin-inline: 20px;

    &__info {
        grid-area: info1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-height: 106px;

        box-shadow: inset 0 0 0 5px $white;
        margin-bottom: 40px;
    }
    
    &__info + &__info {
        grid-area: info2;
    }

    &__achievements {
        grid-area: ach;
        max-width: 100%;
    }
    &__achievements-box {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        align-items: stretch;
    }
    &__tabs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        max-width: 100%;

        body.rtl-lang & {
            direction: rtl;
        }
    }
    &__tab-wrap {
        position: relative;
        &:after {
            display: none;
            content: "";
            cursor: pointer;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100px;
            height: calc(100% + 8px);
            background-size: 
                auto calc(100% - 8px),
                auto calc(100% - 8px);
            background-position: right bottom;
            background-repeat: no-repeat;
            transition: 
                background-size .2s ease,
                filter .2s ease;

            filter: grayscale(100%);
        }
        &.is-sport-wrap {
            &:after {
                background-image: 
                    url($cdn + "/rabona/profile/sport-png-colour.png"),
                    url($cdn + "/rabona/profile/bg-sport-colour.png");
                
            }
        }
        &.is-casino-wrap {
            &:after {
                background-image: 
                    url($cdn + "/rabona/profile/casino-png-colour.png"),
                    url($cdn + "/rabona/profile/bg-casino-colour.png");
            }
        }
        .is-sport &.is-sport-wrap,
        .is-casino &.is-casino-wrap {
            &:after {
                filter: none;
                background-size: 
                    auto 100%, 
                    auto calc(100% - 8px);
            }
        }
    }
    &__tab {
        clip-path: polygon(34px 0,100% 0,100% 100%,0 100%,0 34px);
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        flex-direction: column;
        padding-block: 12px;
        padding-inline-start: 32px;
        padding-inline-end: 12px;
        height: 100%;
        background-image: linear-gradient(135deg, #576477 0%, #576477 28px, #1C2738 28px);
        color: #576477;
        line-height: 1;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;

        .is-sport .is-sport-wrap &,
        .is-casino .is-casino-wrap & {
            color: $yellow;
            background-image: linear-gradient(135deg, $yellow 0%, $yellow 28px, #2A394E 28px);
        }
    }
    &__tab-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.4;
    }
    &__tab-progress {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.4;

        body.rtl-lang & {
            direction: ltr;
        }
    }
    &__tabs-content {
        display: none;
        background-color: #2A394E;
        padding-block: 30px 40px;
        padding-inline: 32px;
        .is-sport ~  &.is-sport,
        .is-casino ~  &.is-casino {
            display: block;
        }

        &.is-sport {
            padding-inline: 0;

            .challenge-progress, .challenge {
                margin: 0 32px;
            }
        }
    }

    &__composite {
        display: flex;
        justify-content: center;
    }

    @include s {
        &__achievements-box {
            grid-gap: 48px;
        }
        &__tabs-content {
            padding-inline: 48px;
        }
        &__tab {
            align-items: flex-end;
            flex-direction: row;
            padding-block: 12px;
            padding-inline-end: 12px;
            padding-inline-start: 48px;
        }
        &__tab-title {
            font-size: 36px;
            margin-inline-end: 12px;
        }
        &__tab-progress {
            font-size: 20px;
            padding-bottom: 4px;
        }
    }

    @include m {
        margin-block: 23px 42px;
        margin-inline: auto;
        &__achievements-box {
            grid-template-columns: 1fr 1fr;
        }
        &__tabs {
            overflow-x: initial;
        }
        &__tab-wrap {
            &:after {
                display: block;
            }
        }
    }
}


// .challenge-page {
//     padding: 0 20px;
//     &__title {
//         background: $red;
//         font-weight: bold;
//         font-size: 12px;
//         text-transform: uppercase;   
//         width: 100%;
//         padding: 6px;      
//         position: relative;  
//         display: flex;
//         justify-content: center;
//         text-align: center;
//         margin: 0 0 12px;
//         &::after,
//         &::before {
//             content: '';
//             width: 10px;
//             height: 100%;                
//             top: 0;
//             position: absolute;
//             background: $red;                
//             left: -10px;
//             clip-path: polygon(0 0, 100% 100%, 100% 0);
//         }
//         &::after {
//             top: 0;
//             right: -10px;
//             left: auto;
//             clip-path: polygon(100% 0, 0% 100%, 0 0);
//         }
        
//     }
//     &__achievements {
//         width: 100vw;
//         @include breakpoint( $bp3 ) {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//         }
//     }
// }