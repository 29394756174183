$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.onboarding-block {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url($cdn + '/rabona/onboarding/bg-onboarding.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 36px;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 50%;
        height: 100%;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMSIgZmlsbD0iI0VGRkIzNyIgZmlsbC1vcGFjaXR5PSIuMzUiLz48L3N2Zz4=');
        background-repeat: repeat;
    }
    @include breakpoint($s) {
        width: 472px;
        height: auto;
        margin-top: 40px;
        left: 50%;
        margin-left: -236px;
    }
    &__skip-button {
        @include text(14px, 32px, normal, #A5AFC0);
        min-width: 61px;
        height: 32px;
        position: absolute;
        top: 8px;
        right: 20px;
        z-index: 200000;
        span {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    &__wrapper {
        margin: auto;
        max-width: 100%;
    }
    &__page {
        position: relative;
        height: auto;
        .block__image {
            width: 320px;
            height: 230px;
            margin: 0 auto;
        }
        .image1 {
            background: url($cdn + '/rabona/onboarding/pic-onboarding-1.png');
            background-size: contain;  
        }
        .image2 {
            background: url($cdn + '/rabona/onboarding/pic-onboarding-2.png');
            background-size: contain;  
        }
        .image3 {
            background: url($cdn + '/rabona/onboarding/pic-onboarding-3.png');
            background-size: contain;  
        }
        .block__info {
            padding: 0 20px;
            text-align: center;
            h1 {
                @include text(28px, 130%, bold, $white);
            }
            p {
                @include text(20px, 150%, normal, $white);
                margin-top: 12px;
                margin-bottom: 20px;
            }
        }
        .block__next {
            &.is-onboarding {
                display: inline-block;
                height: 40px;
                margin: 0 auto;
                @include text(14px, 40px, null, #080F21);
            }
            span {
                display: block;
                width: 100%;
                height: 100%;
                @include ellipsis;
            }
        } 
    }

    &__steps {
        display: flex;
        width: 69px;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 44px;

        &.is-step-1 div:first-of-type,
        &.is-step-2 div:nth-of-type(2),
        &.is-step-3 div:last-of-type {
            background-color: $yellow;
        }

        @include breakpoint($s) {
            margin-bottom: 50px;
        }
        div {
            width: 8px;
            height: 8px;
            transform: skew(-30deg);
            background: rgba(233, 233, 233, 0.8);
        }
    }
}