$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.form-radio {

  display: flex;
  width: 100%;
  height: 48px;

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__input {
    width: 20px;
    height: 20px;
    background: #293241;
    margin-right: 12px;
    border-radius: 50%;
    transition: .2s all;
    position: relative;

    input:checked ~ & {
      background: #EFFB37;
      &:after {
        content: '';
        background: #192333;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #A7AFBE;
  }

  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }

}