$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.sport-race {
    width: 100%;
    min-height: 500px;
    height: calc(100vh - 96px);

    .linda-has-user & {
        height: calc(100vh - 48px);
    }
}