$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.form-steps {

  display: flex;
  justify-content: space-between;
  width: 280px;

  div {

    width: 68px;
    height: 4px;

    transform: skew(-45deg);

    background: #2D394C;

    &.is-50 {
      width: calc(50% - 1px);
    }

  }


  &.is-step-1 div:nth-child(1),
  &.is-step-2 div:nth-child(1), &.is-step-2 div:nth-child(2),
  &.is-step-3 div:nth-child(1), &.is-step-3 div:nth-child(2), &.is-step-3 div:nth-child(3),
  &.is-step-4 div {
    background: #CC0034;
  }

  &.is-page-0 div:nth-child(1),
  &.is-page-1 div:nth-child(1),
  &.is-page-1 div:nth-child(2) {
    background: $red;
  }

  @include breakpoint( $bp2 ){
    width: 320px;
    div {
      width: 78px;
    }
  }

  @include breakpoint( $bp3 ){
    width: 440px;
    div {
      width: 108px;
    }
  }

}