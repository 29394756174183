$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-notification {
    position: absolute;
    top: 58px;
    right: 8px;
    max-width: 280px; 
    padding: 4px 8px 4px 52px; 

    background:
        var(--icon-notification-info-transparent) no-repeat top 50% left 27px,  
        radial-gradient(30px circle at 29px 50%, 
            #fff 50%, 
            transparent 50%),
        linear-gradient($yellow, $yellow); 
    color: #2A394E; 
    font-size: 16px;
    line-height: 20px;

    &__link {
        color: #2A394E;
        text-decoration: underline;
    }
}
