$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.real-money-crab-wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.real-money-crab {
  width: 100%;
  height: 100%;
  position: relative;

  .crab-area-73aos {
    width: 100%;
    min-height: 100%;
    height: auto !important;
    display: flex;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
