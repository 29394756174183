$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-item {
  --par: 49px;
  --height: 266px;
  height: var(--height);
  position: relative;
  width: 136px;
  margin: 0 4px;
  &:not(:last-of-type) {
    margin-bottom: 35px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 49px;
    height: 77px;
    z-index: 15;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:nth-child(1)::before {
    background-image: url($cdn + '/rabona/vip/numbers/1.svg');
  }

  &:nth-child(2)::before {
    background-image: url($cdn + '/rabona/vip/numbers/2.svg');
  }

  &:nth-child(3)::before {
    background-image: url($cdn + '/rabona/vip/numbers/3.svg');
  }

  &:nth-child(4)::before {
    background-image: url($cdn + '/rabona/vip/numbers/4.svg');
  }

  &:nth-child(5)::before {
    background-image: url($cdn + '/rabona/vip/numbers/5.svg');
  }

  &:nth-child(1).is-active::before {
    background-image: url($cdn + '/rabona/vip/numbers/1-active.svg');
  }

  &:nth-child(2).is-active::before {
    background-image: url($cdn + '/rabona/vip/numbers/2-active.svg');
  }

  &:nth-child(3).is-active::before {
    background-image: url($cdn + '/rabona/vip/numbers/3-active.svg');
  }

  &:nth-child(4).is-active::before {
    background-image: url($cdn + '/rabona/vip/numbers/4-active.svg');
  }

  &:nth-child(5).is-active::before {
    background-image: url($cdn + '/rabona/vip/numbers/5-active.svg');
  }

  &__par {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #19273A;
    clip-path: polygon(0 var(--par), 100% 0, 100% calc(100% - var(--par)), 0 100%);
    height: var(--height);
    display: flex;
    align-items: flex-end;
    z-index: 10;

    .vip-item.is-active & {
      background: $red;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      mask: url('/img/vip-pattern3.svg');
      mask-size: auto 100%;
      mask-position: left top;
      mask-repeat: repeat-x;
      background-image: linear-gradient(to top, #A5AFC0, rgba(165, 175, 192, 0));
      height: 80px;

      .vip-item.is-active & {
        background-image: linear-gradient(to top, #ECFD00 0%, rgba(236, 253, 0, 0) 100%);
      }
    }
  }

  &__line {
    position: absolute;
    left: -1px;
    bottom: -2px;
    width: calc(100% + 2px);
    z-index: 9;
    clip-path: polygon(0 var(--par), 100% 0, 100% calc(100% - var(--par)), 0 100%);
    background: linear-gradient(to top, #8E99AB 0%, rgba(141, 152, 170, 0) 100%);
    height: var(--height);

    .vip-item.is-active & {
      background: linear-gradient(to top, #ECFD00 0%, rgba(236, 253, 0, 0) 100%);
    }
  }

  &__content {
    position: absolute;
    top: 67px;
    left: 0;
    padding: 0 6px;
    width: 100%;
    z-index: 20;
    text-align: center;
    color: #fff;

    * {
      body.rtl-lang & {
        text-align: center;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 14px;
    @include text(16px, 120%, bold);
    @include ellipsis;

    .vip-item.is-active & {
      color: $yellow;
    }
  }

  &__val {
    @include text(14px, 100%, bold);
    @include ellipsis;

    &:not(.manager-off) {
      .vip-item.active & {
        color: $yellow;
      }
    }
  }

  &__key {
    margin: 3px 0 8px;
    @include text(10px, 100%, normal, #A5AFC0);
    @include ellipsis;

    .vip-item.active & {
      color: #fff;
    }
  }

  @include breakpoint($bp1) {
    --par: 90px;
    --height: 462px;
    margin: 0 10px;
    min-width: 232px;
    max-width: 252px;
    width: calc(50% - 20px);
    &:not(:last-of-type) {
      margin-bottom: 53px;
    }

    &::before {
      width: 84px;
      height: 132px;
      top: -40px;
    }
    &__content {
      top: 116px;
      padding: 0 11px;
    }
    &__title {
      font-size: 28px;
      margin-bottom: 36px;
    }
    &__val {
      font-size: 20px;
    }
    &__key {
      margin: 5px 0 14px;
      font-size: 12px;
    }
    &__par::before {
      height: 130px;
    }

  }

  @include breakpoint($bp2) {
    margin: 0 8px 53px;
    width: calc(33.33% - 16px);
    &:last-of-type,
    &:nth-of-type(4){
      margin-bottom: 0;
    }

    &__content {
      padding: 0 10px;
    }
  }

  @include breakpoint($bp3) {
    width: auto;
    margin: 0 8px;

    &:not(:last-of-type) {
      margin-bottom: 56px;
    }
  }

}
