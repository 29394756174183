$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.auth-popup {
  position: relative;
  max-width: 400px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #273242;
  background-color: #162334;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: auto;
  padding: 12px;
  margin: auto;

  @include s {
    max-width: 440px;
  }

  body.rtl-lang & {
    direction: rtl;
  }

  &-funid {
    margin-top: 20px;

    &.is-remembered{
      border-bottom: 1px solid #2a394e;
      margin-bottom: 20px;
      padding-bottom: 16px;
    }

    .g-or {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-family: "Roboto Condensed";

      &::before,
      &::after {
          content: '';
          content: "";
          background-color: #2A394E;
          width: 100%;
          height: 1px;
      }

      span {
          color: #576477;
          font-size: 16px;
          font-weight: 400;
          margin: 0 10px;
      }
    }

    &__btn {
      padding-block: 16px;
      padding-inline: 52px 8px;
      line-height: 1;
      font-size: 18px;
      position: relative;
      box-sizing: border-box;
      font-family: "Roboto Condensed";

      &::before {
        content: '';
        display: inline-flex;
        width: 36px;
        height: 36px;
        background-image: var(--icon-funid);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-flex;
        position: absolute;
        inset-inline-start: 8px;
        top: 50%;
        margin-top: -18px;
      }

      &.ng-tap--start:not(.ng-tap--move),
      &:hover {
        background-color: #3f38dd;
        color: #fff;
      }
    }
  }

  &__preloader {
    &::after {
      background-color: rgb(22 35 52 / 50%);
    }
  }

  &__wrap {
    padding: 6px;
    margin-bottom: 12px;
  }

  &__inner {
    padding: 6px;

    .gdpr-modal & {
      display: grid;
      row-gap: 20px;
      text-align: center;
    }
  }

  &__info {
    padding-bottom: 16px;
    margin-bottom: 20px;
    border-bottom: 1px solid #2A394E;
    text-align: center;

    .gdpr-modal & {
      margin-bottom: 0;
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      .popup-body-login & {
        justify-content: center;
      }

      .auth-with-deposit-login & {
        position: relative;
        padding: 0 32px;

        .auth-popup__info-back {
          position: absolute;
          top: 0;
          left: 0;
        }

        .auth-popup__info-title {
          flex-direction: column;
          align-items: center;
          margin: 0 auto;

          .is-yellow {
            font-weight: 700;
          }
        }
      }
    }

    &-back {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 2px;
      border: 1px solid #273242;
      background-color: #162334;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI4IiBoZWlnaHQ9IjI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik05LjAyNCAxMWw1LjEyNyA1LjA1NkwxOS4zNTIgMTFsMS4wOTIuOTVMMTQuMTUxIDE4IDggMTEuOTUgOS4wMjQgMTF6IiBmaWxsPSIjQTdBRkJFIiBzdHlsZT0iIi8+PC9zdmc+");
      transform: rotate(90deg);
      cursor: pointer;

      body.rtl-lang & {
        transform: rotate(270deg);
      }
    }

    &-title {
      color: #ffffff;
      font-family: "Roboto Condensed";
      font-size: 24px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      width: 100%;

      .auth-popup__info--centered & {
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
      }

      .is-yellow {
        color: #ECFD00;
        font-weight: 700;
        width: 100%;
        body.rtl-lang & {
          text-align: center;
        }
      }

      .gdpr-modal & {
        justify-content: center;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      text-align: center;
      color: #A5AFC0;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.2em;
      gap: 5px;
      margin-top: 16px;

      .auth-popup__info--centered & {
        justify-content: center;
      }

      a {
        color: #ECFD00;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2em;
        text-decoration-line: underline;
      }
    }
  }

  &__description {
    max-width: 290px;
    margin: 0 auto;
    font-size: 16px;
    color: #A5AFC0;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    p {
      width: 100%;
    }
  }

  &__dismiss {
    margin-bottom: 18px;
  }

  &__dep-description {
    display: flex;
    column-gap: 3px;
  }

  &__dismiss-link {
    color: #fff;
    font-size: 16px;
    text-decoration: underline;
  }

  &__btn {
    .btn {
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.2px;
      padding: 18px 32px;
      min-width: auto;
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-b-9, #273242);
    background: var(--color-b-10, #162334);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    right: -12px;
    top: -12px;
    cursor: pointer;
    z-index: 1;
    body.rtl-lang & {
      right: inherit;
      left: -12px;
    }

    &-icon {
      width: 16px;
      height: 16px;
      background-image: var(--icon-i-close);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(7%) hue-rotate(10deg) brightness(106%) contrast(100%);
    }
  }

  &__title {
    margin-bottom: 8px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    text-transform: uppercase;
  }

  &__dep {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-top: 1px;
    padding-block: 4px;
    padding-inline: 16px 4px;
    text-align: center;
    border-radius: 0px 0px 2px 2px;
    background-color: rgba(39, 50, 66, 0.50);
    color: rgba(#576477, 0.8);
    font-size: 16px;
    font-weight: 500;
    line-height: 1em;
    text-transform: capitalize;

    &-payment-icon {
      display: flex;
      height: 32px;
      min-width: 88px;
      padding: 2px;
      border-radius: 4px;
      background-color: #EFF5FD;
      margin-left: auto;
      body.rtl-lang & {
        margin-left: 0;
        margin-right: auto;
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__forgot-password {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: #ECFD00;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2em;
    }
  }

  &__form {
    display: grid;
    gap: 20px;
    width: 100%;

    &-submit {
      width: 100%;

      .btn {
        padding: 18px 32px;
        line-height: 1;
        font-size: 20px;
        border: none;
      }
    }

    .form-box {
      margin: 0;
    }

    &.ng-invalid {
      .btn {
        background-color: #a5afc0;
        pointer-events: none;
      }
    }
  }

  &__submit {
    text-align: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    .btn {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 1;
      border: none;
      padding: 18px 32px;
      min-width: auto;

      [lang="kk"] & {
        font-size: 17px;
        padding: 18px 28px;

        @include s {
          font-size: 20px;
          padding: 18px 32px;
        }
      }
    }
  }

  &__diff-account {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #A5AFC0;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8em;

    &-link {
      color: #ECFD00;
      text-decoration: underline;
    }
  }

  &__paynplay {
    display: block;
    margin-top: 36px -24px -24px -24px;
    background-color: #576477;
    border-radius: 0px 0px 5px 5px;
    overflow: hidden;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 68px;
      color: #ECFD00;
      font-size: 24px;
      font-weight: 400;
      padding: 22px 3px;
      line-height: 1em;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 8px;
    }

    &-text {
      color: #ffffff;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.8em;
    }

    &-link {
      color: #ECFD00;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.8em;
      text-decoration-line: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__restoration {

    .auth-popup__info-top,
    .auth-popup__info-text {
      justify-content: center;
    }
  }
}

.auth-with-deposit {
  &__login {
    display: grid;
    gap: 20px;

    .form-box {
      margin: 0;
    }
  }
}

.popup-reg-container {
  &.hide {
    display: none;
  }
}

.auth-deposit-popup-registration-bonus__wrap {
  padding: 6px;
  margin-bottom: 12px;
}
