$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.collection-bonus {
  margin-top: 16px;
  height: 136px;
  background-color: $red;

  &__bg {
    position: absolute;
    min-height: 136px;
    background-color: $red;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
    z-index: 3;
    margin: auto;
    height: 100%;

    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><circle r="2" fill="%23DA6436" /></svg>') repeat center 5px;
  }

  &__count {
    @include text(28px, 33px, 700, $wh);
    &_money {
      direction: ltr !important;
    }
  }

  &__text {
    @include text(16px, 24px, 400, $wh);
  }
}