$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-migration-btns{
    position: sticky;
    bottom: 0;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}