$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.tournaments-item {
    $this: &;

    --date-text: #{$white};
    --date-bg: #{$red};
    --label-bg: #{$yellow};
    --label-text: #{$black};
    --info-text: #{$yellow};
    --bg: #{$red};

    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 475px;
    width: 232px;
    margin-bottom: 20px;
    text-align: center;

    //future
    &_status-0 {
        --date-text: #{$yellow};
        --label-text: #{$white};
        --label-bg: #{$red};
        --bg: #{$lightBlue};

        #{$this}__cup {
            background-image: none
        }
    }

    //past
    &_status-2 {
        --date-text: $white;
        --date-bg: #{$lightBlue};
        --info-text: #A7AFBE;
        --bg: #{$lightBlue};

        #{$this}__label {
            display: none
        }

        #{$this}__bet {
            // background-image: var(--icon-i-money);
        }

        #{$this}__character {
            filter: grayscale(1);
        }
    }

    &__bg {
        position: absolute;
        z-index: -1;
        top: 4px;
        right: 0;
        left: 0;
        bottom: 0;
        background-size: 100% auto;
        background-position: top center;
        background-repeat: no-repeat;

        &.is-sport {
            top: 0;
            bottom: auto;
            height: 284px;
            background-position: center;
            background-size: cover;
        }

        &.euro {
            background-size: auto 60%;
            background-position: 35% 0;
            overflow: hidden;
        }
    }

    &__character {
        img {
            margin-top: 20px;
            width: 160px;
            height: 245px;
            object-fit: contain;
        }

        &.euro {
            img {
                height: auto;
                width: 300px;
                max-width: none;
                margin: 2px 0 0 -55px;
            }
        }
    }

    &__date {
        --side: 13px;
        @include text(12px, 14px, bold);
        text-align: center;
        color: var(--date-text);
        @include ellipsis;
        padding: 5px 16px;
        min-width: 160px;
        filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.25));
        max-width: 100%;

        transform: translate3d(0, 0, 0);

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: var(--date-bg);
            clip-path: polygon(0 0, 100% 0, calc(100% - var(--side)) 100%, var(--side) 100%);
        }

        > span > span:first-child {
            display: inline-block;
        }

        body.rtl-lang & {
            direction: rtl;
        }
    }

    &__info {
        margin-top: auto;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 9px 20px 20px;

        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            top: 0;
            background-color: var(--bg);
            clip-path: polygon(0 33.9%, 100% 0, 100% 100%, 0 100%);
        }
    }

    &__cup {
        width: 116px;
        height: 90px;
        margin-bottom: 27px;
        background-repeat: no-repeat;
        background-position: center top 14px, center;
        background-image:
            url($cdn + '/rabona/tournament/tournament-cup.svg'),
            url($cdn + '/rabona/tournament/tournament-item-icon.svg');
    }

    &__category {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        margin-bottom: 4px;
        letter-spacing: 0.1em;
    }

    &__text {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 2px;
    }

    &__title {
        @include text(20px, 23px, bold, $white);
        text-align: center;
        text-transform: uppercase;
        height: 46px;
        width: 100%;
        overflow: hidden;
        white-space: break-spaces;

        body.rtl-lang & {
            text-align: center;
        }

        &.euro {
            height: 23px;
        }

    }

    &__prize {
        @include text(20px, 23px, bold);
        text-transform: uppercase;
        margin-top: 8px;
        color: var(--info-text);
        @include ellipsis;
        max-width: 100%;
    }

    &__bet {
        @include text(12px, 14px, normal);
        margin-top: 10px;
        margin-bottom: 16px;
        color: var(--info-text);
        @include ellipsis;
        max-width: 100%;
        display: flex;
    }

    &__label {
        position: absolute;
        left: -7px;
        top: 33.9%;
        transform: translateY(-50%);
    }

    &__link {
        min-width: 100%;
    }

    @include breakpoint($bp1 ) {
        min-height: 471px;
        width: 240px;
        margin-right: 10px;
        margin-left: 10px;

    }

    @include breakpoint($bp2 ) {
        width: 232px;
        margin-right: 8px;
        margin-left: 8px;
        margin-bottom: 16px;
    }
}
