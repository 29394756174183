$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
// used variables from _sidemenu.scss
.sidemenu-categories-crab {
    display: flex;
    flex-direction: column;
    .sidemenu:not(.is-casino-tab) &.is-casino-categories {
        display: none;
    }
    .is-casino-tab &.is-sport-categories {
        display: none;
    }
    &_info {
        &>*:first-child {
            margin-top: 9px;
        }
    }
    &__category-crab {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        padding-inline-start: 20px;
        padding-inline-end: 34px;
        @include text(16px, 48px, 700);
        max-width: 100%;
        @include ellipsis;
        text-transform: uppercase;
        border-bottom: 1px solid #344155;
        margin-bottom: 4px;
        @include hover() {
            background-image: var(--category-gradient-tap);
        }
        &.active {
            background-image: var(--category-gradient-active);
            @include hover() {
                background-image: var(--category-gradient-active);
            }
            &:after {
                filter: var(--arrow-filter-active);
            }
        }
        .sidemenu:not(.is-casino-tab) &.active {
            color: $lightBlue;
        }
        &.has-pin::after {
            margin-bottom: 2px;
        }
        &::before {
            content: '';
            background-image: var(--category-gradient);
            z-index: -1;
            position: absolute;
            inset: 0;
            
            body.rtl-lang & {
                scale: -1 1;
            }
        }
    }
}