$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.buy-card-modal {
  display: block;

  .modal-title {
    margin-top: 0;
    margin-bottom: 33px;
  }

  .modal-info-line {
    padding-top: 3px;
    padding-bottom: 3px;

    &__text {
      font-weight: 700;
      font-size: 32px;
      line-height: 37px;
    }
  }
}