$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(12, 18, 33, 0.8);
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: -102;

  .linda-has-popup & {
    z-index: 9999;
  }

  &.ng-enter {
    transition: all .3s;
    transform: translateY(50px);
    opacity: 0;
    z-index: 9999;
  }

  &.ng-enter.ng-enter-active {
    opacity: 1;
    transform: none;
  }

  &.ng-leave {
    transition: all .3s;
    z-index: 9999;
    opacity: 1;
    transform: none;
  }

  &.ng-leave-active {
    z-index: 9999;
    opacity: 0;
    transform: translateY(50px);
  }

  .verification-popup {
    body.rtl-lang & {
      direction: rtl;
    }

    @media (max-width: 950px) {
      padding-bottom: 130px;
    }
  }

  &__close {
    position: absolute;
    top: 17px;
    inset-inline-end: 21px;
    width: 13px;
    height: 13px;
    filter: invert(89%) sepia(95%) saturate(1000%) hue-rotate(178deg) brightness(83%) contrast(78%);
    cursor: pointer;
  }

  &__title {
    font-size: 28px;
    line-height: 33px;
    padding-bottom: 20px;
    color: #FFFFFF;

    &:not(.no-border) {
      border-bottom: solid 2px $red;
    }

    &_left {
      text-align: left;
      max-width: 350px;
    }
  }

  &__box {
    //margin-top: 44px;
    border: 1px solid #293241;
    padding: 16px 18px;
    font-size: 16px;
    line-height: 24px;
    color: $blueGray;

    a {
      color: $yellow;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include breakpoint($bp1 ) {
    &__close {
      top: 37px;
      right: 30px;
    }

    &__title {
      padding-bottom: 24px;
    }
  }
}