$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.header-wrap {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;

  body.rtl-lang & {
    direction: rtl;
  }

  .has-open-left-sidebar & {
    transform: translateX(270px);
  }

  .has-open-right-sidebar & {
    transform: translateX(-270px);
  }
}
