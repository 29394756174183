$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-close-btn {
  
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDEzLjI3bDUuMzYgNS4zNyAxLjI4LTEuMjhMMTMuMjcgMTJsNS4zNy01LjM2LTEuMjgtMS4yOEwxMiAxMC43MyA2LjY0IDUuMzYgNS4zNiA2LjY0IDEwLjczIDEybC01LjM3IDUuMzYgMS4yOCAxLjI4TDEyIDEzLjI3eiIgZmlsbD0iI0YwRjVGQyIvPjwvc3ZnPg==') center no-repeat;
  background-size: 24px;

  width: 48px;
  height: 48px;

  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  @include hover(){
    opacity: .8;
  }

  &.is-dark-background {
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
    background-color: #162334;
  }

  body.rtl-lang & {
    right: inherit;
    left: 0;
    &.is-dark-background {
      right: inherit;
      left: 12px;
    }
  }
}