$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.popup-text {
  font-size: 16px;
  line-height: 24px;
  color: #A7AFBE;

  batman-popup-wrapper6 & {
    white-space: break-spaces;
  }

  p {
    text-align: center;
    
    body.rtl-lang & {
      text-align: center;
    }
  }

  span {
    display: inline-block;
  }

  &.has-line {
    padding-bottom: 20px;
    border-bottom: 2px solid #CC0034;
  }

  &.is-padding {
    padding-bottom: 20px;
  }

  &.is-pad-8 {
    padding-bottom: 8px;
  }

}