$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.challenge-info {
    width: 100%;
    height: auto;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    margin-bottom: 12px;
    padding: 5px 16px;
    &__title {
        @include text(28px, 33px, bold, $yellow);
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 8px;          
    }
    &__subtitle {
        @include text(16px, 19px, bold);
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        margin-bottom: 4px;
    }
    &__value {
        @include text(28px, 34px, bold);
        .is-yellow {
            color: $yellow;
        }
    }
}