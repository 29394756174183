$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.info .pwa-instruction, .info .pwa-instruction * {
  all: revert;
}

.info .pwa-instruction{
  padding-left: 20px;
  padding-right: 20px;
  counter-reset: section;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  &__title{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  &__step {
    margin-top: 14px;
    padding-inline-start: 28px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset-inline-start: 0;
      top: -2px;
      margin: auto;
      width: 24px;
      height: 22px;
      padding: 3px 0px 7px 6px;
      clip-path: polygon(0 0, 100% 0, 62.5% 100%, 0% 100%);
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjIyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDBoMjRsLTkuNjg1IDIySDBWMHoiIGZpbGw9IiNDQzAwMzQiIHN0eWxlPSIiLz48L3N2Zz4='), url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI0IiBoZWlnaHQ9IjIyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0wIDBoMjRsLTkuNjg1IDIySDBWMHoiIGZpbGw9IiM5OTAwMjciIHN0eWxlPSIiLz48L3N2Zz4=');
      background-size: 24px 22px, 0;
      background-position: 0 0, 0px 6px;
      background-repeat: no-repeat;

      body.rtl-lang & {
        scale: -1 1;
      }
    }
    &::after {
      counter-increment: section;
      content: counter(section);
      position: absolute;
      z-index: 1;
      inset-inline-start: 0;
      top: -2px;
      margin: auto;
      width: 24px;
      height: 22px;
      padding-block: 3px 7px;
      padding-inline: 6px 0;
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
    }

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #FFFFFF;

      &::before,
      &::after {
        content: none;
      }
    }

    &-info {
      margin-top: 8px;
      font-size: 14px;
      line-height: 16px;
      color: #A5AFC0;
    }
  }
  &__image{
    margin-top: 14px;
    width: 100%;
  }
}
