$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.form-select {
  display: block;
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  max-width: 100%;
  padding-inline-start: 16px;
  background-color: #293241;
  border: 1px solid #2D394C;
  border-radius: 2px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9InllcyI/Pjxzdmcgd2lkdGg9IjI4IiBoZWlnaHQ9IjI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik05LjAyNCAxMWw1LjEyNyA1LjA1NkwxOS4zNTIgMTFsMS4wOTIuOTVMMTQuMTUxIDE4IDggMTEuOTUgOS4wMjQgMTF6IiBmaWxsPSIjQTdBRkJFIiBzdHlsZT0iIi8+PC9zdmc+');
  background-position: right 11px center;
  background-repeat: no-repeat;
  background-size: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;

  &:focus {
    border-color: $yellow;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: $inputBg;
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    border-radius: $inputRadius;
    height: 52px;
    width: 100%;
    padding-block: 16px;
    padding-inline: 20px 48px;
    border: $inputBorder;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    color: $inputColor;
    transition: .2s all;

    &.ng-invalid.ng-touched:not(.novalidate) {
      color: $colorError;
    }

    &:focus {
      color: white !important;
      border: 1px solid #EFFB37;
    }

    &[disabled] {
      color: $colorDisabled;
      background-color: $bgDisabled;
      border: 1px solid #2D394C;
    }

    &.is-short {
      padding-block: 16px;
      padding-inline: 20px 35px;
      background-position: calc(100% - 15px) center;
    }
  }

  &.ng-untouched.ng-empty {
    color: #A7AFBE;
  }

  &.is-small {
    background-position: right 0px center;
    background-size: 30px;
    font-size: 15px;
    padding-inline-start: 10px;
  }

  &:not(.is-small) {
    padding-inline: 16px 50px;
  }

  &:disabled {
    background-color: transparent;
    background-position: right 16px center;
    background-size: 20px;
    background-image: var(--icon-i-success);
  }

  &.has-width200 {
    width: 200px;
  }

  &.is-falsy {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    touch-action: none;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    pointer-events: none;
    line-height: 52px;
    padding-inline-end: 30px;
    text-align: left;
  }
}

body.rtl-lang .form-select {
  background-position: left 3px center;
  text-align-last: right;

  &:not(.is-small) {
    padding-inline: 26px 16px !important;
  }

  select {
    background-position: 20px center;
  }
}