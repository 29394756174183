$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.modal-amount-row {
  padding: 20px 0;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 16px;
    border-bottom: 1px solid #273242;
    margin-bottom: 20px;
  }

  &__sum,
  &__fs {
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
  }

  &__sum {
    body.rtl-lang & {
      direction: ltr;
    }
  }

  &__info {
    font-size: 12px;
    line-height: 120%;
    text-transform: uppercase;
    color: #A7AFBE;
  }

  body.rtl-lang & {
    direction: rtl;
  }
}

.modal-amount-val {
  body.rtl-lang & {
    direction: ltr;
  }
}