$build: 1741784258147;
$cdn: "/joxi";
$cdnProject: undefined;
.santa-banner {
  &__wrap {
    background-color: #CC0034;
    margin-top: 20px;

    @include s {
      position: relative;
      margin: 20px 20px 0;
      overflow: hidden;
    }

    body.rtl-lang & {
      direction: rtl;
    }
  }

  &__img {
    position: relative;
    height: 144px;
    background-size: 730px;
    background-repeat: no-repeat;

    @include s {
      position: absolute;
      top: 0;
      inset-inline-end: 0;
      height: 100%;
      background-size: cover;
      width: calc(100% - 250px);
    }

    @include m {
      height: 222px;
    }

    body.rtl-lang & {
      scale: -1 1;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 28px;
      background-image: linear-gradient(to top, #CC0034, transparent);

      @include s {
        content: none;
      }
    }
  }

  &__content {
    padding: 8px 30px 26px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    @include s {
      padding-block: 20px;
      padding-inline: 23px 0;
      text-align: start;
      position: relative;
      max-width: 250px;
    }
  }

  &__title {
    font-size: 20px;

    @include s {
      font-size: 18px;
    }
  }

  &__value {
    line-height: 1;
    font-size: 26px;
    color: #ECFD00;
    margin-inline-start: 8px;
    display: inline-block;
  }

  &__button {
    margin-top: 14px;
    display: inline-block;

    min-width: 200px;
    min-height: 40px;

    @include s {
      margin-top: 12px;
      min-width: 160px;
    }
  }
}